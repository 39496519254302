import classes from "./Ficha.module.css";
import { handleDate } from "../../../funciones/funcionesAuxiliares";
import { useState, useEffect } from "react";

function FichaCaso(props) {
  const ingresoData = props.ingresoData;
  const premediacionData = props.premediacionData;
  const convocatoriasArray = props.convocatoriasArray;
  const entrevistasArray = props.entrevistasArray;
  const mediacionData = props.mediacionData;
  const mediacionDataSesiones = props.mediacionDataSesiones;
  const seguimientoData = props.seguimientoData;
  const cierreData = props.cierreData;
  console.log(cierreData);

  return (
    <div className={classes.formContainer}>
      {/* ---------------------------- INGRESO ---------------------------- */}
      <div className={classes.ingreso_campos}>
        <div className={classes.titulo_etapa}>INGRESO</div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Fecha Ingreso: </div>
          <div className={classes.div_info_valor}>
            {handleDate(ingresoData.fecha_ingreso)}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Delito: </div>
          <div className={classes.div_info_valor}>{ingresoData.delito}</div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Otro Delito: </div>
          <div className={classes.div_info_valor}>
            {ingresoData.otro_delito}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Fecha Delito: </div>
          <div className={classes.div_info_valor}>
            {handleDate(ingresoData.fecha_delito)}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Fecha Denuncia: </div>
          <div className={classes.div_info_valor}>
            {handleDate(ingresoData.fecha_denuncia)}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Comuna del hecho: </div>

          <div className={classes.div_info_valor}>
            {ingresoData.comuna_hecho}
          </div>
        </div>

        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>
            {" "}
            RUC Caso vinculado:{" "}
          </div>
          <div className={classes.div_info_valor}>
            {ingresoData.rucs_vinculados}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}> Derivador: </div>
          <div className={classes.div_info_valor}>
            {ingresoData.derivador_caso}
          </div>
        </div>
        {/* <div className={classes.linea_info}>
          <div>FISCAL</div>
          <div>
            Nombre Fiscal:{" "}
            <span>
              {ingresoData.datosFiscal["nombre"]
                ? ingresoData.datosFiscal["nombre"]
                : ""}
            </span>
          </div>
          <div>
            Correo Fiscal:{" "}
            <span>
              {ingresoData.datosFiscal["mail"]
                ? ingresoData.datosFiscal["mail"]
                : ""}
            </span>
          </div>
          <div>
            Teléfono Fiscal:{" "}
            <span>
              {ingresoData.datosFiscal["telefono"]
                ? ingresoData.datosFiscal["telefono"]
                : ""}
            </span>
          </div>
          <div>
            Nombre Tribunal:{" "}
            <span>
              {ingresoData.datosFiscal["tribunal"]
                ? ingresoData.datosFiscal["tribunal"]
                : ""}
            </span>
          </div>
        </div>
        <div className={classes.linea_info}>
          <div>DEFENSOR</div>
          <div>
            Nombre Defensor:{" "}
            <span>
              {ingresoData.datosDefensor["nombre"]
                ? ingresoData.datosDefensor["nombre"]
                : ""}
            </span>
          </div>
          <div>
            Correo Defensor:{" "}
            <span>
              {ingresoData.datosDefensor["mail"]
                ? ingresoData.datosDefensor["mail"]
                : ""}
            </span>
          </div>
          <div>
            Teléfono Defensor:{" "}
            <span>
              {ingresoData.datosDefensor["telefono"]
                ? ingresoData.datosDefensor["telefono"]
                : ""}
            </span>
          </div>
          <div>
            Nombre Tribunal:{" "}
            <span>{ingresoData.datosDefensor["tribunal"]}</span>
          </div>
        </div>
        <div className={classes.linea_info}>
          <div>JUEZ</div>
          <div>
            Nombre Juez:{" "}
            <span>
              {ingresoData.datosJuez["nombre"]
                ? ingresoData.datosJuez["nombre"]
                : ""}
            </span>
          </div>
          <div>
            Correo Juez:{" "}
            <span>
              {ingresoData.datosJuez["mail"]
                ? ingresoData.datosJuez["mail"]
                : ""}
            </span>
          </div>
          <div>
            Teléfono Juez:{" "}
            <span>
              {ingresoData.datosJuez["telefono"]
                ? ingresoData.datosJuez["telefono"]
                : ""}
            </span>
          </div> */}
        {/* <div>
            Nombre Tribunal:{" "}
            <span>
              {ingresoData.datosJuez["tribunal"]
                ? ingresoData.datosJuez["tribunal"]
                : ""}
            </span>
          </div>
        </div> */}
        {/* <div className={classes.linea_info}>
  <div>OTRO</div>
  <div>
    Nombre Otro: <span>{ingresoData.datosOtro["nombre"] ? ingresoData.datosOtro["nombre"] : ""}</span>
  </div>
  <div>
    Correo Otro: <span>{ingresoData.datosOtro["mail"] ? ingresoData.datosOtro["mail"] : ""}</span>
  </div>
  <div>
    Teléfono Otro: <span>{ingresoData.datosOtro["telefono"] ? ingresoData.datosOtro["telefono"] : ""}</span>
  </div>
  <div>
    Nombre Tribunal: <span>{ingresoData.datosOtro["tribunal"] ? ingresoData.datosOtro["tribunal"] : ""}</span>
  </div>
</div> */}

        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}> Mediador asignado: </div>
          <div className={classes.div_info_valor}>
            {ingresoData.mediador_asignado}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>
            {" "}
            Comediador asignado:{" "}
          </div>
          <div className={classes.div_info_valor}>
            {ingresoData.comediador_asignado}
          </div>
        </div>
        <div className={classes.linea_info}>
          <div className={classes.subtitulos}>VICTIMAS</div>{" "}
          {ingresoData.victimas.length > 0 ? (
            <div>
              {ingresoData.victimas.map((victima, index) => (
                <div key={"victima_data_" + index}>
                  <hr />
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}> Nombres: </div>
                    <div className={classes.div_info_valor}>
                      {victima.nombres}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Apellidos:</div>
                    <div className={classes.div_info_valor}>
                      {victima.apellidos}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>RUT:</div>
                    <div className={classes.div_info_valor}>{victima.rut}</div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Género:</div>
                    <div className={classes.div_info_valor}>
                      {victima.genero}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Fecha Nacimiento:
                    </div>
                    <div className={classes.div_info_valor}>
                      {handleDate(victima.fecha_nacimiento)}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Nacionalidad:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.nacionalidad}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Otra Nacionalidad:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.otra_nacionalidad}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Pueblo Originario:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.pueblo_originario}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Ocupación:</div>
                    <div className={classes.div_info_valor}>
                      {victima.ocupacion}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Estado Civil:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.estado_civil}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Relación con Ofensor:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.relacion_con_ofensor}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Dirección Principal:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.direccion_principal}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Región Principal:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.region_principal}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Comuna Pincipal:
                    </div>
                    <div className={classes.div_info_valor}>
                      {victima.comuna_principal}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Correo:</div>
                    <div className={classes.div_info_valor}>
                      {victima.correo}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Teléfonos:</div>
                    <div className={classes.div_info_valor}>
                      {victima.telefonos}
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.linea_info}>
          <div className={classes.subtitulos}>OFENSORES</div>
          {ingresoData.ofensores.length > 0 ? (
            <div>
              {ingresoData.ofensores.map((ofensor, index) => {
                <div key={"ofensor_data_" + index}>
                  <hr />
                  <div>
                    Nombres : <span>{ofensor.nombres}</span>
                  </div>
                  <div>
                    Apellidos : <span>{ofensor.apellidos}</span>
                  </div>
                  <div>
                    RUT: <span>{ofensor.rut}</span>
                  </div>
                  <div>
                    Género: <span>{ofensor.genero}</span>
                  </div>
                  <div>
                    Fecha Nacimiento:{" "}
                    <span>{handleDate(ofensor.fecha_nacimiento)}</span>
                  </div>
                  <div>
                    Nacionalidad: <span>{ofensor.nacionalidad}</span>
                  </div>
                  <div>
                    Otra Nacionalidad: <span>{ofensor.otra_nacionalidad}</span>
                  </div>
                  <div>
                    Pueblo Originario: <span>{ofensor.pueblo_originario}</span>
                  </div>
                  <div>
                    Ocupación: <span>{ofensor.ocupacion}</span>
                  </div>
                  <div>
                    Estado Civil: <span>{ofensor.estado_civil}</span>
                  </div>
                  <div>
                    Relación con Víctima:{" "}
                    <span>{ofensor.relacion_con_victima}</span>
                  </div>
                  <div>
                    Dirección Principal:{" "}
                    <span>{ofensor.direccion_principal}</span>
                  </div>
                  <div>
                    Región Principal: <span>{ofensor.region_principal}</span>
                  </div>
                  <div>
                    Comuna Pincipal: <span>{ofensor.comuna_principal}</span>
                  </div>
                  <div>
                    Correo: <span>{ofensor.correo}</span>
                  </div>
                  <div>
                    Teléfonos: <span></span>
                  </div>
                  <hr />
                </div>;
              })}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.linea_info}>
          <div className={classes.subtitulos}>CONCURRENTES</div>
          {ingresoData.concurrentes.length > 0 ? (
            <div>
              {ingresoData.concurrentes.map((concurrente, index) => {
                <div key={"concurrente_data_" + index}>
                  <hr />
                  <div>
                    Nombres : <span>{concurrente.nombres}</span>
                  </div>
                  <div>
                    Apellidos : <span>{concurrente.apellidos}</span>
                  </div>
                  <div>
                    RUT: <span>{concurrente.rut}</span>
                  </div>
                  <div>
                    Género : <span>{concurrente.genero}</span>
                  </div>
                  <div>
                    Fecha Nacimiento:{" "}
                    <span>{handleDate(concurrente.fecha_nacimiento)}</span>
                  </div>
                  <div>
                    Nacionalidad: <span>{concurrente.nacionalidad}</span>
                  </div>
                  <div>
                    Otra Nacionalidad:{" "}
                    <span>{concurrente.otra_nacionalidad}</span>
                  </div>
                  <div>
                    Pueblo Originario:{" "}
                    <span>{concurrente.pueblo_originario}</span>
                  </div>
                  <div>
                    Ocupación: <span>{concurrente.ocupacion}</span>
                  </div>
                  <div>
                    Estado Civil: <span>{concurrente.estado_civil}</span>
                  </div>
                  <div>
                    Relacionado a: <span>{concurrente.relacionado_a}</span>
                  </div>
                  <div>
                    Dirección Principal:{" "}
                    <span>{concurrente.direccion_principal}</span>
                  </div>
                  <div>
                    Región Principal:{" "}
                    <span>{concurrente.region_principal}</span>
                  </div>
                  <div>
                    Comuna Pincipal: <span>{concurrente.comuna_principal}</span>
                  </div>
                  <div>
                    Correo : <span>{concurrente.correo}</span>
                  </div>
                  <div>
                    Teléfonos: <span></span>
                  </div>
                  <hr />
                </div>;
              })}{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.linea_info}>
          <div className={classes.div_info_enunciado}>
            {" "}
            Contexto victimización:{" "}
          </div>
          <div className={classes.div_info_valor}>
            {ingresoData.contexto_victimizacion}
          </div>
        </div>
        <div className={classes.linea_info}>
          <div className={classes.div_info_enunciado}>Descripción:</div>
          <div className={classes.div_info_valor}>
            {ingresoData.descripcion}
          </div>
        </div>
        {/* <div className={classes.linea_info}>
Parte Policial:{" "}
<span>
  {ingresoData.parte_policial != "" ? (
    <a href={ingresoData.parte_policial}>
      <button>Descargar</button>
    </a>
  ) : (
    ""
  )}
</span>
div> */}
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>Causal Devolución:</div>
          <div className={classes.div_info_valor}>
            {ingresoData.causal_devolucion}
          </div>
        </div>
        <div className={`${classes.linea_info} ${classes.div_info}`}>
          <div className={classes.div_info_enunciado}>
            Otra Causal Devolución:{" "}
          </div>
          <div className={classes.div_info_valor}>
            {ingresoData.otra_causal_devolucion}
          </div>
        </div>
      </div>

      {/* ::::::::::::::::::::::::::: PREMEDIACIÓN ::::::::::::::::::::::::::: */}
      {premediacionData != "" ? (
        <div className={classes.premediacion_campos}>
          <div className={classes.titulo_etapa}>PREMEDIACION</div>

          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              {" "}
              Estado Actual Víctima:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {premediacionData.estado_actual_victima}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              Estado Actual Ofensor:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {premediacionData.estado_actual_ofensor}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              Última modificación:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {handleDate(premediacionData.fecha_ultima_modificacion)}
            </div>
          </div>
          {premediacionData.consentimiento_victima !== undefined ? (
            <div className={`${classes.linea_info} ${classes.div_info}`}>
              <div className={classes.div_info_enunciado}>
                Consentimiento Víctima:
              </div>
              <div>
                {premediacionData.consentimiento_victima !== "" ? (
                  <div className={classes.div_info_valor}>
                    <a
                      href={premediacionData.consentimiento_victima}
                      target="blank"
                    >
                      <button>Ver</button>
                    </a>
                  </div>
                ) : (
                  <div>No hay archivo para descargar</div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {premediacionData.consentimiento_ofensor !== undefined ? (
            <div className={`${classes.linea_info} ${classes.div_info}`}>
              <div className={classes.div_info_enunciado}>
                Consentimiento Ofensor:
              </div>
              <div>
                {premediacionData.consentimiento_ofensor !== "" ? (
                  <div className={classes.div_info_valor}>
                    <a
                      href={premediacionData.consentimiento_ofensor}
                      target="blank"
                    >
                      <button>Ver</button>
                    </a>
                  </div>
                ) : (
                  <div>No hay archivo para descargar</div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {/* <div className={classes.linea_info}>
  Documentos adjuntos: <span></span>
</div> */}
          <div className={classes.linea_info}>
            <div
              className={classes.subtitulos}
              // onClick={() => {
              //   console.log(convocatoriasArray.length > 0);
              // }}
            >
              CONVOCATORIAS PREMEDIACION
            </div>
            {convocatoriasArray.length > 0 ? (
              <div>
                {convocatoriasArray.map((conv, index) => {
                  return (
                    <div key={"convocatoria_hist_" + index}>
                      <hr />
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Fecha interacción:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {handleDate(conv.fecha_interaccion)}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Participante:
                        </div>
                        <div className={classes.div_info_valor}>
                          {conv.participante}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Número de convocatoria:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {conv.numero_convocatoria}
                        </div>
                      </div>

                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Canal convocatoria:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {conv.canal_convocatoria}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Resultado:
                        </div>
                        <div className={classes.div_info_valor}>
                          {conv.resultado}
                        </div>
                      </div>
                      <div className={`${classes.linea_info}`}>
                        <div className={classes.div_info_enunciado}>
                          Observaciones convocatoria:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {conv.observaciones_convocatoria}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Fecha próxima entrevista:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {handleDate(conv.fecha_proxima_entrevista)}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Fecha mediación:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {handleDate(conv.fecha_sesion_mediacion)}
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.linea_info}>
            <div
              className={classes.subtitulos}
              // onClick={() => {
              //   console.log(entrevistasArray.length);
              // }}
            >
              ENTREVISTAS PREMEDIACION
            </div>
            {entrevistasArray.length > 0 ? <div></div> : ""}
            {/* Datos entrevista individual */}
            {entrevistasArray.map((entrevista, index) => {
              return (
                <div key={"entrevistas_hist_" + index}>
                  <hr />
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Fecha interacción:{" "}
                    </div>
                    <div className={classes.div_info_valor}>
                      {handleDate(entrevista.fecha_interaccion)}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Participante:
                    </div>
                    <div className={classes.div_info_valor}>
                      {entrevista.participante}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Número de entrevista:{" "}
                    </div>
                    <div className={classes.div_info_valor}>
                      {entrevista.numero_entrevista}
                    </div>
                  </div>

                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Canal entrevista:
                    </div>
                    <div className={classes.div_info_valor}>
                      {entrevista.canal_entrevista}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>Resultado:</div>
                    <div className={classes.div_info_valor}>
                      <span>{entrevista.resultado}</span>
                    </div>
                  </div>
                  <div className={classes.linea_info}>
                    <div className={classes.div_info_enunciado}>
                      Observaciones entrevista:{" "}
                    </div>
                    <div className={classes.div_info_valor}>
                      {entrevista.observaciones_entrevista}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Fecha próxima entrevista:{" "}
                    </div>
                    <div className={classes.div_info_valor}>
                      {handleDate(entrevista.fecha_nueva_entrevista)}
                    </div>
                  </div>
                  <div className={`${classes.linea_info} ${classes.div_info}`}>
                    <div className={classes.div_info_enunciado}>
                      Fecha mediación:{" "}
                    </div>
                    <div className={classes.div_info_valor}>
                      {handleDate(entrevista.fecha_sesion_mediacion)}
                    </div>
                  </div>

                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* ::::::::::::::::::::::::::: MEDIACIÓN ::::::::::::::::::::::::::: */}
      {mediacionData != "" ? (
        <div className={classes.mediacion_campos}>
          <div className={classes.titulo_etapa}>MEDIACIÓN</div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              Fecha inicio mediación:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {handleDate(mediacionData.fecha_inicio_mediacion)}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              Estado Actual Mediación:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {mediacionData.estado_actual_mediacion}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>Tipo Acuerdo:</div>
            <div className={classes.div_info_valor}>
              {mediacionData.tipo_acuerdo}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>Fecha Acuerdo: </div>
            <div className={classes.div_info_valor}>
              {handleDate(mediacionData.fecha_acuerdo)}
            </div>
          </div>
          {mediacionData.documento_seguimiento !== undefined ? (
            <div className={`${classes.linea_info} ${classes.div_info}`}>
              <div className={classes.div_info_enunciado}>
                Documento Seguimiento Acuerdo:
              </div>
              <div>
                {mediacionData.documento_seguimiento !== "" ? (
                  <div className={classes.div_info_valor}>
                    <a
                      href={mediacionData.documento_seguimiento}
                      target="blank"
                    >
                      <button>Ver</button>
                    </a>
                  </div>
                ) : (
                  <div>No hay archivo para descargar</div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className={`${classes.linea_info} `}>
            <div className={`${classes.subtitulos} `}> SESIONES MEDIACIÓN</div>

            {mediacionDataSesiones.length > 0 ? (
              <div>
                {mediacionData.sesiones.map((sesion, index) => {
                  return (
                    <div key={"sesion" + index}>
                      <hr />
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        {"Sesión Nº " + index.toString() + 1}
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Fecha sesión:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {handleDate(sesion.fecha_sesion_mediacion)}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          Tipo sesión:
                        </div>
                        <div className={classes.div_info_valor}>
                          {sesion.tipo_sesion_mediacion}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          {" "}
                          Observaciones sesión:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {sesion.observaciones_sesion_mediacion}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          {" "}
                          Tipo asesor legal:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {sesion.tipo_asesor_legal}
                        </div>
                      </div>
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          {" "}
                          Resultados sesión:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {sesion.resultados_sesion}
                        </div>
                      </div>
                      {/* <div className={`${classes.linea_info} ${classes.div_info}`}>
                Tipo de acuerdo:{" "}
                <span>{sesion.tipo_acuerdo_mediacion}</span>
              </div> */}
                      <div
                        className={`${classes.linea_info} ${classes.div_info}`}
                      >
                        <div className={classes.div_info_enunciado}>
                          {" "}
                          Fecha de acuerdo:{" "}
                        </div>
                        <div className={classes.div_info_valor}>
                          {handleDate(sesion.fecha_acuerdo)}
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <div className={classes.linea_info}>
    <div>SESIONES MEDIACIÓN</div>
    {props.mediacionData.sesiones ? (
      <div>
        {props.mediacionData.sesiones.map((sesion, index) => {
          return (
            <div key={"convocatoria_hist_" + index}>
              <hr />
              <div className={classes.linea_info}>
                Fecha Sesión Mediación:{" "}
                <span>
                  {handleDate(sesion.fecha_sesion_mediacion)}
                </span>
              </div>

              <div className={classes.linea_info}>
                Tipo de sesión:{" "}
                <span>{sesion.tipo_sesion_mediacion}</span>
              </div>

              <div className={classes.linea_info}>
                Resultado sesión:{" "}
                <span>{sesion.resultados_sesion}</span>
              </div>
              <div className={classes.linea_info}>
                Observaciones sesión:{" "}
                <span>{sesion.observaciones_sesion_mediacion}</span>
              </div>
              <div className={classes.linea_info}>
                Tipo de acuerdo mediación:{" "}
                <span>{sesion.tipo_acuerdo_mediacion}</span>
              </div>
              <div className={classes.linea_info}>
                Fecha próxima sesión de mediación:{" "}
                <span>
                  {handleDate(sesion.fecha_proxima_sesion_mediacion)}
                </span>
              </div>
              <div className={classes.linea_info}>
                Fecha acuerdo:{" "}
                <span>{handleDate(sesion.fecha_acuerdo)}</span>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    ) : (
      ""
    )}
  </div> */}
        </div>
      ) : (
        ""
      )}
      {/* ::::::::::::::::::::::::::: SEGUIMIENTO ::::::::::::::::::::::::::: */}
      {seguimientoData != "" ? (
        <div className={classes.seguimiento_campos}>
          <div className={classes.titulo_etapa}>SEGUIMIENTO</div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}> Tipo de acuerdo: </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.tipo_acuerdo}
            </div>
          </div>
          {seguimientoData.acuerdo_adjunto !== undefined ? (
            <div className={`${classes.linea_info} ${classes.div_info}`}>
              <div className={classes.div_info_enunciado}>Acuerdo Adjunto:</div>
              <div>
                {seguimientoData.acuerdo_adjunto !== "" ? (
                  <div className={classes.div_info_valor}>
                    <a href={seguimientoData.acuerdo_adjunto} target="blank">
                      <button>Ver</button>
                    </a>
                  </div>
                ) : (
                  <div>No hay archivo para descargar</div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              {" "}
              Cantidad de días para cumplimiento:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.cantidad_dias_para_cumplimiento}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}> Cumple acuerdo: </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.cumple_acuerdo}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              {" "}
              Observaciones cumplimiento acuerdo:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.observaciones_cumple_acuerdo}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              {" "}
              Observaciones generales seguimiento:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.observaciones_generales_seguimiento}
            </div>
          </div>
          <div className={`${classes.linea_info} ${classes.div_info}`}>
            <div className={classes.div_info_enunciado}>
              {" "}
              Resultados seguimiento:{" "}
            </div>
            <div className={classes.div_info_valor}>
              {seguimientoData.resultados_seguimiento}
            </div>
          </div>
          {/* <div className={classes.linea_info}>
            <div className={classes.subtitulos}>ACCIONES MEDIADOR</div>
            {seguimientoData.acciones_mediador.length > 0 ? (
              <div>
                {seguimientoData.acciones_mediador.map((accion, index) => {
                  <div key={"accion_data_" + index}>
                    <hr />
                    <div>
                      Fecha : <span>{handleDate(accion.fecha)}</span>
                    </div>
                    <div>
                      Acción : <span>{accion.tipo}</span>
                    </div>

                    <hr />
                  </div>;
                })}{" "}
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
      ) : (
        ""
      )}
      {/* CIERRE */}
    </div>
  );
}

export default FichaCaso;
