import { useState } from "react";
import classes from "./IndicadorCard.module.css";
import { ResponsivePie } from "@nivo/pie";

function IndicadorCard(props) {
  const titulo = props.titulo;
  const indicador = props.indicador;
  const porcentajeBool = props.porcentajeBool;
  return (
    <div className={classes.card}>
      {porcentajeBool ? (
        <div className={classes.pieChart}>
          <ResponsivePie
            data={[
              {
                id: titulo,
                label: titulo,
                value: +indicador.slice(0,-1)/100,
                color: "hsl(215, 97%, 56%)"
              },
            ]}
            defs={[
              {
                id: titulo,
                color: "#217efc"
              }
            ]}
            fill={[{
              match: {
                id: titulo
              },
              id: titulo
            }]}
            valueFormat=" >-~%"
            padAngle={0.7}
            innerRadius={0.5}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            endAngle={+indicador.slice(0,-1)*360/100}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
          ></ResponsivePie>
        </div>
      ) : (
        <>
          <div className={classes.title}>{titulo}</div>
          <div className={classes.indicador}>{indicador}</div>
        </>
      )}
    </div>
  );
}

export default IndicadorCard;
