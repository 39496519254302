import React from "react";
import classes from "./paginacion.module.css";

function Pagination({ currentPage, totalCases, casesPerPage, onPageChange }) {
  const totalPages = Math.ceil(totalCases / casesPerPage);
  const maxVisiblePages = 3; // Número máximo de páginas visibles

  // Calcular los límites inferior y superior para las páginas visibles
  let startPage = Math.max(currentPage - maxVisiblePages, 1);
  let endPage = Math.min(startPage + 2 * maxVisiblePages, totalPages);

  // Ajustar los límites si es necesario
  if (endPage - startPage + 1 <= maxVisiblePages) {
    startPage = Math.max(endPage - 2 * maxVisiblePages, 1);
  }

  // Generar los números de página para las páginas visibles
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={classes.pagination_main}>
      <ul className={classes.pagination_bar}>
        <li className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
          <button
            className={`${classes.page_link}`}
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
          >
            Primera
          </button>
        </li>
        {startPage > 1 && (
          <li className={`page-item disabled`}>
            <span className={`${classes.page_link}`}>...</span>
          </li>
        )}
        {pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item${
              currentPage === pageNumber ? " active" : ""
            }`}
          >
            <button
              className={`${classes.page_link} ${
                currentPage === pageNumber ? classes.active_link : ""
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        {endPage < totalPages && (
          <li className={`page-item disabled`}>
            <span className={`${classes.page_link}`}>...</span>
          </li>
        )}
        <li className={`page-item${currentPage === totalPages ? " disabled" : ""}`}>
          <button
            className={`${classes.page_link}`}
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            Última
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;


