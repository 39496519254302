import React, { useState } from "react";
import { logout, auth } from "../../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";
import classes from "./Navbar.module.css";
import logo from "../../img/logoUnimico.png";
import userLogo from "../../img/user.png";

function Navbar() {
  const [user, loading, error] = useAuthState(auth);

  const [mostrar_login, setMostrar_login] = useState(false);
  function handleMostrarLogin() {
    setMostrar_login(!mostrar_login);
  }

  let userMail = "Nombre de usuario";

  if (user) {
    userMail = user.email.toString();
  }

  const location = useLocation();

  const isActive = (path) => {
    console.log(location)
    return location.pathname === path ? "active" : "";
  };

  return (
    <div className={classes.Navbar}>
      <header className={classes.NavbarHeader}>
        <div className={classes.HeaderLogo}>
          <img src={logo} className={classes.NavbarImage} alt="" />
        </div>
        <div className={classes.HeaderNav}>
          <div className={classes.HeaderNavList}>
            {/* TODO: Agregar booleano que distimga si la página está seleccionada para cambiar el estilo*/}
            <div className={classes.ListItem}>
              <Link to="/" className={[classes.Link, location.pathname === "/"? classes.active:"" ].join(" ")}>
                Casos
              </Link>
            </div>
            <div className={classes.ListItem}>
              <Link
                to="/estadisticas"
                className={[classes.Link, location.pathname === "/estadisticas"? classes.active:"" ].join(" ")}
              >
                Estadísticas
              </Link>
            </div>
            <div className={classes.ListItem}>
              <Link
                to="/historicos"
                className={[classes.Link, location.pathname === "/historicos"? classes.active:"" ].join(" ")}
              >
                Histórico
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.HeaderUser}>
          <div className={classes.UserPanelButton}>
            <img
              src={userLogo}
              className={classes.UserIcon}
              alt=""
              onClick={handleMostrarLogin}
            />
          </div>
        </div>
      </header>
      {mostrar_login === true ? (
        <div className={classes.loginUserCard}>
          <div className={classes.loginUsername}>
            <p>{userMail}</p>
          </div>
          <div className={classes.logout} onClick={logout}>
            <p>Cerrar Sesión</p>{" "}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Navbar;
