//CSS
import classes from "./Estadistica.module.css";
//Librerías react
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Funciones y componentes propios
import { getAllCasosEstadistica } from "../firebase/firebaseGetData";
import { auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { userSignIn } from "../firebase/firebasePostData";
//Librerías externas
import { HashLoader } from "react-spinners";
import Graficos from "../components/estadistica/Graficos";
import Indicadores from "../components/estadistica/Indicadores";

function Estadistica() {
  const navigate = useNavigate();

  //Contenedor de casos y manejo de autenticación
  const [user, loading] = useAuthState(auth);
  const [isCargandoAllCasos, setIsCargandoAllCasos] = useState(true);
  const [allCasos, setAllCasos] = useState([]);
  const [tab, setTab] = useState("Gráficos");

  //Manejo de inicio de sesión
  useEffect(() => {
    if (loading) return;
    if (!user) {
      return navigate("/login");
    } else {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      return;
    }
  }, [user, loading, navigate]);

  //Obtención de Todos los casos
  useEffect(() => {
    if (user) {
      getAllCasosEstadistica(setAllCasos, setIsCargandoAllCasos);
    }
  }, [user]);

  return isCargandoAllCasos ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div className={classes.mainContainer}>
      <div className={classes.tabContainer}>
        <div className={tab === "Gráficos"? classes.selectedTab:classes.unselectedTab} onClick={() => setTab("Gráficos")}>Gráficos</div>
        <div className={tab === "Indicadores"? classes.selectedTab:classes.unselectedTab} onClick={() => setTab("Indicadores")}>Indicadores</div>
      </div>
      <hr className={classes.separator}/>
      <div>
        {tab === "Gráficos" ? (
          <Graficos allCasos={allCasos} />
        ) : (
          <Indicadores allCasos={allCasos}/>
        )}
      </div>
    </div>
  );
}

export default Estadistica;
