import { useState } from "react";
import classes from "./CierrePenal.module.css";
import { Timestamp } from "firebase/firestore";
import { updateHistoricoCierrePenal } from "../../../firebase/firebasePostData";
import { useEffect } from "react";
import { toast } from "react-toastify";

function CierrePenal(props) {
  // STATES MANEJO FRONTEND

  const [medida_tomada_por_fiscalia, setMedida_tomada_por_fiscalia] = useState(
    props.caso.cierre_penal
      ? props.caso.cierre_penal.medida_tomada_por_fiscalia
        ? props.caso.cierre_penal.medida_tomada_por_fiscalia
        : ""
      : ""
  );

  const handleOptionChangeMedidaFiscalia = (option) => {
    setMedida_tomada_por_fiscalia(option);
  };

  const [caso_penal_cerrado, setCaso_penal_cerrado] = useState(
    props.caso.cierre_penal
      ? props.caso.cierre_penal.caso_penal_cerrado
        ? props.caso.cierre_penal.caso_penal_cerrado
        : ""
      : false
  );

  const [modal_cierre_penal_visible, setModal_cierre_penal_visible] =
    useState(false);

  const [fecha_principio_oportunidad, setFecha_principio_oportunidad] =
    useState(
      props.caso.cierre_penal
        ? props.caso.cierre_penal.fecha_principio_oportunidad
          ? props.caso.cierre_penal.fecha_principio_oportunidad
          : ""
        : ""
    );

  const [fecha_acuerdo_reparatorio, setFecha_acuerdo_reparatorio] = useState(
    props.caso.cierre_penal
      ? props.caso.cierre_penal.fecha_acuerdo_reparatorio
        ? props.caso.cierre_penal.fecha_acuerdo_reparatorio
        : ""
      : ""
  );

  const [fecha_suspension_condicional, setFecha_suspension_condicional] =
    useState(
      props.caso.cierre_penal
        ? props.caso.cierre_penal.fecha_suspension_condicional
          ? props.caso.cierre_penal.fecha_suspension_condicional
          : ""
        : ""
    );

  const [fecha_otra_resolucion, setFecha_otra_resolucion] = useState(
    props.caso.cierre_penal
      ? props.caso.cierre_penal.fecha_otra_resolucion
        ? props.caso.cierre_penal.fecha_otra_resolucion
        : ""
      : ""
  );

  // const [caso_cerrado_penalmente, setCaso_cerrado_penalmente] = useState(
  //   props.caso.cierre_penal.id ? true : false
  // );

  const [observaciones_cierre_penal, setObservaciones_cierre_penal] = useState(
    props.caso.cierre_penal
      ? props.caso.cierre_penal.observaciones_cierre_penal
        ? props.caso.cierre_penal.observaciones_cierre_penal
        : ""
      : ""
  );

  function handleDate(timestamp) {
    if (typeof timestamp === typeof Timestamp.now()) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "";
    }
  }

  const handleModal = async () => {
    if (modal_cierre_penal_visible === false) {
      setModal_cierre_penal_visible(true);
    }
    if (modal_cierre_penal_visible === true) {
      setModal_cierre_penal_visible(false);
    }
  };
  console.log(props.caso);
  let data;

  data = {
    id: props.caso.id,
    fecha_cierre_penal: Timestamp.now(),
    medida_tomada_por_fiscalia: medida_tomada_por_fiscalia,
    fecha_principio_oportunidad: fecha_principio_oportunidad,
    fecha_acuerdo_reparatorio: fecha_acuerdo_reparatorio,
    fecha_suspension_condicional: fecha_suspension_condicional,
    fecha_otra_resolucion: fecha_otra_resolucion,
    observaciones_cierre_penal: observaciones_cierre_penal,
    caso_penal_cerrado: caso_penal_cerrado,
  };

  useEffect(() => {}, [caso_penal_cerrado]);

  const notify_cierre_penal = () => {
    toast.success("Cierre del caso penal ejecutado con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // useEffect(() => {
  //   if (medida_tomada_por_fiscalia != "") {
  //     setCaso_penal_cerrado(true);
  //   }
  // }, [medida_tomada_por_fiscalia]);

  // console.log("CIERRE PENAL: " + caso_cerrado_penalmente);
  return (
    <div className={classes.cierre_penal_div}>
      <div>
        <h2>Cierre caso penal</h2>
        <p className={classes.titulos_div}>
          Medida tomada por la Fiscalía en el caso
        </p>
        <div className={classes.checkWithOption}>
          <input
            type="checkbox"
            checked={medida_tomada_por_fiscalia === "principio_oportunidad"}
            onChange={() =>
              handleOptionChangeMedidaFiscalia("principio_oportunidad")
            }
          />
          <span>Principio de oportunidad</span>
        </div>
        <div className={classes.checkWithOption}>
          <input
            type="checkbox"
            checked={medida_tomada_por_fiscalia === "acuerdo_reparatorio"}
            onChange={() =>
              handleOptionChangeMedidaFiscalia("acuerdo_reparatorio")
            }
          />
          <span>Acuerdo reparatorio</span>
        </div>
        <div className={classes.checkWithOption}>
          <input
            type="checkbox"
            checked={medida_tomada_por_fiscalia === "suspension_condicional"}
            onChange={() =>
              handleOptionChangeMedidaFiscalia("suspension_condicional")
            }
          />
          <span>Suspensión condicional</span>
        </div>
        <div className={classes.checkWithOption}>
          <input
            type="checkbox"
            checked={medida_tomada_por_fiscalia === "otro"}
            onChange={() => handleOptionChangeMedidaFiscalia("otro")}
          />
          <span>Otro</span>
        </div>

        {/* Input calendario para cada opción elegida */}
        {medida_tomada_por_fiscalia === "principio_oportunidad" ? (
          <div>
            <p className={classes.subtitulo}>
              Fecha resolución Fiscalía: Principio de Oportunidad
            </p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_principio_oportunidad)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_principio_oportunidad(newTimestamp);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {medida_tomada_por_fiscalia === "acuerdo_reparatorio" ? (
          <div>
            <p className={classes.subtitulo}>
              Fecha resolución Fiscalía: Acuerdo Reparatorio
            </p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_acuerdo_reparatorio)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_acuerdo_reparatorio(newTimestamp);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {medida_tomada_por_fiscalia === "suspension_condicional" ? (
          <div>
            <p className={classes.subtitulo}>
              Fecha resolución Fiscalía: Suspensión Condicional
            </p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_suspension_condicional)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_suspension_condicional(newTimestamp);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {medida_tomada_por_fiscalia === "otro" ? (
          <div>
            <p className={classes.subtitulo}>Fecha resolución Fiscalía: Otro</p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_otra_resolucion)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_otra_resolucion(newTimestamp);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {/* Observaciones Generales */}

        <div>
          <p className={classes.subtitulo}>Observaciones</p>
          <textarea
            className={classes.textArea}
            name=""
            id=""
            cols="40"
            rows="10"
            defaultValue={observaciones_cierre_penal}
            onBlur={(event) => {
              setObservaciones_cierre_penal(event.target.value);
            }}
          ></textarea>
        </div>

        {/* Botón de cierre del caso penal */}
        {caso_penal_cerrado ? (
          <div>
            <div>
              <button className={classes.boton_cierre_caso_penal_desactivado}>
                CASO PENAL CERRADO
              </button>
            </div>
          </div>
        ) : (
          <div>
            <button
              className={classes.boton_cierre_caso_penal}
              // onClick={handleModal}
              onClick={async () => {
                setCaso_penal_cerrado(true);

                handleModal();
              }}
            >
              CIERRE DEL CASO PENAL
            </button>
          </div>
        )}

        {/* MODAL DE CIERRE DE FORMULARIO */}
        {modal_cierre_penal_visible == true ? (
          <div className={classes.modal_container}>
            <div className={classes.encabezado_modal}>CERRAR CASO PENAL</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de cerrar el caso penal, este proceso es
                irreversible, una vez realizado no se podrán modificar los
                datos. ¿Tienes seguridad de continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  // setCaso_penal_cerrado(true);
                  updateHistoricoCierrePenal(data, props.setIsCargandoCaso);

                  //handleModal();
                  notify_cierre_penal();
                }}
                className={classes.btn_modal_cerrar_caso}
              >
                Cerrar caso penal
              </button>
              <button
                onClick={async () => {
                  setCaso_penal_cerrado(false);
                  handleModal();
                  // notify();
                }}
                className={classes.btn_modal_seguir_editando}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default CierrePenal;
