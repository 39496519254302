import { useState, useEffect } from "react";

import classes from "./Premediacion.module.css";
import log from "loglevel";
import { getUnIngreso } from "../../firebase/firebaseGetData";
import {
  addCaso,
  addMediacion,
  archivar,
  updatePremediacion,
} from "../../firebase/firebasePostData";
import { postStorage } from "../../firebase/firebaseStorage";
import {
  handleDate,
  handleDownload,
} from "../../funciones/funcionesAuxiliares";

import { HashLoader } from "react-spinners";
import save from "../../img/icono_saved.png";
import attach from "../../img/icono_attach.png";
import { Drawer } from "antd";
import { toast } from "react-toastify";

import DatosVictima from "./IngresoDatosVictima";
import DatosOfensor from "./IngresoDatosOfensor";
import DatosConcurrente from "./IngresoDatosConcurrente";
import PremediacionConvocatoria from "./PremediacionConvocatoria";
import PremediacionEntrevista from "./PremediacionEntrevista";
import {
  datosVictima,
  datosOfensor,
  datosConcurrente,
} from "../data/estructuras";
import { comediadores } from "../data/opciones";

function FormularioPremediacion(props) {
  // Funciones y States para Manejo de nuevos documentos

  const [guardado, setGuardado] = useState(true);
  const [fileNuevo, setFileNuevo] = useState("");
  const [adjuntos_premediacion, setAdjuntos_premediacion] = useState(
    props.caso.adjuntos_premediacion ? props.caso.adjuntos_premediacion : []
  );
  const [nombreNuevoArchivo, setNombreNuevoArchivo] = useState("");
  const idCasoPremediacion = props.caso ? props.caso.id : "";
  const historicoRef = props.caso.historicoRef;
  const [ruc, setRuc] = useState(props.caso ? props.caso.ruc : "");
  const [fecha_ingreso, setFecha_ingreso] = useState(
    props.caso ? props.caso.fecha_ingreso : ""
  );
  const [delito, setDelito] = useState(props.caso ? props.caso.delito : "");
  const [fecha_ultima_modificacion, setUltima_actualizacion_estado] = useState(
    props.caso ? props.caso.fecha_ultima_modificacion : ""
  );
  const [estado_actual_victima, setEstado_actual_victima] = useState(
    props.caso ? props.caso.estado_actual_victima : "Sin información"
  );
  const [estado_actual_ofensor, setEstado_actual_ofensor] = useState(
    props.caso ? props.caso.estado_actual_ofensor : "Sin información"
  );
  const [pasa_a_mediacion_bool, setPasa_a_mediacion_bool] = useState(
    props.caso ? props.caso.pasa_a_mediacion_bool : false
  );
  const [pasa_a_cierre_bool, setPasa_a_cierre_bool] = useState(
    props.caso ? props.caso.pasa_a_cierre_bool : false
  );

  const [mediador_asignado, setMediador_asignado] = useState(
    props.caso ? props.caso.mediador_asignado : ""
  );

  const [comediador_asignado, setComediador_asignado] = useState(
    props.caso ? props.caso.comediador_asignado : ""
  );

  const [victimas, setVictimas] = useState(
    props.caso ? props.caso.victimas : ""
  );
  const [ofensores, setOfensores] = useState(
    props.caso ? props.caso.ofensores : ""
  );
  const [concurrentes, setConcurrentes] = useState(
    props.caso ? props.caso.concurrentes : ""
  );
  const [victimasIngreso, setVictimasIngreso] = useState(
    props.caso ? props.caso.victimas : []
  );
  const [ofensoresIngreso, setOfensoresIngreso] = useState(
    props.caso ? props.caso.ofensores : []
  );
  const [concurrentesIngreso, setConcurrentesIngreso] = useState(
    props.caso ? props.caso.concurrentes : []
  );

  const [actividades, setActividades] = useState(
    props.caso.actividades ? props.caso.actividades : []
  );
  const [consentimiento_victima, setConsentimiento_victima] = useState(
    props.caso.consentimiento_victima ? props.caso.consentimiento_victima : ""
  );
  const [consentimiento_ofensor, setConsentimiento_ofensor] = useState(
    props.caso.consentimiento_ofensor ? props.caso.consentimiento_ofensor : ""
  );

  // Estados y funciones ofensor
  const [mostrandoFormConvocatoria, setMostrandoFormConvocatoria] =
    useState(false);

  const [mostrandoFormEntrevista, setMostrandoFormEntrevista] = useState(false);

  const [datosConvocatoria, setDatosConvocatoria] = useState("");
  const [indexConvocatoria, setIndexConvocatoria] = useState("");

  const [datosEntrevista, setDatosEntrevista] = useState("");
  const [indexEntrevista, setIndexEntrevista] = useState("");

  const mostrarConvocatoria = () => {
    setMostrandoFormConvocatoria(true);
    setMostrandoFormEntrevista(false);
  };

  const mostrarEntrevista = () => {
    setMostrandoFormEntrevista(true);
    setMostrandoFormConvocatoria(false);
  };

  const volverEventos = () => {
    setDatosConvocatoria("");
    setIndexConvocatoria("");
    setDatosEntrevista("");
    setIndexEntrevista("");
    setMostrandoFormConvocatoria(false);
    setMostrandoFormEntrevista(false);
  };
  const ingresoRef = props.caso ? props.caso.ingresoRef : "";

  let premediacionData = {
    ingresoRef: ingresoRef,
    id: idCasoPremediacion,
    ruc: ruc,
    delito: delito,
    fecha_ingreso: fecha_ingreso,
    victimas: victimas,
    consentimiento_ofensor: consentimiento_ofensor,
    consentimiento_victima: consentimiento_victima,
    ofensores: ofensores,
    actividades: actividades,
    estado_actual_victima: estado_actual_victima,
    estado_actual_ofensor: estado_actual_ofensor,
    pasa_a_mediacion_bool: pasa_a_mediacion_bool,
    pasa_a_cierre_bool: pasa_a_cierre_bool,
    concurrentes: concurrentes,
    adjuntos_premediacion: adjuntos_premediacion,
    historicoRef: historicoRef,
    mediador_asignado: mediador_asignado,
    comediador_asignado: comediador_asignado,
  };

  let victima =
    props.caso.victimas.length > 0
      ? props.caso.victimas.length > 1
        ? "Múltiple"
        : props.caso.victimas[0].nombres +
          " " +
          props.caso.victimas[0].apellidos
      : "Sin información";

  let ofensor =
    props.caso.ofensores.length > 0
      ? props.caso.ofensores.length > 1
        ? "Múltiple"
        : props.caso.ofensores[0].nombres +
          " " +
          props.caso.ofensores[0].apellidos
      : "Sin información";

  const [isCargandoDatosIngreso, setIsCargandoCasosIngreso] = useState(true);
  const [isCargandoDatosParticipantes, setIsCargandoCasosParticipantes] =
    useState(true);
  const [datosIngreso, setDatosIngreso] = useState([]);
  const [asigna_comediador_bool, setAsigna_comediador_bool] = useState(
    props.caso ? props.caso.asigna_comediador_bool : false
  );
  const [caso_vinculado_bool, setCaso_vinculado_bool] = useState(
    props.caso ? props.caso.caso_vinculado_bool : false
  );
  const [rucs_vinculados, setRucs_vinculados] = useState(
    props.caso ? props.caso.rucs_vinculados : ""
  );
  const [errorRucVinculado, setErrorRucVinculado] = useState(false);

  useEffect(() => {
    setEstado_actual_ofensor(estado_actual_ofensor);
    setEstado_actual_victima(estado_actual_victima);
  }, [estado_actual_ofensor, estado_actual_victima]);

  useEffect(() => {
    if (
      estado_actual_ofensor === "Expresa formalmente consentimiento" &&
      estado_actual_victima === "Expresa formalmente consentimiento" &&
      consentimiento_ofensor !== "" &&
      consentimiento_victima !== ""
    ) {
      setPasa_a_mediacion_bool(true);
    } else if (
      estado_actual_ofensor === "Rechaza participar" ||
      estado_actual_victima === "Rechaza participar" ||
      estado_actual_ofensor === "Contraparte no participa" ||
      estado_actual_victima === "Contraparte no participa" ||
      estado_actual_victima === "Caso no mediable" ||
      estado_actual_ofensor === "Caso no mediable" ||
      estado_actual_victima === "Cerrar caso" ||
      estado_actual_ofensor === "Cerrar caso" ||
      estado_actual_victima === "No se ubica" ||
      estado_actual_ofensor === "No se ubica"
    ) {
      setPasa_a_cierre_bool(true);
    } else {
      setPasa_a_mediacion_bool(false);
      setPasa_a_cierre_bool(false);
    }
  }, [
    consentimiento_ofensor,
    consentimiento_victima,
    estado_actual_ofensor,
    estado_actual_victima,
  ]);
  // STATES Y FUNCIONES DRAWER

  //Obtiene datos desde ingreso
  useEffect(() => {
    getUnIngreso(ingresoRef, setDatosIngreso, setIsCargandoCasosIngreso);
  }, [ingresoRef]);

  //Escribe los datos de víctimas, concurrentes y ofensores al actualizar los datos
  useEffect(() => {
    let victimasTemp = [];
    let ofensoresTemp = [];
    let concurrentesTemp = [];
    if (datosIngreso.victimas) {
      for (let i = 0; i < datosIngreso.victimas.length; i++) {
        victimasTemp.push(datosIngreso.victimas[i]);
      }
      for (let i = 0; i < datosIngreso.ofensores.length; i++) {
        ofensoresTemp.push(datosIngreso.ofensores[i]);
      }
      for (let i = 0; i < datosIngreso.concurrentes.length; i++) {
        concurrentesTemp.push(datosIngreso.concurrentes[i]);
      }
    }
    setRucs_vinculados(datosIngreso.rucs_vinculados);
    setVictimas(victimasTemp);
    setOfensores(ofensoresTemp);
    setConcurrentes(concurrentesTemp);
    setVictimasIngreso(datosIngreso.victimas);
    setOfensoresIngreso(datosIngreso.ofensores);
    setConcurrentesIngreso(datosIngreso.concurrentes);
  }, [datosIngreso]);

  const [visible, setVisible] = useState(false);

  const [mostrar_modal_guardar, setMostrar_modal_guardar] = useState(false);
  const [mostrar_modal_pasar_a_mediacion, setMostrar_modal_pasar_a_mediacion] =
    useState(false);

  const [mostrar_modal_derivar, setMostrar_modal_derivar] = useState(false);

  const notify = () => {
    toast.success("Datos Premediación guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const documento_agregado = () => {
    toast.success(
      "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const falta_para_derivar = () => {
    toast.error("Faltan condiciones para derivar el caso a Fiscalía", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const falta_para_mediacion = () => {
    toast.error("Faltan condiciones para avanzar a etapa de Mediación", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const paso_a_mediacion = () => {
    toast.success("Caso avanza a Mediación !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const derivado = () => {
    toast.success("Caso fue archivado exitosamente", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const victima_borrada = () => {
    toast.success("Se eliminan los datos de la última víctima ingresada", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const ofensor_borrado = () => {
    toast.success("Se eliminan los datos del último ofensor ingresado", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const concurrente_borrado = () => {
    toast.success("Se eliminan los datos del último concurrente ingresado", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorGuardado = () => {
    toast.error(
      "Tiene datos en formato incorrecto, corregir antes de guardar",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const [
    mostrar_confirmar_eliminar_incumbente_victima,
    setMostrar_confirmar_eliminar_incumbente_victima,
  ] = useState(false);

  const [
    mostrar_confirmar_eliminar_incumbente_ofensor,
    setMostrar_confirmar_eliminar_incumbente_ofensor,
  ] = useState(false);

  const [
    mostrar_confirmar_eliminar_incumbente_concurrente,
    setMostrar_confirmar_eliminar_incumbente_concurrente,
  ] = useState(false);

  function handlePlusMinus(type, action) {
    switch (type) {
      case "victima":
        if (action === "sumar") {
          let newVictima = JSON.parse(JSON.stringify(datosVictima));
          victimas.length === 0
            ? setVictimas([newVictima])
            : setVictimas([...victimas, newVictima]);
          setGuardado(false);
        } else {
          if (victimas.length > 0) {
            // setVictimas(victimas.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_victima(true);
          }
        }
        break;
      case "ofensor":
        if (action === "sumar") {
          let newOfensor = JSON.parse(JSON.stringify(datosOfensor));
          setOfensores([...ofensores, newOfensor]);
          setGuardado(false);
        } else {
          if (ofensores.length > 0) {
            // setOfensores(ofensores.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_ofensor(true);
          }
        }
        break;
      case "concurrente":
        if (action === "sumar") {
          setConcurrentes([
            ...concurrentes,
            JSON.parse(JSON.stringify(datosConcurrente)),
          ]);
          setGuardado(false);
        } else {
          if (concurrentes.length > 0) {
            // setConcurrentes(concurrentes.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_concurrente(true);
          }
        }
        break;
      default:
        break;
    }
  }

  const handleRucVinculado = (event) => {
    // const event = Number(event);

    if (/^\d{10}-[0-9Kk]$/.test(event) || event === "") {
      setErrorRucVinculado(false);
    } else {
      setErrorRucVinculado(true);
    }

    setRucs_vinculados(event);
  };

  return isCargandoDatosIngreso && isCargandoDatosParticipantes ? (
    <div>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div className={classes.mainContainer}>
      <div
        className={classes.save}
        onClick={async () => {
          setMostrar_modal_guardar(true);
        }}
      >
        <img className={classes.saveIcon} src={save} alt="" />
      </div>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <img className={classes.attachIcon} src={attach} alt="" />
      </div>
      <div className={[classes.encabezado, classes.border1].join(" ")}>
        PREMEDIACION
      </div>
      {mostrar_modal_guardar === true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>GUARDAR DATOS</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de guardar los datos del formulario, ¿como quieres
              continuar?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                let participantesData = {
                  id: ingresoRef.id,
                  victimas: victimas,
                  ofensores: ofensores,
                  concurrentes: concurrentes,
                  comediador_asignado: comediador_asignado,
                  caso_vinculado_bool: rucs_vinculados !== "" ? true : false,
                  historicoRef: premediacionData.historicoRef,
                  rucs_vinculados: rucs_vinculados,
                };
                addCaso(participantesData, notify, notify, errorGuardado);
                updatePremediacion(premediacionData);
                setMostrar_modal_guardar(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Guardar y seguir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                /* premediacionData = {
                  ingresoRef: ingresoRef,
                  id: idCasoPremediacion,
                  ruc: ruc,
                  delito: delito,
                  fecha_ingreso: fecha_ingreso,
                  victimas: victimas,
                  consentimiento_ofensor: consentimiento_ofensor,
                  consentimiento_victima: consentimiento_victima,
                  ofensores: ofensores,
                  actividades: actividades,
                  estado_actual_victima: estado_actual_victima,
                  estado_actual_ofensor: estado_actual_ofensor,
                  pasa_a_mediacion_bool: pasa_a_mediacion_bool,
                  pasa_a_cierre_bool: pasa_a_cierre_bool,
                }; */
                updatePremediacion(premediacionData);
                let participantesData = {
                  id: ingresoRef.id,
                  victimas: victimas,
                  ofensores: ofensores,
                  concurrentes: concurrentes,
                  comediador_asignado: comediador_asignado,
                  caso_vinculado_bool: rucs_vinculados !== "" ? true : false,
                  historicoRef: premediacionData.historicoRef,
                  rucs_vinculados: rucs_vinculados,
                };
                addCaso(participantesData, notify, notify, errorGuardado);
                props.closeModal();
              }}
            >
              Guardar y salir
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_pasar_a_mediacion == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>PASO A MEDIACIÓN</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de pasar el caso a la fase de Mediación, asegúrate
              de haber ingresado todos los datos que necesitas.
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                updatePremediacion(premediacionData);
                addMediacion(premediacionData);
                paso_a_mediacion();
                props.closeModal();
              }}
              className={classes.btn_seguir_en_form}
            >
              Pasar a Mediación
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_pasar_a_mediacion(false);
              }}
            >
              Seguir editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_derivar == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>DERIVAR A FISCALÍA</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de finalizar el proceso del caso para derivarlo a
              Fiscalía, ¿tienes seguridad de proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                updatePremediacion(premediacionData);
                archivar(premediacionData, "premediacion");
                setMostrar_modal_derivar(false);
                derivado();
                props.closeModal();
              }}
              className={classes.btn_seguir_en_form}
            >
              Derivar
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_derivar(false);
              }}
            >
              Seguir editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={[classes.infoInicial]}>
        <div className={[classes.infoInicial_columna1]}>
          <div className={[classes.etiquetaCaso]}>
            <p>
              CASO: <span>{ruc}</span>
            </p>
          </div>
          <div>
            <p>
              Fecha de Ingreso{" "}
              <span className={[classes.etiquetaFechas]}>
                {handleDate(fecha_ingreso)}
              </span>
            </p>
          </div>
        </div>
        <div className={[classes.infoInicial_columna2]}>
          <div>
            <p>
              Última actualización estado{" "}
              <span className={[classes.etiquetaFechas]}>
                {handleDate(fecha_ultima_modificacion)}
              </span>
            </p>
          </div>
          <div>
            <p>
              Estado actual ofensor{" "}
              <span className={[classes.estado_ofensor]}>
                {estado_actual_ofensor}
              </span>
            </p>
          </div>
          <div>
            <p>
              Estado actual víctima{" "}
              <span className={[classes.estado_victima]}>
                {estado_actual_victima}
              </span>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <Drawer
          open={visible}
          title="Archivos Adjuntos Premediación"
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className={classes.div_principal_drawer}>
            {/* Archivos provenientes de Database */}
            {/* *******Consentimiento Víctima******* */}
            {consentimiento_victima !== "" ? (
              <div>
                <p>Consentimiento {victima}</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      consentimiento_victima,
                      `Consentimiento_${victima}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}

            {/* *******Consentimiento Ofensor******* */}
            {consentimiento_ofensor != "" ? (
              <div>
                <p>Consentimiento {ofensor}</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      consentimiento_ofensor,
                      `Consentimiento_${ofensor}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            {consentimiento_victima === "" && consentimiento_ofensor === "" ? (
              <div>Premediación sin documentos adjuntos hasta la fecha</div>
            ) : (
              ""
            )}
            {/* *******Subir Archivos******* */}
            <hr />
            <div className={[`${classes.nuevos_documentos}`]}>
              {adjuntos_premediacion != undefined ? (
                <div>
                  {adjuntos_premediacion.map((documento, index) => (
                    <div key={index}>
                      <p>{documento.nombre}</p>
                      <button
                        className={classes.btn_descarga_drawer}
                        onClick={() => {
                          handleDownload(
                            documento.archivo,
                            `${documento.nombre}.pdf`
                          );
                        }}
                      >
                        Descargar
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.div_subir_nuevos_documentos}>
              <hr />

              <div className={classes.columna_subir_nuevo}>
                <p className={classes.subtitulo}>Subir Nuevo Documento</p>
                <label htmlFor="file-nuevo" className={classes.fileInputLabel}>
                  Seleccionar
                </label>
                <input
                  className={classes.fileInput}
                  type="file"
                  id="file-nuevo"
                  onChange={(e) => {
                    e.target.nextElementSibling.innerText =
                      e.target.files[0].name;
                    setFileNuevo(e.target.files[0]);
                    console.log(fileNuevo);
                  }}
                />
                <span className={classes.fileName}></span>
                <input
                  type="text"
                  placeholder="Ingrese nombre documento"
                  value={nombreNuevoArchivo}
                  onChange={(e) => setNombreNuevoArchivo(e.target.value)}
                />
                <button
                  className={classes.subir_nuevo_btn}
                  onClick={async () => {
                    if (fileNuevo !== "") {
                      console.log(fileNuevo);
                      let urlNuevo;
                      await postStorage(
                        "premediacion/" + fileNuevo.name,
                        fileNuevo
                      ).then((archivoRutaDescarga) => {
                        urlNuevo = archivoRutaDescarga;

                        setAdjuntos_premediacion([
                          ...adjuntos_premediacion,
                          { nombre: nombreNuevoArchivo, archivo: urlNuevo },
                        ]);
                      });
                      addCaso(premediacionData, notify, notify, errorGuardado);

                      documento_agregado();
                    }
                  }}
                >
                  Subir documento
                </button>
              </div>
            </div>
          </div>
        </Drawer>
        {/* ***** MODALES DE CONFIRMACIÓN DE ELIMINACIÓN ***** */}

        {mostrar_confirmar_eliminar_incumbente_concurrente == true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>
              Eliminar concurrente
            </div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar al último concurrente que agregaste,
                esto borrará de forma permanente todos los datos ingresados del
                mismo. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setConcurrentes(concurrentes.slice(0, -1));
                  setGuardado(false);
                  concurrente_borrado();
                  setMostrar_confirmar_eliminar_incumbente_concurrente(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar concurrente
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_concurrente(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_confirmar_eliminar_incumbente_ofensor == true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>Eliminar ofensor</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar al último ofensor que agregaste, esto
                borrará de forma permanente todos los datos ingresados del
                mismo. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setOfensores(ofensores.slice(0, -1));
                  setGuardado(false);
                  ofensor_borrado();
                  setMostrar_confirmar_eliminar_incumbente_ofensor(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar ofensor
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_ofensor(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_confirmar_eliminar_incumbente_victima == true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>Eliminar víctima</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar a la última víctima que agregaste,
                esto borrará de forma permanente todos los datos ingresados de
                la misma. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setVictimas(victimas.slice(0, -1));
                  setGuardado(false);
                  victima_borrada();
                  setMostrar_confirmar_eliminar_incumbente_victima(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar víctima
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_victima(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className={classes.editor}>
          <>
            {/* TODO: Lógica para crear como un array y agregar más opciones */}
            <div className={classes.subtitulo}>Número de RUC vinculado</div>
            {errorRucVinculado ? (
              <span className={classes.mensaje_error_datos}>RUC inválido</span>
            ) : (
              ""
            )}
            <input
              className={`${classes.actionBox} ${classes.input_cursiva} ${
                errorRucVinculado ? classes.error : ""
              }`}
              type="text"
              name=""
              placeholder="Sin RUC vinculado"
              id=""
              onChange={(event) => {
                const rucIngresado = event.target.value;
                handleRucVinculado(rucIngresado);
                setGuardado(false);
              }}
              defaultValue={rucs_vinculados}
            />
          </>

          <div>
            <p className={classes.subtituloComediador}>Comediador Asignado</p>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={comediador_asignado ? comediador_asignado : " "}
              onChange={(event) => {
                setComediador_asignado(event.target.value);
                if (event.target.value === "No asigna comediador") {
                  setAsigna_comediador_bool(false);
                } else {
                  setAsigna_comediador_bool(true);
                }
                setGuardado(false);
              }}
            >
              <option disabled value=" ">
                {" "}
                -- Seleccione --{" "}
              </option>
              {comediadores.map(function (comediador, i) {
                return (
                  <option value={comediador} key={i}>
                    {comediador}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={classes.gridPartes}>
            <div>Víctimas</div>
            <div
              className={classes.menos}
              onClick={() => {
                handlePlusMinus("victima", "menos");
              }}
            >
              -
            </div>
            <div className={classes.cantidad}>{victimas.length}</div>
            <div
              className={classes.mas}
              onClick={() => {
                handlePlusMinus("victima", "sumar");
              }}
            >
              +
            </div>

            <div>Ofensores</div>
            <div
              className={classes.menos}
              onClick={() => {
                handlePlusMinus("ofensor", "menos");
              }}
            >
              -
            </div>
            <div className={classes.cantidad}>{ofensores.length}</div>
            <div
              className={classes.mas}
              onClick={() => {
                handlePlusMinus("ofensor", "sumar");
              }}
            >
              +
            </div>

            <div>Concurrentes</div>
            <div
              className={classes.menos}
              onClick={() => {
                handlePlusMinus("concurrente", "menos");
              }}
            >
              -
            </div>
            <div className={classes.cantidad}>{concurrentes.length}</div>
            <div
              className={classes.mas}
              onClick={() => {
                handlePlusMinus("concurrente", "sumar");
              }}
            >
              +
            </div>
          </div>
          {victimas.length > 0
            ? victimas.map((number, index) => {
                return (
                  <div key={"victima_" + index}>
                    <DatosVictima
                      setGuardado={setGuardado}
                      data={victimas[index]}
                      fullArray={victimas}
                      index={index}
                      setVictimas={setVictimas}
                    ></DatosVictima>
                  </div>
                );
              })
            : ""}
          {ofensores.length > 0
            ? ofensores.map((number, index) => {
                return (
                  <div key={"ofensor_" + index}>
                    <DatosOfensor
                      setGuardado={setGuardado}
                      data={ofensores[index]}
                      fullArray={ofensores}
                      index={index}
                      setOfensores={setOfensores}
                    ></DatosOfensor>
                  </div>
                );
              })
            : ""}
          {concurrentes.length > 0
            ? concurrentes.map((number, index) => {
                return (
                  <div key={"concurrente_" + index}>
                    <DatosConcurrente
                      setGuardado={setGuardado}
                      data={concurrentes[index]}
                      fullArray={concurrentes}
                      index={index}
                      setConcurrentes={setConcurrentes}
                    ></DatosConcurrente>
                  </div>
                );
              })
            : ""}
        </div>
        {/* <div className={classes.datos_usuarios_premediacion}>
          {datosIngreso.victimas.map((victima, index) => {
            return (
              <div key={"victima_" + index}>
                <span>
                  Victima: {victima.nombres} {victima.apellidos}
                </span>
                <span>
                  <img className={classes.edit} src={edit} alt="" />
                </span>
              </div>
            );
          })}
        </div>
        <div className={classes.datos_usuarios_premediacion}>
          {datosIngreso.ofensores.map((ofensor, index) => {
            return (
              <div key={"ofensor_" + index}>
                <span>
                  Ofensor: {ofensor.nombres} {ofensor.apellidos}
                </span>
                <span>
                  <img className={classes.edit} src={edit} alt="" />
                </span>
              </div>
            );
          })}
        </div> */}
      </div>
      <hr />
      <div className={[classes.agregar_premediacion]}>
        <button onClick={mostrarConvocatoria} className={[classes.boton1]}>
          <span>+</span> Agregar Convocatoria
        </button>
        <button onClick={mostrarEntrevista} className={[classes.boton2]}>
          <span>+</span> Agregar Entrevista
        </button>
      </div>
      {props.modal_cierre_visible == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>CERRAR FORMULARIO</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el formulario, recuerda que cualquier
              cambio que no hayas guardado se perderá. Por favor, asegúrate de
              haber guardado tus cambios.
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                props.setModal_cierre_visible(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Seguir editando
            </button>
            <button
              onClick={async () => {
                let participantesData = {
                  id: ingresoRef.id,
                  victimas: victimas,
                  ofensores: ofensores,
                  concurrentes: concurrentes,
                  comediador_asignado: comediador_asignado,
                  caso_vinculado_bool: rucs_vinculados !== "" ? true : false,
                  historicoRef: premediacionData.historicoRef,
                  rucs_vinculados: rucs_vinculados,
                };
                addCaso(participantesData, notify, notify, errorGuardado);
                updatePremediacion(premediacionData);
                props.closeModal();
              }}
              className={classes.btn_guardar_en_form}
            >
              Guardar y salir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                props.closeModal();
              }}
            >
              Salir sin guardar
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrandoFormConvocatoria ? (
        <div>
          <button className={classes.boton_volver} onClick={volverEventos}>
            Volver a Eventos
          </button>
          <PremediacionConvocatoria
            participantes={[
              ...datosIngreso.victimas,
              ...datosIngreso.ofensores,
            ]}
            actividades={actividades}
            setActividades={setActividades}
            estadoActualVictima = {estado_actual_victima}
            estadoActualOfensor = {estado_actual_ofensor}
            setEstadoActualVictima={setEstado_actual_victima}
            setEstadoActualOfensor={setEstado_actual_ofensor}
            volverEventos={volverEventos}
            datosConvocatoria={datosConvocatoria}
            indexConvocatoria={indexConvocatoria}
          ></PremediacionConvocatoria>
        </div>
      ) : mostrandoFormEntrevista ? (
        <div>
          <button className={classes.boton_volver} onClick={volverEventos}>
            Volver a Eventos
          </button>
          <PremediacionEntrevista
            participantes={[
              ...datosIngreso.victimas,
              ...datosIngreso.ofensores,
            ]}
            actividades={actividades}
            setActividades={setActividades}
            estadoActualVictima = {estado_actual_victima}
            estadoActualOfensor = {estado_actual_ofensor}
            setEstadoActualVictima={setEstado_actual_victima}
            setEstadoActualOfensor={setEstado_actual_ofensor}
            setConsentimientoOfensor={setConsentimiento_ofensor}
            setConsentimientoVictima={setConsentimiento_victima}
            volverEventos={volverEventos}
            datosEntrevista={datosEntrevista}
            indexEntrevista={indexEntrevista}
          ></PremediacionEntrevista>
        </div>
      ) : (
        /* Tabla de actividades */
        <div className={classes.tabla_premediacion_div}>
          <table>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Participante</th>
                <th>Tipo</th>
                <th>Vía</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {actividades.map((actividad, index) => {
                if (actividad.tipo === "convocatoria") {
                  return (
                    <tr
                      className={classes.tableRow}
                      key={"fila_" + index}
                      onClick={() => {
                        setDatosConvocatoria(actividad);
                        setIndexConvocatoria(index);
                        mostrarConvocatoria();
                      }}
                    >
                      <th>{handleDate(actividad.fecha_interaccion)}</th>
                      <th>{actividad.participante}</th>
                      <th>
                        {actividad.tipo.charAt(0).toUpperCase() +
                          actividad.tipo.slice(1)}
                      </th>
                      <th>{actividad.canal_convocatoria}</th>
                      <th>{actividad.resultado}</th>
                    </tr>
                  );
                }

                if (actividad.tipo === "entrevista") {
                  return (
                    <tr
                      className={classes.tableRow}
                      key={"fila_" + index}
                      onClick={() => {
                        setDatosEntrevista(actividad);
                        setIndexEntrevista(index);
                        mostrarEntrevista();
                      }}
                    >
                      <th>{handleDate(actividad.fecha_interaccion)}</th>
                      <th>{actividad.participante}</th>
                      <th>
                        {actividad.tipo.charAt(0).toUpperCase() +
                          actividad.tipo.slice(1)}
                      </th>
                      <th>{actividad.canal_entrevista}</th>
                      <th>{actividad.resultado}</th>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      )}
      <div>
        <p className={classes.disclaimers_premediacion}>
          *Para el paso a mediación, es un requerimiento obligatorio adjuntar el
          documento de consentimiento de ambas partes.
        </p>
      </div>
      <div className={classes.botones_cierre}>
        {pasa_a_cierre_bool == true ? (
          <button
            className={classes.boton_cerrar}
            onClick={async () => {
              setMostrar_modal_derivar(true);
            }}
          >
            DERIVAR A FISCALÍA
          </button>
        ) : (
          <button
            className={classes.boton_cerrar_deshabilitado}
            onClick={falta_para_derivar}
          >
            DERIVAR A FISCALÍA
          </button>
        )}
        {pasa_a_mediacion_bool ? (
          <button
            className={classes.boton_paso_fase}
            onClick={async () => {
              setMostrar_modal_pasar_a_mediacion(true);
            }}
          >
            PASO A MEDIACIÓN
          </button>
        ) : (
          <button
            className={classes.boton_paso_fase_deshabilitado}
            onClick={falta_para_mediacion}
          >
            PASO A MEDIACIÓN
          </button>
        )}
      </div>
    </div>
  );
}

export default FormularioPremediacion;
