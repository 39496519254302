import classes from "./Graficos.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  etapas,
  delitos,
  comunas,
  derivadores,
  mediadores,
} from "../../components/data/opciones";
import { HashLoader } from "react-spinners";
import FiltrosGenerales from "./FiltrosGenerales";
import moment from "moment";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  dateArray,
  monthArrayGenerator,
} from "../../funciones/funcionesAuxiliares";
import FiltrosParticipantes from "./FiltrosParticipantes";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import {
  defEtapa,
  defGradientes,
  fillEtapa,
  fillGradientes,
  keysChart,
  gradientesTitulos,
} from "./coloresBarras";
import botonDescarga from "../../img/botonDescarga.png";
import { toPng } from "html-to-image";
import download from "downloadjs";
import { Button, Menu, MenuItem } from "@mui/material";

function Graficos(props) {
  //Opciones para ordenar casos, estoy usando useMemo para que no moleste React, básicamente renderiza esto sólo una vez
  const opcionesEjeX = useMemo(() => {
    return {
      Etapa: {
        opciones: etapas,
        campo: "etapa",
      },
      "Ingreso diario": { opciones: "", campo: "fecha_ingreso" },
      "Ingreso mensual": { opciones: "", campo: "fecha_ingreso" },
      Delitos: { opciones: delitos, campo: "delito" },
      Comunas: { opciones: comunas, campo: "comuna_hecho" },
      Derivadores: {
        opciones: derivadores,
        campo: "derivador_caso",
      },
      Mediadores: {
        opciones: mediadores,
        campo: "mediador_asignado",
      },
      Actividades: {
        opciones: ["Convocatorias", "Entrevistas", "Sesiones Mediación"],
      },
    };
  }, []);

  const colorScaleEtapa = useMemo(() => {
    return gradientesTitulos;
  }, []);

  //Contenedor de casos y manejo de autenticación
  const [isCargandoData, setIsCargandoData] = useState(true);

  //Filtros disponibles
  const [filtros, setFiltros] = useState({
    estado: "Todos",
    etapa: "Todos",
    delito: "Todos",
    comuna_hecho: "Todos",
    derivador_caso: "Todos",
    causal_devolucion: "Todos",
    mediador_asignado: "Todos",
  });

  const [filtrosVictima, setFiltrosVictima] = useState({
    nacionalidad: "Todos",
    pueblo_originario: "Todos",
    nivel_educacional: "Todos",
    genero: "Todos",
    estado_civil: "Todos",
  });
  const [filtrosOfensor, setFiltrosOfensor] = useState({
    nacionalidad: "Todos",
    pueblo_originario: "Todos",
    nivel_educacional: "Todos",
    genero: "Todos",
    estado_civil: "Todos",
  });
  const [filtrosConcurrente, setFiltrosConcurrente] = useState({
    nacionalidad: "Todos",
    pueblo_originario: "Todos",
    nivel_educacional: "Todos",
    genero: "Todos",
    estado_civil: "Todos",
  });
  const [fechaDesdeIngreso, setFechaDesdeIngreso] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaIngreso, setFechaHastaIngreso] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [fechaDesdeCierre, setFechaDesdeCierre] = useState(
    moment(new Date("2023-05-01")).format("YYYY-MM-DD")
  );
  const [fechaHastaCierre, setFechaHastaCierre] = useState(
    moment().format("YYYY-MM-DD")
  );

  //Resultado de filtros y orden
  const [casos, setCasos] = useState([]);
  const [ejeX, setEjeX] = useState("Etapa");
  const [conteo, setConteo] = useState({});
  const [data, setData] = useState([]);

  //Para menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Arreglo de días o meses
  const [dayArray, setDayArray] = useState(
    dateArray(moment(new Date("2023-05-01")), moment())
  );

  const [monthArray, setMonthArray] = useState(
    monthArrayGenerator(moment(new Date("2023-05-01")), moment())
  );

  //Filtrado de casos
  useEffect(() => {
    let casosFiltrados = props.allCasos;
    //Filtros para fecha desde ingreso
    if (fechaDesdeIngreso !== "") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.ingresoData["fecha_ingreso"] !== "") {
          return (
            moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
              "YYYY-MM-DD"
            ) >= fechaDesdeIngreso
          );
        } else {
          return false;
        }
      });
    }
    if (fechaHastaIngreso !== "") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.ingresoData["fecha_ingreso"] !== "") {
          return (
            moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
              "YYYY-MM-DD"
            ) <= fechaHastaIngreso
          );
        } else {
          return false;
        }
      });
    }
    //Filtros para fecha desde ingreso
    if (fechaDesdeCierre !== "" && filtros.estado === "finalizado") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.fecha_cierre) {
          return (
            moment(caso.fecha_cierre.toDate()).format("YYYY-MM-DD") >=
            fechaDesdeCierre
          );
        } else {
          return false;
        }
      });
    }
    if (fechaHastaCierre !== "" && filtros.estado === "finalizado") {
      casosFiltrados = casosFiltrados.filter((caso) => {
        if (caso.fecha_cierre) {
          return (
            moment(caso.fecha_cierre.toDate()).format("YYYY-MM-DD") <=
            fechaHastaCierre
          );
        } else {
          return false;
        }
      });
    }
    //Filtros para ingreso en general
    for (const [key, value] of Object.entries(filtros)) {
      if (key === "estado" || key === "etapa") {
        if (value !== "Todos") {
          casosFiltrados = casosFiltrados.filter((caso) => caso[key] === value);
        }
      } else if (value !== "Todos") {
        casosFiltrados = casosFiltrados.filter(
          (caso) => caso.ingresoData[key] === value
        );
      }
    }

    //Filtros para victimas
    for (const [key, value] of Object.entries(filtrosVictima)) {
      if (value !== "Todos") {
        const condicionFiltro = (victima) => victima[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["victimas"] !== ""
            ? caso.ingresoData["victimas"].some(condicionFiltro)
            : false
        );
      }
    }

    //Filtros para ofensores
    for (const [key, value] of Object.entries(filtrosOfensor)) {
      if (value !== "Todos") {
        const condicionFiltro = (ofensor) => ofensor[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["ofensores"] !== ""
            ? caso.ingresoData["ofensores"].some(condicionFiltro)
            : false
        );
      }
    }

    //Filtros para concurrentes
    for (const [key, value] of Object.entries(filtrosConcurrente)) {
      if (value !== "Todos") {
        const condicionFiltro = (concurrente) => concurrente[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) =>
          caso.ingresoData["concurrentes"] !== ""
            ? caso.ingresoData["concurrentes"].some(condicionFiltro)
            : false
        );
      }
    }
    setTimeout(() => {
      setIsCargandoData(false);
    }, 1000);
    setCasos(casosFiltrados);
  }, [
    filtros,
    filtrosVictima,
    filtrosOfensor,
    filtrosConcurrente,
    fechaDesdeIngreso,
    fechaHastaIngreso,
    props.allCasos,
    fechaDesdeCierre,
    fechaHastaCierre,
  ]);

  //Generación eje X
  useEffect(() => {
    let result = {};
    let data = [];
    let containerDiario = [];
    if (ejeX !== "Actividades") {
      for (const alternativa of opcionesEjeX[ejeX]["opciones"]) {
        let campo = opcionesEjeX[ejeX]["campo"];
        //Caso Etapa
        if (ejeX === "Etapa") {
          result[alternativa] = casos.filter(
            (caso) => caso[campo] === alternativa
          ).length;
          //Caso Diario
        } else {
          //Cualquier otra alternativa
          result[alternativa] = casos.filter(
            (caso) => caso.ingresoData[campo] === alternativa
          ).length;
        }
      }
      if (ejeX === "Ingreso diario") {
        for (const alternativa of dayArray) {
          let value = casos.filter((caso) => {
            try {
              return (
                moment(caso.ingresoData["fecha_ingreso"].toDate()).format(
                  "YYYY-MM-DD"
                ) === alternativa
              );
            } catch {
              return false;
            }
          }).length;
          result[alternativa] = value === 0 ? null : value;
        }
      }
      if (ejeX === "Ingreso mensual") {
        for (const alternativa of monthArray) {
          let value = casos.filter((caso) => {
            try {
              return (
                moment(caso.ingresoData["fecha_ingreso"].toDate())
                  .format("YYYY-MM-DD")
                  .slice(0, 7) === alternativa
              );
            } catch {
              return false;
            }
          }).length;
          result[alternativa] = value === 0 ? null : value;
        }
      }
    } else {
      let convocatorias = 0;
      let entrevistas = 0;
      let sesiones = 0;
      let convocatoriasCaso = 0;
      let entrevistasCaso = 0;
      let sesionesCaso = 0;
      for (const caso of casos) {
        try {
          if (caso.etapa !== "Ingreso") {
            if (caso.premediacionData.actividades !== "") {
              convocatoriasCaso = caso.premediacionData.actividades.filter(
                (actividad) =>
                  actividad.tipo === "convocatoria" &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) <= fechaHastaIngreso &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) >= fechaDesdeIngreso
              ).length;
              entrevistasCaso = caso.premediacionData.actividades.filter(
                (actividad) =>
                  actividad.tipo === "entrevista" &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) <= fechaHastaIngreso &&
                  moment(actividad.fecha_interaccion.toDate()).format(
                    "YYYY-MM-DD"
                  ) >= fechaDesdeIngreso
              ).length;
              convocatorias = convocatorias + convocatoriasCaso;
              entrevistas = entrevistas + entrevistasCaso;
            }
          }
          if (caso.etapa !== "Ingreso" && caso.etapa !== "Premediación") {
            sesionesCaso = caso.mediacionData.sesiones.filter(
              (sesion) =>
                moment(sesion.fecha_sesion_mediacion.toDate()).format(
                  "YYYY-MM-DD"
                ) <= fechaHastaIngreso &&
                moment(sesion.fecha_sesion_mediacion.toDate()).format(
                  "YYYY-MM-DD"
                ) >= fechaDesdeIngreso
            ).length;
            sesiones = sesiones + sesionesCaso;
          }
        } catch (error) {
          console.log(caso.id);
        }
      }
      result["Convocatorias"] = convocatorias;
      result["Entrevistas"] = entrevistas;
      result["Sesiones Mediación"] = sesiones;
    }

    for (const [key, value] of Object.entries(result)) {
      let dataKey =
        ejeX === "Delitos" ? key.charAt(0) + key.slice(1).toLowerCase() : key;
      if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
        containerDiario.push({
          x: dataKey,
          y: value,
        });
      } else {
        data.push({
          [dataKey]: value,
          campo: dataKey,
          value: value,
        });
      }
    }
    if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
      data = [
        {
          id: "diario",
          data: containerDiario,
        },
      ];
    }

    setConteo(result);
    setData(data);
    setTimeout(() => {
      setIsCargandoData(false);
    }, 1000);
  }, [
    casos,
    colorScaleEtapa,
    dayArray,
    ejeX,
    fechaDesdeIngreso,
    fechaHastaIngreso,
    monthArray,
    opcionesEjeX,
  ]);

  //Función modificar filtros generales
  function addFiltro(tipo, campo, value) {
    setIsCargandoData(true);
    if (tipo === "general") {
      let filtrosCopy = { ...filtros };
      filtrosCopy[campo] = value;
      setFiltros(filtrosCopy);
    }
    if (tipo === "victima") {
      let filtrosCopy = { ...filtrosVictima };
      filtrosCopy[campo] = value;
      setFiltrosVictima(filtrosCopy);
    }
    if (tipo === "ofensor") {
      let filtrosCopy = { ...filtrosOfensor };
      filtrosCopy[campo] = value;
      setFiltrosOfensor(filtrosCopy);
    }
    if (tipo === "concurrente") {
      let filtrosCopy = { ...filtrosConcurrente };
      filtrosCopy[campo] = value;
      setFiltrosConcurrente(filtrosCopy);
    }
  }

  const exportToExcel = async (excelData, fileName, fileExtension) => {
    let cleanedData = [];
    console.log(excelData);
    if (ejeX === "Ingreso diario" || ejeX === "Ingreso mensual") {
      console.log("Entré a if")
      excelData[0]["data"].map((entry) => {
        cleanedData.push({
          fecha: entry.x,
          cantidad: entry.y,
        });
        return cleanedData;
      });
    }
    excelData.map((entry) => {
      cleanedData.push({
        campo: entry.campo,
        cantidad: entry.value,
      });
      return cleanedData;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charet=UTF-8";
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    if (fileExtension === ".xlsx") {
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataToExport = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    } else {
      const csv = XLSX.utils.sheet_to_csv(ws);
      const csvBuffer = new ArrayBuffer(csv.length);
      const view = new Uint8Array(csvBuffer);
      for (let i = 0; i !== csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xff;
      const dataToExport = new Blob([csvBuffer], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(dataToExport, fileName + fileExtension);
    }
  };
  const exportToImage = async (filename, extension) => {
    if (!chart.current) {
      return;
    }
    const dataUrl = await toPng(chart.current);
    download(dataUrl, filename + extension);
  };

  const chart = useRef();

  return (
    <div className={classes.mainContainer}>
      {/* Sección de filtros */}
      <div className={classes.filtrosColumn}>
        <FiltrosGenerales
          opcionesEjeX={opcionesEjeX}
          ejeX={ejeX}
          setIsCargandoData={setIsCargandoData}
          setEjeX={setEjeX}
          setFechaDesdeCierre={setFechaDesdeCierre}
          setFechaHastaCierre={setFechaHastaCierre}
          addFiltro={addFiltro}
        ></FiltrosGenerales>
        <FiltrosParticipantes
          titulo="Filtros Víctima"
          tipoParticipante="victima"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
        <FiltrosParticipantes
          titulo="Filtros Ofensor"
          tipoParticipante="ofensor"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
        <FiltrosParticipantes
          titulo="Filtros Concurrentes"
          tipoParticipante="concurrente"
          addFiltro={addFiltro}
        ></FiltrosParticipantes>
      </div>
      <div className={classes.graphContainer}>
        <div className={classes.botonDescarga}>
          <Button
            id="botonDescarga"
            variant="contained"
            className={classes.botonDescarga2}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Descargar
          </Button>
          <Menu
            id="menuDescarga"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                exportToExcel(
                  data,
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".xlsx"
                );
                handleClose();
              }}
            >
              Data en Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToExcel(
                  data,
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".csv"
                );
                handleClose();
              }}
            >
              Data en CSV
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToImage(
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".png"
                );
                handleClose();
              }}
            >
              Gráfico PNG
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToImage(
                  "Estadisticas_" + ejeX + "_" + moment().format("YYYY-MM-DD"),
                  ".jpg"
                );
                handleClose();
              }}
            >
              Gráfico JPG
            </MenuItem>
          </Menu>
        </div>
        {isCargandoData ? (
          <div className={classes.loadingGraph}>
            <HashLoader color="#36d7b7" size={80} />
          </div>
        ) : (
          /* Sección de gráfico */
          <div className={classes.grafico} ref={chart}>
            {ejeX === "Ingreso diario" || ejeX === "Ingreso mensual" ? (
              <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                curve="monotoneX"
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: true,
                  reverse: false,
                }}
                axisLeft={{ tickSize: 1 }}
                axisBottom={{
                  format: function (value) {
                    if (ejeX === "Ingreso diario") {
                      return value.slice(-2) === "01" ? value : "";
                    } else {
                      return value;
                    }
                  },
                }}
                pointSize={10}
                pointColor="#f54107"
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
              ></ResponsiveLine>
            ) : (
              <ResponsiveBar
                ref={chart}
                data={data}
                keys={keysChart[ejeX]}
                indexBy="campo"
                enableLabel={false}
                tooltip={(d) => {
                  return <div>{d.value}</div>;
                }}
                defs={ejeX === "Etapa" ? defEtapa : defGradientes}
                fill={ejeX === "Etapa" ? fillEtapa : fillGradientes}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                padding={0.3}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={"#FFFFFF"}
                role="application"
                axisBottom={{
                  tickRotation:
                    ejeX === "Delitos" || ejeX === "Comunas" ? 10 : 0,
                }}
              ></ResponsiveBar>
            )}
          </div>
        )}
      </div>
      {/* Sección de resumen */}
      <div className={classes.summaryContainer}>
        {/* Selector de fechas de ingreso */}
        <div className={classes.dateContainer}>
          <div>
            <strong>Fecha de ingreso</strong>
          </div>
          <hr className={classes.divider} />
          <div className={classes.date}>
            <div>
              <strong>Desde</strong>
            </div>
            <div>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                defaultValue={fechaDesdeIngreso}
                onChange={(event) => {
                  setIsCargandoData(true);
                  event.target.value === ""
                    ? setFechaDesdeIngreso(
                        moment(new Date("2023-05-01")).format("YYYY-MM-DD")
                      )
                    : setFechaDesdeIngreso(
                        moment(event.target.value).format("YYYY-MM-DD")
                      );
                  event.target.value === ""
                    ? setDayArray(
                        dateArray(
                          moment(new Date("2023-05-01")),
                          moment(new Date(fechaHastaIngreso))
                        )
                      )
                    : setDayArray(
                        dateArray(
                          moment(event.target.value),
                          moment(new Date(fechaHastaIngreso))
                        )
                      );
                  event.target.value === ""
                    ? setMonthArray(
                        monthArrayGenerator(
                          moment(new Date("2023-05-01")),
                          moment(new Date(fechaHastaIngreso))
                        )
                      )
                    : setMonthArray(
                        monthArrayGenerator(
                          moment(event.target.value),
                          moment(new Date(fechaHastaIngreso))
                        )
                      );
                }}
              />
            </div>
          </div>
          <div className={classes.date}>
            <div>
              <strong>Hasta</strong>
            </div>
            <div>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                defaultValue={fechaHastaIngreso}
                onChange={(event) => {
                  setIsCargandoData(true);
                  event.target.value === ""
                    ? setFechaHastaIngreso(moment().format("YYYY-MM-DD"))
                    : setFechaHastaIngreso(
                        moment(event.target.value).format("YYYY-MM-DD")
                      );
                  event.target.value === ""
                    ? setDayArray(
                        dateArray(moment(new Date(fechaDesdeIngreso)), moment())
                      )
                    : setDayArray(
                        dateArray(
                          moment(new Date(fechaDesdeIngreso)),
                          moment(event.target.value)
                        )
                      );
                  event.target.value === ""
                    ? setMonthArray(
                        monthArrayGenerator(
                          moment(new Date(fechaDesdeIngreso)),
                          moment()
                        )
                      )
                    : setMonthArray(
                        monthArrayGenerator(
                          moment(new Date(fechaDesdeIngreso)),
                          moment(event.target.value)
                        )
                      );
                }}
              />
            </div>
          </div>
        </div>
        {/* Selector de fecha de finalización*/}
        {filtros.estado === "finalizado" ? (
          <div className={classes.dateContainer}>
            <div>Fecha de cierre</div>
            <div className={classes.date}>
              <div>Desde:</div>
              <div>
                <input
                  className={classes.actionBox}
                  type="date"
                  name=""
                  id=""
                  defaultValue={fechaDesdeCierre}
                  onChange={(event) => {
                    setIsCargandoData(true);
                    event.target.value === ""
                      ? setFechaDesdeCierre("")
                      : setFechaDesdeCierre(
                          moment(event.target.value).format("YYYY-MM-DD")
                        );
                  }}
                />
              </div>
            </div>
            <div className={classes.date}>
              <div>Hasta:</div>
              <div>
                <input
                  className={classes.actionBox}
                  type="date"
                  name=""
                  id=""
                  defaultValue={fechaHastaCierre}
                  onChange={(event) => {
                    setIsCargandoData(true);
                    event.target.value === ""
                      ? setFechaHastaCierre("")
                      : setFechaHastaCierre(
                          moment(event.target.value).format("YYYY-MM-DD")
                        );
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* Sección resumen */}
        <div className={classes.resumen}>
          {Object.keys(conteo).map(function (key, i) {
            return (
              <div key={key} className={classes.summaryItem}>
                <div>
                  {ejeX === "Delitos" ? (
                    <div className={classes.summaryLineContainer}>
                      <div
                        style={{
                          background: `linear-gradient(to right, ${
                            colorScaleEtapa[
                              key.charAt(0) + key.slice(1).toLowerCase()
                            ]
                          }, ${
                            colorScaleEtapa[
                              key.charAt(0) + key.slice(1).toLowerCase() + "2"
                            ]
                          })`,
                        }}
                        className={classes.summaryColor}
                      ></div>
                      <div>{key.charAt(0) + key.slice(1).toLowerCase()}</div>
                    </div>
                  ) : (
                    <div className={classes.summaryLineContainer}>
                      <div
                        style={{
                          background: `linear-gradient(to right, ${
                            colorScaleEtapa[key]
                          }, ${colorScaleEtapa[key + "2"]})`,
                        }}
                        className={classes.summaryColor}
                      ></div>
                      <div>{key}</div>
                    </div>
                  )}
                </div>
                <div className={classes.keyValue}>{conteo[key]}</div>
                <hr className={classes.separator} />
              </div>
            );
          })}
          <div className={classes.summaryItem}>
            <div>Total: </div>
            <div>{casos.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Graficos;
