import { useState, useEffect } from "react";
import {
  addPremediacion,
  updatePremediacion,
} from "../../firebase/firebasePostData";
import classes from "./PremediacionEntrevista.module.css";
import { relacionPartes } from "../data/opciones";
import { Timestamp } from "firebase/firestore";
import { asesoresLegales, canales } from "../data/opciones";
import { postStorage } from "../../firebase/firebaseStorage";
import { toast } from "react-toastify";

function PremediacionEntrevista(props) {
  // *****************************************
  // Funciones, Hooks y States para Subir Archivo a Storage
  const [file, setFile] = useState("");

  // *****************************************
  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }
  const [guardado, setGuardado] = useState(true);
  const [tipoParticipante, setTipoParticipante] = useState(
    props.datosEntrevista ? props.datosEntrevista.tipoParticipante : ""
  );
  const [participante, setParticipante] = useState(
    props.datosEntrevista ? props.datosEntrevista.participante : ""
  );
  const [numero_entrevista, setNumero_entrevista] = useState(
    props.datosEntrevista ? props.datosEntrevista.numero_entrevista : 1
  );
  const [fecha_interaccion, setFecha_interaccion] = useState(
    props.datosEntrevista ? props.datosEntrevista.fecha_interaccion : ""
  );
  const [canal_entrevista, setCanal_entrevista] = useState(
    props.datosEntrevista ? props.datosEntrevista.canal_entrevista : ""
  );
  const [participa_asesor_legal_bool, setParticipa_asesor_legal_bool] =
    useState(
      props.datosEntrevista
        ? props.datosEntrevista.participa_asesor_legal_bool
        : false
    );
  const [tipo_asesor_legal, setTipo_asesor_legal] = useState(
    props.datosEntrevista ? props.datosEntrevista.tipo_asesor_legal : ""
  );
  const [participan_concurrentes_bool, setParticipan_concurrentes_bool] =
    useState(
      props.datosEntrevista
        ? props.datosEntrevista.participan_concurrentes_bool
        : false
    );
  const [relacion_concurrente, setRelacion_concurrentes] = useState(
    props.datosEntrevista ? props.datosEntrevista.relacion_concurrente : ""
  );
  const [
    fija_fecha_nueva_entrevista_bool,
    setFija_fecha_nueva_entrevista_bool,
  ] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.fija_fecha_nueva_entrevista_bool
      : false
  );
  const [fecha_nueva_entrevista, setFecha_nueva_entrevista] = useState(
    props.datosEntrevista ? props.datosEntrevista.fecha_nueva_entrevista : ""
  );
  const [
    fija_fecha_sesion_mediacion_bool,
    setFija_fecha_sesion_mediacion_bool,
  ] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.fija_fecha_sesion_mediacion_bool
      : false
  );
  const [fecha_sesion_mediacion, setFecha_sesion_mediacion] = useState(
    props.datosEntrevista ? props.datosEntrevista.fecha_sesion_mediacion : ""
  );
  const [documento_consentimiento, setDocumento_consentimiento] = useState(
    props.datosEntrevista ? props.datosEntrevista.documento_consentimiento : ""
  );
  const [observaciones_entrevista, setObservaciones_entrevista] = useState(
    props.datosEntrevista ? props.datosEntrevista.observaciones_entrevista : ""
  );
  const [entrega_informacion_bool, setEntrega_informacion_bool] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.entrega_informacion_bool
      : false
  );
  const [convoca_nueva_entrevista_bool, setConvoca_entrevista_bool] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.convoca_nueva_entrevista_bool
      : false
  );
  const [acepta_mediacion_bool, setAcepta_mediacion_bool] = useState(
    props.datosEntrevista ? props.datosEntrevista.acepta_mediacion_bool : false
  );
  const [
    se_informa_contraparte_no_participa_bool,
    setSe_informa_contraparte_no_participa_bool,
  ] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.se_informa_contraparte_no_participa_bool
      : false
  );
  const [expresa_consentimiento_bool, setExpresa_consentimiento_bool] =
    useState(
      props.datosEntrevista
        ? props.datosEntrevista.expresa_consentimiento_bool
        : false
    );
  const [cerrar_en_entrevista, setCerrar_en_entrevista] = useState(
    props.datosEntrevista ? props.datosEntrevista.cerrar_en_entrevista : false
  );
  const [
    mediador_decide_caso_no_mediable,
    setMediador_decide_caso_no_mediable,
  ] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.mediador_decide_caso_no_mediable
      : false
  );
  const [rechaza_participar_bool, setRechaza_participar_bool] = useState(
    props.datosEntrevista
      ? props.datosEntrevista.rechaza_participar_bool
      : false
  );
  const [ninguno_bool, setNinguno_bool] = useState(
    props.datosEntrevista ? props.datosEntrevista.ninguno_bool : false
  );
  const [resultado, setResultado] = useState(
    props.datosEntrevista ? props.datosEntrevista.resultado : ""
  );

  let participantes = [];
  let numeroInteracciones = {};
  let tipoParticipantes = {};

  props.participantes.map((participante) => {
    let nombreApellido = participante.nombres + " " + participante.apellidos;
    tipoParticipantes[nombreApellido] = participante.tipo;
    participantes.push(nombreApellido);
    numeroInteracciones[nombreApellido] = { entrevistas: 0, convocatorias: 0 };
    return true;
  });

  props.actividades.map((actividad) => {
    for (let i = 0; i < participantes.length; i++) {
      if (
        actividad.participante === participantes[i] &&
        actividad.tipo === "entrevistas"
      ) {
        numeroInteracciones[participantes[i]]["entrevistas"] =
          numeroInteracciones[participantes[i]]["entrevistas"] + 1;
      }
    }
    return true;
  });

  let actividadData = {
    tipo: "entrevista",
    participante: participante,
    tipoParticipante: tipoParticipante,
    numero_entrevista: numero_entrevista,
    fecha_interaccion: fecha_interaccion,
    canal_entrevista: canal_entrevista,
    participa_asesor_legal_bool: participa_asesor_legal_bool,
    tipo_asesor_legal: tipo_asesor_legal,
    participan_concurrentes_bool: participan_concurrentes_bool,
    relacion_concurrente: relacion_concurrente,
    entrega_informacion_bool: entrega_informacion_bool,
    convoca_nueva_entrevista_bool: convoca_nueva_entrevista_bool,
    expresa_consentimiento_bool: expresa_consentimiento_bool,
    se_informa_contraparte_no_participa_bool:
      se_informa_contraparte_no_participa_bool,
    mediador_decide_caso_no_mediable: mediador_decide_caso_no_mediable,
    rechaza_participar_bool: rechaza_participar_bool,
    ninguno_bool: ninguno_bool,
    cerrar_en_entrevista: cerrar_en_entrevista,
    fija_fecha_nueva_entrevista_bool: fija_fecha_nueva_entrevista_bool,
    fecha_nueva_entrevista: fecha_nueva_entrevista,
    fija_fecha_sesion_mediacion_bool: fija_fecha_sesion_mediacion_bool,
    fecha_sesion_mediacion: fecha_sesion_mediacion,
    documento_consentimiento: documento_consentimiento,
    observaciones_entrevista: observaciones_entrevista,
    resultado: resultado,
  };

  // EFECTO PARA DETECTAR CAMPOS OBLIGATORIOS

  const [datosObligatoriosVerificados, setDatosObligatoriosVerificados] =
    useState(false);

  const [objetivo_checked, setObjetivo_checked] = useState(false);

  // useEffect(() => {
  //   if (resultado !== "") {
  //     if (tipoParticipante === "ofensor") {
  //       props.setEstadoActualOfensor(resultado);
  //     } else if (tipoParticipante === "victima") {
  //       props.setEstadoActualVictima(resultado);
  //     }
  //   } else {
  //     if (tipoParticipante === "ofensor") {
  //       props.setEstadoActualOfensor(resultado);
  //     } else if (tipoParticipante === "victima") {
  //       props.setEstadoActualVictima(resultado);
  //     }
  //   }
  // }, [resultado]);

  useEffect(() => {
    if (
      participante !== "" &&
      fecha_interaccion !== "" &&
      canal_entrevista !== "" &&
      (entrega_informacion_bool === true ||
        convoca_nueva_entrevista_bool === true ||
        acepta_mediacion_bool === true ||
        se_informa_contraparte_no_participa_bool === true ||
        expresa_consentimiento_bool === true ||
        rechaza_participar_bool === true ||
        ninguno_bool === true ||
        mediador_decide_caso_no_mediable === true)
    ) {
      setDatosObligatoriosVerificados(true);
    } else {
      setDatosObligatoriosVerificados(false);
    }
  }, [
    participante,
    fecha_interaccion,
    canal_entrevista,
    entrega_informacion_bool,
    convoca_nueva_entrevista_bool,
    acepta_mediacion_bool,
    se_informa_contraparte_no_participa_bool,
    expresa_consentimiento_bool,
    rechaza_participar_bool,
    ninguno_bool,
    mediador_decide_caso_no_mediable,
  ]);

  const notify = () => {
    toast.success("Datos Entrevista guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const toast_not_pdf = () => {
    toast.error("El archivo de consentimiento debe estar en formato PDF", {
      position: toast.POSITION.TOP_CENTER,
      duration: 5000,
    });
  };

  const handleEntregaInformacionChange = (event) => {
    setEntrega_informacion_bool(event.target.checked);
    setResultado("Entrega información");
  };

  const handleConvocaEntrevistaChange = (event) => {
    setConvoca_entrevista_bool(event.target.checked);
    setResultado("Fija entrevista");
  };

  const handleExpresaConsentimientoChange = (event) => {
    setExpresa_consentimiento_bool(event.target.checked);
    setResultado("Expresa formalmente consentimiento");
  };

  const handleNingunoChange = (event) => {
    setNinguno_bool(event.target.checked);
    setResultado("Ninguno");
  };

  const handleSeInformaContraparteNoParticipaChange = (event) => {
    setSe_informa_contraparte_no_participa_bool(event.target.checked);
    setResultado("Contraparte no participa");
  };

  const handleRechazaParticiparChange = (event) => {
    setRechaza_participar_bool(event.target.checked);
    setResultado("Rechaza participar");
  };

  const handleMediadorDecideCasoNoMediableChange = (event) => {
    setMediador_decide_caso_no_mediable(event.target.checked);
    setResultado("Caso no mediable");
  };

  //const handleFechaSesionMediacionChange = (event) => {
  //  setFecha_sesion_mediacion_bool(event.target.checked);
  //};

  return (
    <div className={classes.mainComponente}>
      <h2>Datos Entrevista</h2>
      <div>
        {guardado ? (
          <div className={classes.saveTrue}></div>
        ) : (
          <div className={classes.saveFalse}>Sin Guardar</div>
        )}
        <div className={classes.subtitulo}>Participante*</div>
        <div>
          <select
            className={classes.actionBox}
            name=""
            id=""
            defaultValue={participante}
            onChange={(event) => {
              setParticipante(event.target.value);
              setNumero_entrevista(
                numeroInteracciones[event.target.value]["entrevistas"] + 1
              );
              setTipoParticipante(tipoParticipantes[event.target.value]);
              setGuardado(false);
            }}
          >
            <option disabled value="">
              {" "}
              -- Seleccione --{" "}
            </option>
            {participantes.map(function (participante, i) {
              return (
                <option value={participante} key={i}>
                  {participante}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <p>Número de Interacción: {numero_entrevista}</p>
      </div>
      <div className={classes.dosPartes}>
        <div>
          <div className={classes.subtitulo}>Fecha interacción*</div>
          <input
            className={classes.actionBox}
            type="date"
            name=""
            id=""
            onChange={(event) => {
              let newDate = new Date(event.target.value + "T00:00");
              let newTimestamp = Timestamp.fromDate(newDate);
              setFecha_interaccion(newTimestamp);
              setGuardado(false);
            }}
            defaultValue={handleDate(fecha_interaccion)
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>
        <div>
          <div className={classes.subtitulo}>Canal entrevista*</div>
          <div>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={canal_entrevista}
              onChange={(event) => setCanal_entrevista(event.target.value)}
            >
              <option disabled value="">
                {" "}
                -- Seleccione --{" "}
              </option>
              {canales.map(function (canal, i) {
                return (
                  <option value={canal} key={i}>
                    {canal}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div>
        <p className={classes.subtitulo}>Participa asesor legal*</p>
        <div className={classes.selectOne}>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={participa_asesor_legal_bool}
              onChange={(event) => {
                setParticipa_asesor_legal_bool(event.target.checked);
              }}
            />
            <span>Sí</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={!participa_asesor_legal_bool}
              onChange={(event) => {
                setParticipa_asesor_legal_bool(!event.target.checked);
              }}
            />
            <span>No</span>
          </div>
        </div>
      </div>
      {participa_asesor_legal_bool ? (
        <div>
          <div className={classes.subtitulo}>Tipo asesor legal</div>
          <div>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={tipo_asesor_legal}
              onChange={(event) => setTipo_asesor_legal(event.target.value)}
            >
              <option disabled value="">
                {" "}
                -- Seleccione --{" "}
              </option>
              {asesoresLegales.map(function (asesor, i) {
                return (
                  <option value={asesor} key={i}>
                    {asesor}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        <p className={classes.subtitulo}>Participan otros concurrentes</p>
        <div className={classes.selectOne}>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={participan_concurrentes_bool}
              onChange={(event) => {
                setParticipan_concurrentes_bool(event.target.checked);
              }}
            />
            <span>Sí</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={!participan_concurrentes_bool}
              onChange={(event) => {
                setParticipan_concurrentes_bool(!event.target.checked);
              }}
            />
            <span>No</span>
          </div>
        </div>
      </div>
      {participan_concurrentes_bool ? (
        <div>
          <p className={classes.subtitulo}>
            Relación del concurrente con participante
          </p>
          <select
            className={classes.actionBox}
            name=""
            id=""
            defaultValue={relacion_concurrente ? relacion_concurrente : " "}
            onChange={(event) => {
              setRelacion_concurrentes(event.target.value);
              setGuardado(false);
            }}
          >
            <option disabled value=" ">
              {" "}
              -- Seleccione --{" "}
            </option>
            {relacionPartes.map(function (relacion, i) {
              return (
                <option
                  value={relacion}
                  key={"concurrente_entrevista_relacion_" + i}
                >
                  {relacion}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        ""
      )}

      <div>
        <p className={classes.subtitulo}>Objetivo(s) Alcanzado(s)*</p>
        <div className={classes.dosPartes}>
          <div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={entrega_informacion_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(true);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Entrega información");
                }}
              />
              <span>Entrega información</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={convoca_nueva_entrevista_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(true);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Fija entrevista");
                }}
              />
              <span>Se agenda nueva entrevista</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={expresa_consentimiento_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(true);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Expresa formalmente consentimiento");
                }}
              />
              <span>Expresa formalmente consentimiento</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={ninguno_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(true);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Ninguno");
                }}
              />
              <span>Ninguno</span>
            </div>
          </div>
          <div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={se_informa_contraparte_no_participa_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(true);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Contraparte no participa");
                }}
              />
              <span>
                Se le informa que la otra parte no fue ubicable o no quiso
                participar
              </span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={rechaza_participar_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(true);
                  setMediador_decide_caso_no_mediable(false);
                  setResultado("Rechaza participar");
                }}
              />
              <span>Rechaza participar</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                name="objetivoAlcanzado"
                checked={mediador_decide_caso_no_mediable}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setConvoca_entrevista_bool(false);
                  setExpresa_consentimiento_bool(false);
                  setNinguno_bool(false);
                  setSe_informa_contraparte_no_participa_bool(false);
                  setRechaza_participar_bool(false);
                  setMediador_decide_caso_no_mediable(true);
                  setResultado("Caso no mediable");
                }}
              />
              <span>Mediador decide que el caso no es mediable</span>
            </div>
          </div>
        </div>
      </div>
      {resultado === "Expresa formalmente consentimiento" ? (
        <div>
          <p className={classes.subtitulo}>
            Fija fecha para primera sesión de mediación
          </p>
          <div className={classes.selectOne}>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={fija_fecha_sesion_mediacion_bool}
                onChange={(event) => {
                  setFija_fecha_sesion_mediacion_bool(event.target.checked);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={!fija_fecha_sesion_mediacion_bool}
                onChange={(event) => {
                  setFija_fecha_sesion_mediacion_bool(!event.target.checked);
                }}
              />
              <span>No</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {fija_fecha_sesion_mediacion_bool &&
      resultado === "Expresa formalmente consentimiento" ? (
        <div>
          <p className={classes.subtitulo}>Fecha para sesión de mediación</p>
          <input
            type="date"
            onChange={(event) => {
              let newDate = new Date(event.target.value + "T00:00");
              let newTimestamp = Timestamp.fromDate(newDate);
              setFecha_sesion_mediacion(newTimestamp);
              setGuardado(false);
            }}
            defaultValue={handleDate(fecha_sesion_mediacion)
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>
      ) : (
        ""
      )}
      {resultado === "Fija entrevista" ? (
        <div>
          <p className={classes.subtitulo}>Fija fecha para nueva entrevista</p>
          <div className={classes.selectOne}>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={fija_fecha_nueva_entrevista_bool}
                onChange={(event) => {
                  setFija_fecha_nueva_entrevista_bool(event.target.checked);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={!fija_fecha_nueva_entrevista_bool}
                onChange={(event) => {
                  setFija_fecha_nueva_entrevista_bool(!event.target.checked);
                }}
              />
              <span>No</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {fija_fecha_nueva_entrevista_bool && resultado === "Fija entrevista" ? (
        <div>
          <p className={classes.subtitulo}>Fecha para entrevista</p>
          <input
            type="date"
            onChange={(event) => {
              let newDate = new Date(event.target.value + "T00:00");
              let newTimestamp = Timestamp.fromDate(newDate);
              setFecha_nueva_entrevista(newTimestamp);
              setGuardado(false);
            }}
            defaultValue={handleDate(fecha_nueva_entrevista)
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>
      ) : (
        ""
      )}
      {resultado === "Expresa formalmente consentimiento" ? (
        <div>
          <p className={classes.subtitulo}>Adjuntar Documento Consentimiento</p>
          <label htmlFor="file-input" className={classes.fileInputLabel}>
            Subir archivo consentimiento
          </label>
          <input
            type="file"
            id="file-input"
            className={classes.fileInput}
            onChange={(e) => {
              if (
                e.target.files[0].name.slice(-4) !== ".pdf" &&
                e.target.files[0].name.slice(-4) !== ".PDF"
              ) {
                e.target.nextElementSibling.innerText =
                  "Sube un archivo válido";
                toast_not_pdf();
              } else {
                e.target.nextElementSibling.innerText = e.target.files[0].name;
                setFile(e.target.files[0]);
              }
            }}
          />
          <span className={classes.fileName}>
            {documento_consentimiento !== "" ? "¿Reemplazar archivo?" : ""}
          </span>
        </div>
      ) : (
        ""
      )}

      <div>
        <p className={classes.subtitulo}>Observaciones entrevista</p>
        <textarea
          cols="60"
          rows="10"
          className={classes.textarea}
          name=""
          id=""
          onChange={(event) => {
            setObservaciones_entrevista(event.target.value);
            setGuardado(false);
          }}
          defaultValue={observaciones_entrevista}
        ></textarea>
      </div>
      {!datosObligatoriosVerificados ? (
        <p className={classes.mensaje_error_datos}>
          Debe rellenar/seleccionar todos los campos obligatorios*
        </p>
      ) : (
        ""
      )}
      <div className={classes.guardado_btn_div}>
        <button
          id="guardar_btn_premediacion_entrevista"
          className={`${
            !datosObligatoriosVerificados
              ? classes.guardado_btn_desactivado
              : classes.guardado_btn
          }`}
          onClick={async () => {
            console.log(actividadData);
            if (file !== "") {
              console.log("entré a if");
              let url;
              await postStorage("premediacion/" + file.name, file).then(
                (archivoRutaDescarga) => {
                  url = archivoRutaDescarga;
                  actividadData["documento_consentimiento"] = url;
                  if (tipoParticipante === "ofensor") {
                    props.setConsentimientoOfensor(url);
                  } else if (tipoParticipante === "victima") {
                    props.setConsentimientoVictima(url);
                  }
                }
              );
            }

            if (
              tipoParticipante === "ofensor" &&
              props.estadoActualOfensor !== "Expresa formalmente consentimiento"
            ) {
              props.setEstadoActualOfensor(resultado);
            } else if (
              tipoParticipante === "victima" &&
              props.estadoActualVictima !== "Expresa formalmente consentimiento"
            ) {
              props.setEstadoActualVictima(resultado);
            }
            console.log(props.indexEntrevista);

            let helperArray = [...props.actividades];
            if (props.indexEntrevista) {
              helperArray[props.indexEntrevista] = actividadData;
            } else {
              helperArray.push(actividadData);
            }

            props.setActividades(helperArray);

            setGuardado(true);
            notify();
            props.volverEventos();
          }}
        >
          GUARDAR
        </button>
      </div>
    </div>
  );
}

export default PremediacionEntrevista;
