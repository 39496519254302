import classes from "./Admin.module.css";
import {
  getAllCasosEstadistica,
  deleteSentitiveData,
} from "../firebase/firebaseGetData";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { reparar, userSignIn } from "../firebase/firebasePostData";
import { delitos, mediadores, derivadores } from "../components/data/opciones";
import { reparacion } from "../components/data/opciones";
import moment from "moment";

function Admin() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [isCargandoAllCasos, setIsCargandoAllCasos] = useState(true);
  const [isFiltrando, setIsFiltrando] = useState(true);
  const [isPaginando, setIsPaginando] = useState(true);

  const [allCasos, setAllCasos] = useState([]);
  const [casosFiltrados, setCasosFiltrados] = useState([]);
  const [casosPaginados, setCasosPaginados] = useState([]);

  // Pruebas Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const casosPorPagina = 4;

  // Estados para filtros
  const [estadoFiltro, setEstadoFiltro] = useState("Todos");
  const [estadoChecked, setEstadoChecked] = useState(false);

  const [delitoFiltro, setDelitoFiltro] = useState("Todos");
  const [delitoChecked, setDelitoChecked] = useState(false);

  const [derivadorFiltro, setDerivadorFiltro] = useState("Todos");
  const [derivadorChecked, setDerivadorChecked] = useState(false);

  const [mediadorFiltro, setMediadorFiltro] = useState("Todos");
  const [mediadorChecked, setMediadorChecked] = useState(false);

  const [etapaFiltro, setEtapaFiltro] = useState("Todos");
  const [etapaChecked, setEtapaChecked] = useState(false);

  const [apellidosFiltro, setApellidosFiltro] = useState("Todos");
  const [apellidosChecked, setApellidosChecked] = useState(false);

  const [rutFiltro, setRutFiltro] = useState("Todos");
  const [rutChecked, setRutChecked] = useState(false);

  const [rucFiltro, setRucFiltro] = useState("Todos");
  const [rucChecked, setRucChecked] = useState(false);
  const [nFiltros, setNFiltros] = useState(0);

  const [casosVerde, setcasosVerde] = useState(0);
  const [casosAmarillo, setcasosAmarillo] = useState(0);
  const [casosRojo, setcasosRojo] = useState(0);
  const [casosMorado, setcasosMorado] = useState(0);

  //Filtros disponibles

  const [filtros, setFiltros] = useState({
    estado: estadoFiltro,
    delito: delitoFiltro,
    ruc: rucFiltro,
    derivador_caso: derivadorFiltro,
    mediador_asignado: mediadorFiltro,
    etapa: etapaFiltro,
    fecha_ingreso: "Todos",
    fecha_ultima_modificacion: "Todos",
  });

  const [filtrosParticipante, setFiltrosParticipante] = useState({
    apellidos: apellidosFiltro,
    rut: rutFiltro,
  });

  //Manejo de inicio de sesión
  useEffect(() => {
    if (loading) return;
    if (!user || user.email !== "martin.fsv1993@gmail.com") {
      return navigate("/login");
    } else {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      return;
    }
  }, [user, loading, navigate]);

  //Obtención de todos los casos
  useEffect(() => {
    if (user) {
      console.log("hay usuario");
      if (isCargandoAllCasos === true) {
        console.log("obteniendo casos");
        getAllCasosEstadistica(setAllCasos, setIsCargandoAllCasos);
      } else {
        console.log("No cargué casos");
      }
    }
  }, [user, isCargandoAllCasos]);

  const convertirFecha = (fecha) => {
    const timestamp = fecha.seconds * 1000 + fecha.nanoseconds / 1000000; // Convertir el objeto de fecha a milisegundos
    return moment(timestamp);
  };
  //Filtrado de casos
  useEffect(() => {
    let casosFiltrados = allCasos;
    casosFiltrados.sort(
      (a, b) =>
        b.ingresoData.fecha_ingreso.seconds -
        a.ingresoData.fecha_ingreso.seconds
    );
    //Filtra casos que terminen con K
    //casosFiltrados = casosFiltrados.filter((caso) => caso["ruc"].endsWith("k"))
    let nEncuestas = 0;
    let encuestaString = ``;
    for (let caso of casosFiltrados) {
      if (Object.keys(caso.encuestas).length > 0) {
        nEncuestas = nEncuestas + Object.keys(caso.encuestas).length;
        encuestaString = encuestaString + caso.ruc + ";";
        encuestaString = encuestaString + caso.ingresoData.delito + ";";
        for (let i = 0; i < Object.keys(caso.encuestas).length; i++) {
          encuestaString =
            encuestaString + Object.keys(caso.encuestas)[i] + ";";
        }
        encuestaString = encuestaString + "\n";
      }
    }
    console.log("nEncuestas: " + nEncuestas);
    let nFiltrosTemp = 0;
    //Filtros Generales
    for (const [key, value] of Object.entries(filtros)) {
      if (
        key === "estado" ||
        key === "fecha_ultima_modificacion" ||
        key === "etapa" ||
        key === "ruc"
      ) {
        if (value !== "Todos" && value !== "Todas") {
          nFiltrosTemp = nFiltrosTemp + 1;
          casosFiltrados = casosFiltrados.filter((caso) => caso[key] === value);
        }
      } else if (value !== "Todos" && value !== "Todas") {
        nFiltrosTemp = nFiltrosTemp + 1;
        casosFiltrados = casosFiltrados.filter(
          (caso) => caso.ingresoData[key] === value
        );
      }
    }
    //Filtros para Participantes
    for (const [key, value] of Object.entries(filtrosParticipante)) {
      if (value !== "Todos" && value !== "Todas") {
        nFiltrosTemp = nFiltrosTemp + 1;
        const condicionFiltro = (participante) => participante[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) => {
          return (
            (caso.ingresoData["victimas"] !== ""
              ? caso.ingresoData["victimas"].some(condicionFiltro)
              : false) ||
            (caso.ingresoData["ofensores"] !== ""
              ? caso.ingresoData["ofensores"].some(condicionFiltro)
              : false) ||
            (caso.ingresoData["concurrentes"] !== ""
              ? caso.ingresoData["concurrentes"].some(condicionFiltro)
              : false)
          );
        });
      }
    }

    //Conteo de casos por fecha
    let verde = 0;
    let amarillo = 0;
    let rojo = 0;
    let morado = 0;
    for (const caso of casosFiltrados) {
      let fechaPasoEtapaActual;
      if (caso.etapa === "Ingreso") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_ingreso);
      }
      if (caso.etapa === "Premediación") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_premediacion);
      }
      if (caso.etapa === "Mediación") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_mediacion);
      }
      if (caso.etapa === "Seguimiento") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_seguimiento);
      }
      if (caso.etapa === "Cierre") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_cierre);
      }
      const fechaActual = moment();
      const diferenciaDias = fechaActual.diff(fechaPasoEtapaActual, "days");

      if (caso.estado === "finalizado") {
        morado = morado + 1;
      } else if (diferenciaDias <= 10) {
        verde = verde + 1;
      } else if (diferenciaDias > 10 && diferenciaDias <= 20) {
        amarillo = amarillo + 1;
      } else if (diferenciaDias > 20) {
        rojo = rojo + 1;
      }
    }
    setNFiltros(nFiltrosTemp);
    setCasosFiltrados(casosFiltrados);
    setIsFiltrando(false);
    setcasosVerde(verde);
    setcasosAmarillo(amarillo);
    setcasosRojo(rojo);
    setcasosMorado(morado);
  }, [allCasos, filtros, filtrosParticipante]);

  //Paginación de casos
  useEffect(() => {
    let casosPaginadosTemp = casosFiltrados.slice(
      (currentPage - 1) * casosPorPagina,
      currentPage * casosPorPagina
    );
    setCasosPaginados(casosPaginadosTemp);
    setIsPaginando(false);
  }, [casosFiltrados, currentPage]);

  //Para ver interacciones
  useEffect(() => {
    let nVictimas = 0;
    let nOfensores = 0;
    let nConcurrentes = 0;
    let convocatoriasVictima = {
      1: 0,
      2: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    let convocatoriasOfensor = {
      1: 0,
      2: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    let entrevistasVictima = {
      1: 0,
      2: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    let entrevistasOfensor = {
      1: 0,
      2: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    let sesiones = {
      1: 0,
      2: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    //fecha_interaccion
    //fecha_sesion_mediacion
    let casosTotales = allCasos.length;
    let casosProcesados = 0;
    for (const caso of allCasos) {
      let currentRuc = caso.ruc;
      try {
        if (caso.etapa !== "Ingreso") {
          //Número de personas atendidas
          nVictimas = nVictimas + caso.ingresoData.victimas.length;
          nOfensores = nOfensores + caso.ingresoData.ofensores.length;
          nConcurrentes = nConcurrentes + caso.ingresoData.concurrentes.length;

          let victima =
            caso.ingresoData.victimas[0].nombres +
            " " +
            caso.ingresoData.victimas[0].apellidos;
          let ofensor =
            caso.ingresoData.ofensores[0].nombres +
            " " +
            caso.ingresoData.ofensores[0].apellidos;
          if (caso.premediacionData.actividades !== "") {
            for (const actividad of caso.premediacionData.actividades) {
              let month =
                +moment(actividad.fecha_interaccion.toDate()).month() + 1;

              if (actividad.tipo === "convocatoria") {
                if (actividad.participante === victima)
                  convocatoriasVictima[month] = convocatoriasVictima[month] + 1;
                if (actividad.participante === ofensor)
                  convocatoriasOfensor[month] = convocatoriasOfensor[month] + 1;
              }
              if (actividad.tipo === "entrevista") {
                if (actividad.participante === victima)
                  entrevistasVictima[month] = entrevistasVictima[month] + 1;
                if (actividad.participante === ofensor)
                  entrevistasOfensor[month] = entrevistasOfensor[month] + 1;
              }
            }
          }
        }
        if (caso.etapa !== "Ingreso" && caso.etapa !== "Premediación") {
          for (const sesion of caso.mediacionData.sesiones) {
            let month = +moment(sesion.fecha_sesion_mediacion.toDate()).month();
            sesiones[month] = sesiones[month] + 1;
          }
        }
        casosProcesados = casosProcesados + 1;
      } catch (error) {
        console.log(error);
        console.log(currentRuc);
      }
    }
    console.log("Casos totales: " + casosTotales);
    console.log("Casos procesados: " + casosProcesados);
    console.log("NVictimas: " + nVictimas);
    console.log("NOfensores: " + nOfensores);
    console.log("NConcurrentes: " + nConcurrentes);
    console.log("Objetos de interacciones");
    console.log(convocatoriasVictima);
    console.log(convocatoriasOfensor);
    console.log(entrevistasVictima);
    console.log(entrevistasOfensor);
    console.log(sesiones);
  }, [allCasos]);

  return isCargandoAllCasos || isFiltrando || isPaginando ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div>
      <h2>SELECCIÓN DE FILTROS PARA BÚSQUEDA</h2>
      {/* Filtros para búsqueda */}
      <div className={classes.filtros_main}>
        <div className={classes.filtros_primer_bloque}>
          <div>
            <div>Filtros</div>
            <div></div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!rucChecked ? `${classes.gray_text}` : ""}>
                RUC
              </span>
              <input
                type="checkbox"
                name=""
                id=""
                checked={rucChecked}
                onChange={(event) => {
                  setRucChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                disabled={rucChecked !== true}
                value={rucFiltro === "Todos" ? "" : rucFiltro}
                onChange={(event) => {
                  if (rucChecked) {
                    setRucFiltro(event.target.value.toUpperCase());
                  } else {
                    setRucFiltro("Todos");
                  }
                }}
                type="text"
              />
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!rutChecked ? `${classes.gray_text}` : ""}>
                RUT
              </span>

              <input
                type="checkbox"
                checked={rutChecked}
                name=""
                id=""
                onChange={(event) => {
                  setRutChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                disabled={rutChecked !== true}
                value={rutFiltro === "Todos" ? "" : rutFiltro}
                onChange={(event) => {
                  setRutFiltro(event.target.value);
                }}
                type="text"
              />
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!derivadorChecked ? `${classes.gray_text}` : ""}>
                Quien deriva
              </span>

              <input
                type="checkbox"
                checked={derivadorChecked}
                onChange={(event) => {
                  setDerivadorChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={derivadorChecked !== true}
                onChange={(event) => {
                  setDerivadorFiltro(event.target.value);
                }}
                name=""
                id=""
                value={derivadorFiltro}
              >
                {derivadores.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div>
            <div className={classes.titleAndCheck}>
              <span className={!apellidosChecked ? `${classes.gray_text}` : ""}>
                Apellidos
              </span>

              <input
                type="checkbox"
                checked={apellidosChecked}
                onChange={(event) => {
                  setApellidosChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                value={apellidosFiltro === "Todos" ? "" : apellidosFiltro}
                disabled={apellidosChecked !== true}
                onChange={(event) => {
                  setApellidosFiltro(event.target.value);
                }}
                type="text"
              />
            </div>
          </div>
        </div>
        <div className={classes.filtros_segundo_bloque}>
          <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!mediadorChecked ? `${classes.gray_text}` : ""}>
                Mediador
              </span>

              <input
                type="checkbox"
                checked={mediadorChecked}
                onChange={(event) => {
                  setMediadorChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={mediadorChecked !== true}
                value={mediadorFiltro}
                onChange={(event) => {
                  setMediadorFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos" key="">
                  Todos
                </option>
                {mediadores.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!etapaChecked ? `${classes.gray_text}` : ""}>
                Etapa
              </span>

              <input
                type="checkbox"
                checked={etapaChecked}
                name=""
                id=""
                onChange={(event) => {
                  setEtapaChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={etapaChecked !== true}
                value={etapaFiltro}
                onChange={(event) => {
                  setEtapaFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos">Todas</option>
                <option value="Ingreso">Ingreso</option>
                <option value="Premediación">Premediación</option>
                <option value="Mediación">Mediación</option>
                <option value="Seguimiento">Seguimiento</option>
                <option value="Cierre">Cierre</option>
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!delitoChecked ? `${classes.gray_text}` : ""}>
                Delito
              </span>

              <input
                type="checkbox"
                checked={delitoChecked}
                name=""
                id=""
                onChange={(event) => {
                  setDelitoChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={delitoChecked !== true}
                value={delitoFiltro}
                onChange={(event) => {
                  setDelitoFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos"> TODOS </option>
                {delitos.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!estadoChecked ? `${classes.gray_text}` : ""}>
                Estado
              </span>

              <input
                type="checkbox"
                checked={estadoChecked}
                onChange={(event) => {
                  setEstadoChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={estadoChecked !== true}
                value={estadoFiltro}
                onChange={(event) => {
                  setEstadoFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos" key="">
                  Todos
                </option>
                <option value="vigente">Vigente</option>
                <option value="finalizado">Finalizado</option>
              </select>
            </div>
          </div>
        </div>
        {/* <div className={classes.filtros_tercer_bloque}>
          <div></div>
          <div>
            <div>
              Fecha Ingreso <input type="checkbox" name="" id="" />
            </div>
            <div>
              Entre <input type="date" /> y <input type="date" />
            </div>
          </div>
          <div>
            <div>
              Última modificación <input type="checkbox" name="" id="" />
            </div>
            <div>
              {" "}
              Entre <input type="date" /> y <input type="date" />
            </div>
          </div>
          <div></div>
          <div></div>
        </div> */}
        <div className={classes.filtros_cuarto_bloque}>
          <div>
            Filtros claves{" "}
            <button
              className={classes.btn_aplicar}
              onClick={() => {
                setFiltros({
                  estado: estadoFiltro,
                  delito: delitoFiltro,
                  ruc: rucFiltro,
                  derivador_caso: derivadorFiltro,
                  mediador_asignado: mediadorFiltro,
                  etapa: etapaFiltro,
                  fecha_ingreso: "Todos",
                  fecha_ultima_modificacion: "Todos",
                });

                setFiltrosParticipante({
                  apellidos: apellidosFiltro,
                  rut: rutFiltro,
                });

                setCurrentPage(1);
              }}
            >
              APLICAR
            </button>{" "}
            <button
              onClick={() => {
                setFiltros({
                  estado: "Todos",
                  delito: "Todos",
                  ruc: "Todos",
                  derivador_caso: "Todos",
                  mediador_asignado: "Todos",
                  etapa: "Todos",
                  fecha_ingreso: "Todos",
                  fecha_ultima_modificacion: "Todos",
                });

                setFiltrosParticipante({
                  apellidos: "Todos",
                  rut: "Todos",
                });

                setEstadoChecked(false);
                setDelitoChecked(false);
                setDerivadorChecked(false);
                setMediadorChecked(false);
                setEtapaChecked(false);
                setApellidosChecked(false);
                setRutChecked(false);
                setRucChecked(false);

                setEstadoFiltro("Todos");
                setDelitoFiltro("Todos");
                setDerivadorFiltro("Todos");
                setMediadorFiltro("Todos");
                setEtapaFiltro("Todos");
                setApellidosFiltro("Todos");
                setRutFiltro("Todos");
                setRucFiltro("Todos");

                setCurrentPage(1);
              }}
              className={classes.btn_borrar}
            >
              RESTAURAR
            </button>
          </div>
          <div>
            <div className={classes.btn_filtros_totales}>
              Filtros aplicados: <span>{nFiltros}</span>
            </div>
            <div className={classes.btn_filtros_totales}>
              Casos totales:{" "}
              <span>
                {casosVerde + casosRojo + casosAmarillo + casosMorado}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Nomenclatura de colores */}

      <div className={classes.conteo_casos}>
        <div className={classes.conteo_10}>
          Casos: <span>{casosVerde}</span>
        </div>
        <div className={classes.conteo_20}>
          Casos: <span>{casosAmarillo}</span>
        </div>
        <div className={classes.conteo_30}>
          Casos: <span>{casosRojo}</span>
        </div>
        <div className={classes.conteo_finalizado}>
          Casos: <span>{casosMorado}</span>
        </div>
      </div>

      <div className={classes.casos}>
        <button
          onClick={() => {
            deleteSentitiveData();
          }}
        >
          Reparar
        </button>
        {casosFiltrados.map((caso) => {
          return (
            <div className={classes.lista} key={caso.id}>
              <span>
                {convertirFecha(caso.ingresoData.fecha_ingreso).format(
                  "YYYY-MM-DD"
                )}
                ;
              </span>
              <span>{caso.ruc};</span>
              <span>{caso.ingresoData.delito};</span>
              <span>{caso.ingresoData.mediador_asignado};</span>
              <span>{caso.etapa};</span>
              <span>{caso.estado}</span>
              {/* <span>
                <div>Delito ingreso: {caso.ingresoData.delito}</div>
                {caso.premediacionData.delito ? (
                  <div>Delito premediacion: {caso.premediacionData.delito}</div>
                ) : (
                  ""
                )}
                {caso.mediacionData.delito ? (
                  <div>Delito mediacion: {caso.mediacionData.delito}</div>
                ) : (
                  ""
                )}
                {caso.seguimientoData.delito ? (
                  <div>Delito seguimiento: {caso.seguimientoData.delito}</div>
                ) : (
                  ""
                )}
                {caso.cierreData.delito ? (
                  <div>Delito cierre: {caso.cierreData.delito}</div>
                ) : (
                  ""
                )}
              </span>
              <span>
                Nuevo delito:
                {reparacion[caso.ruc]}
              </span>
              <span>
                <button
                  onClick={() => {
                    reparar(
                      caso.etapa,
                      caso.id,
                      reparacion[caso.ruc],
                      setIsCargandoAllCasos
                    );
                  }}
                >
                  Reparar
                </button>
              </span> */}
              <hr className={classes.separador} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Admin;
