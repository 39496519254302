import { firestore } from "./firebase_init";
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  Timestamp,
  doc,
} from "firebase/firestore";
import { mediadoresUid } from "../components/data/opciones";

const casos = [];

const admin = [
  "zyaD868YbuTOEMOERvAzH6o8z8U2",
  "8BzdDf7Iufd5JIuKerBlN9BpyGD2",
  "HXKKWbnhqOSrDqA9ztnDKsppafL2",
  "sQVI72Tp7cS5RJVoGefiqMkjudF3",
  "mhlDU2mHQkUNTb7DwqaFf0k5kky1",
  "gcKU2yWc3OWnSnn9cFmlQEEBDoX2",
  "X4iVPlQtX1X77ceOH1swqc8Y0BQ2",
  "I7QwoXBy2dVbGp0aKHs3zaEKCSV2",
  "roF6HA9r8PR0EJudmHfyybss3QW2",
  "7eUzQk2Fl6dClH8DpBz4Ekewr3J3",
  "3hhlLS8HP4UWmI8HOChLo72o4YJ2",
  "RP9V1IrjX8bSOJQiLQwQZRtGElJ2",
  "snZjmbfPDcXmGSZTkyv0Rximx0k2",
];

async function getCasosIngreso(
  uid,
  setCasosIngreso,
  setIsCargandoCasosIngreso
) {
  const qCasosIngreso = query(
    collection(firestore, "ingreso"),
    /* where("estado","==", "ingresado"), */
    orderBy("fecha_ingreso", "desc")
  );

  const unSubIngreso = onSnapshot(qCasosIngreso, async (querySnapshot) => {
    let casosIngreso = [];
    for (const docSnapshot of querySnapshot.docs) {
      const casoIngreso = docSnapshot.data();
      if (casoIngreso.estado !== "finalizado") {
        if (
          admin.includes(uid) ||
          uid === mediadoresUid[casoIngreso["mediador_asignado"]]
        )
          casosIngreso.push({
            ...casoIngreso,
          });
      }
    }
    setCasosIngreso(casosIngreso);
    setIsCargandoCasosIngreso(false);
  });
  return unSubIngreso;
}

async function getCasosPremediacion(
  uid,
  setCasosPremediacion,
  setIsCargandoCasosPremediacion
) {
  const qCasosPremediacion = query(
    collection(firestore, "premediacion"),
    /* where("estado","==", "ingresado"), */
    orderBy("fecha_ingreso", "desc")
  );

  const unSubPremediacion = onSnapshot(
    qCasosPremediacion,
    async (querySnapshot) => {
      let casosPremediacion = [];
      for (const docSnapshot of querySnapshot.docs) {
        const casoPremediacion = docSnapshot.data();
        if (casoPremediacion.estado !== "finalizado") {
          if (
            admin.includes(uid) ||
            uid === mediadoresUid[casoPremediacion["mediador_asignado"]]
          )
            casosPremediacion.push({
              ...casoPremediacion,
            });
        }
      }
      setCasosPremediacion(casosPremediacion);
      setIsCargandoCasosPremediacion(false);
    }
  );
  return unSubPremediacion;
}

async function getCasosMediacion(
  uid,
  setCasosMediacion,
  setIsCargandoCasosMediacion
) {
  const qCasosMediacion = query(
    collection(firestore, "mediacion"),
    /* where("estado","==", "ingresado"), */
    orderBy("fecha_inicio_mediacion", "desc")
  );

  const unSubMediacion = onSnapshot(qCasosMediacion, async (querySnapshot) => {
    let casosMediacion = [];
    for (const docSnapshot of querySnapshot.docs) {
      const casoMediacion = docSnapshot.data();
      if (casoMediacion.estado !== "finalizado") {
        if (
          admin.includes(uid) ||
          uid === mediadoresUid[casoMediacion["mediador_asignado"]]
        )
          casosMediacion.push({
            ...casoMediacion,
          });
      }
    }
    setCasosMediacion(casosMediacion);
    setIsCargandoCasosMediacion(false);
  });
  return unSubMediacion;
}

async function getCasosSeguimiento(
  uid,
  setCasosSeguimiento,
  setIsCargandoCasosSeguimiento
) {
  const qCasosSeguimiento = query(
    collection(firestore, "seguimiento"),
    /* where("estado","==", "ingresado"), */
    orderBy("fecha_inicio_seguimiento", "desc")
  );

  const unSubSeguimiento = onSnapshot(
    qCasosSeguimiento,
    async (querySnapshot) => {
      let casosSeguimiento = [];
      for (const docSnapshot of querySnapshot.docs) {
        const casoSeguimiento = docSnapshot.data();
        if (casoSeguimiento.estado !== "finalizado") {
          if (
            admin.includes(uid) ||
            uid === mediadoresUid[casoSeguimiento["mediador_asignado"]]
          )
            casosSeguimiento.push({
              ...casoSeguimiento,
            });
        }
      }
      setCasosSeguimiento(casosSeguimiento);
      setIsCargandoCasosSeguimiento(false);
    }
  );
  return unSubSeguimiento;
}

async function getCasosCierre(uid, setCasosCierre, setIsCargandoCasosCierre) {
  const qCasosCierre = query(
    collection(firestore, "cierre"),
    /* where("estado","==", "ingresado"), */
    orderBy("fecha_inicio_cierre", "desc")
  );

  const unSubCierre = onSnapshot(qCasosCierre, async (querySnapshot) => {
    let casosCierre = [];
    for (const docSnapshot of querySnapshot.docs) {
      const casoCierre = docSnapshot.data();
      if (casoCierre.estado !== "finalizado") {
        if (
          admin.includes(uid) ||
          uid === mediadoresUid[casoCierre["mediador_asignado"]]
        )
          casosCierre.push({
            ...casoCierre,
          });
      }
    }
    setCasosCierre(casosCierre);
    setIsCargandoCasosCierre(false);
  });
  return unSubCierre;
}

async function getUnIngreso(
  docRef,
  setDatosIngreso,
  setIsCargandoDatosIngreso
) {
  const docSnap = await getDoc(docRef);
  let data = docSnap.data();
  setDatosIngreso(data);
  setIsCargandoDatosIngreso(false);
}

async function getAllCasos(setAllCasos, setIsCargandoAllCasos) {
  const querySnapshot = await getDocs(collection(firestore, "historico"));
  let allCasos = [];
  let counter = 0;
  querySnapshot.forEach(async (doc) => {
    const casoHistorico = doc.data();
    const casoRef = doc.ref;
    const caso = {
      ingresoData: {},
      premediacionData: {},
      mediacionData: {},
      seguimientoData: {},
      cierreData: {},
      ruc: "",
      etapa: "",
      estado: "",
      id: casoHistorico.id,
      fecha_ultima_modificacion: casoHistorico.fecha_ultima_modificacion,
      fecha_cierre: "",
      encuestas: {},
      cierre_penal: {},
      fecha_ingreso: casoHistorico.fecha_ingreso,
      fecha_paso_premediacion: "",
      fecha_paso_mediacion: "",
      fecha_inicio_seguimiento: "",
      fecha_inicio_cierre: "",
    };
    if (casoHistorico.estado) {
      if (casoHistorico.estado === "finalizado") {
        caso.estado = "finalizado";
        caso.fecha_cierre = casoHistorico.fecha_cierre;
      }
    } else {
      caso.estado = "vigente";
    }
    if (casoHistorico.encuestas) {
      caso.encuestas = casoHistorico.encuestas;
    }
    if (casoHistorico.cierre_penal) {
      caso.cierre_penal = casoHistorico.cierre_penal;
    }
    if (casoHistorico.ingresoRef) {
      caso.etapa = "Ingreso";
      const ingresoDocSnap = await getDoc(casoHistorico.ingresoRef);
      let ingresoData = ingresoDocSnap.data();
      caso.ingresoData = ingresoData;
      caso.fecha_ultima_modificacion = ingresoData.fecha_ultima_modificacion;
      caso.ruc = ingresoData.ruc;
    }
    if (casoHistorico.premediacionRef) {
      caso.etapa = "Premediación";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      /* caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      const premediacionDocSnap = await getDoc(casoHistorico.premediacionRef);
      let premediacionData = premediacionDocSnap.data();
      caso.premediacionData = premediacionData;
      caso.fecha_ultima_modificacion =
        premediacionData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_paso_premediacion: premediacionData.fecha_paso_premediacion,
      }); */
    }
    if (casoHistorico.mediacionRef) {
      caso.etapa = "Mediación";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      /* caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      const mediacionDocSnap = await getDoc(casoHistorico.mediacionRef);
      let mediacionData = mediacionDocSnap.data();
      caso.mediacionData = mediacionData;
      caso.fecha_ultima_modificacion = mediacionData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_paso_mediacion: mediacionData.fecha_paso_mediacion,
      }); */
    }
    if (casoHistorico.seguimientoRef) {
      caso.etapa = "Seguimiento";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      caso.fecha_inicio_seguimiento = casoHistorico.fecha_inicio_seguimiento;
      /* caso.fecha_inicio_seguimiento = casoHistorico.fecha_inicio_seguimiento;
      const seguimientoDocSnap = await getDoc(casoHistorico.seguimientoRef);
      let seguimientoData = seguimientoDocSnap.data();
      caso.seguimientoData = seguimientoData;
      caso.fecha_ultima_modificacion =
        seguimientoData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_inicio_seguimiento: seguimientoData.fecha_inicio_seguimiento,
      }); */
    }
    if (casoHistorico.cierreRef) {
      caso.etapa = "Cierre";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      caso.fecha_inicio_seguimiento = casoHistorico.fecha_inicio_seguimiento;
      caso.fecha_inicio_cierre = casoHistorico.fecha_inicio_cierre;
      /* caso.fecha_inicio_cierre = casoHistorico.fecha_inicio_cierre;
      const cierreDocSnap = await getDoc(casoHistorico.cierreRef);
      let cierreData = cierreDocSnap.data();
      caso.cierreData = cierreData;
      caso.fecha_ultima_modificacion = cierreData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_inicio_cierre: cierreData.fecha_inicio_cierre,
      }); */
    }
    allCasos.push({
      ...caso,
    });

    counter = counter + 1;
    if (counter === querySnapshot.size) {
      let id_collection = [];
      /* for (let caso_i of allCasos) {
        id_collection.push(caso_i["ruc"]);
      }
      console.log(id_collection); */
      setAllCasos(allCasos);
      setIsCargandoAllCasos(false);
    }
  });
}

async function getAllCasosEstadistica(setAllCasos, setIsCargandoAllCasos) {
  const querySnapshot = await getDocs(collection(firestore, "historico"));
  let allCasos = [];
  let counter = 0;
  querySnapshot.forEach(async (doc) => {
    const casoHistorico = doc.data();
    const casoRef = doc.ref;
    const caso = {
      ingresoData: {},
      premediacionData: {},
      mediacionData: {},
      seguimientoData: {},
      cierreData: {},
      ruc: "",
      etapa: "",
      estado: "",
      id: casoHistorico.id,
      fecha_ultima_modificacion: casoHistorico.fecha_ultima_modificacion,
      fecha_cierre: "",
      encuestas: {},
      cierre_penal: {},
      fecha_ingreso: casoHistorico.fecha_ingreso,
      fecha_paso_premediacion: "",
      fecha_paso_mediacion: "",
      fecha_inicio_seguimiento: "",
      fecha_inicio_cierre: "",
    };
    if (casoHistorico.estado) {
      if (casoHistorico.estado === "finalizado") {
        caso.estado = "finalizado";
        caso.fecha_cierre = casoHistorico.fecha_cierre;
      }
    } else {
      caso.estado = "vigente";
    }
    if (casoHistorico.encuestas) {
      caso.encuestas = casoHistorico.encuestas;
    }
    if (casoHistorico.cierre_penal) {
      caso.cierre_penal = casoHistorico.cierre_penal;
    }
    if (casoHistorico.ingresoRef) {
      caso.etapa = "Ingreso";
      const ingresoDocSnap = await getDoc(casoHistorico.ingresoRef);
      let ingresoData = ingresoDocSnap.data();
      caso.ingresoData = ingresoData;
      caso.fecha_ultima_modificacion = ingresoData.fecha_ultima_modificacion;
      caso.ruc = ingresoData.ruc;
    }
    if (casoHistorico.premediacionRef) {
      caso.etapa = "Premediación";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      const premediacionDocSnap = await getDoc(casoHistorico.premediacionRef);
      let premediacionData = premediacionDocSnap.data();
      caso.premediacionData = premediacionData;
      /* caso.fecha_ultima_modificacion =
        premediacionData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_paso_premediacion: premediacionData.fecha_paso_premediacion,
      }); */
    }
    if (casoHistorico.mediacionRef) {
      caso.etapa = "Mediación";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      const mediacionDocSnap = await getDoc(casoHistorico.mediacionRef);
      let mediacionData = mediacionDocSnap.data();
      caso.mediacionData = mediacionData;
      /* caso.fecha_ultima_modificacion = mediacionData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_paso_mediacion: mediacionData.fecha_paso_mediacion,
      }); */
    }
    if (casoHistorico.seguimientoRef) {
      caso.etapa = "Seguimiento";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      caso.fecha_inicio_seguimiento = casoHistorico.fecha_inicio_seguimiento;

      const seguimientoDocSnap = await getDoc(casoHistorico.seguimientoRef);
      let seguimientoData = seguimientoDocSnap.data();
      caso.seguimientoData = seguimientoData;
      /* caso.fecha_ultima_modificacion =
        seguimientoData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_inicio_seguimiento: seguimientoData.fecha_inicio_seguimiento,
      }); */
    }
    if (casoHistorico.cierreRef) {
      caso.etapa = "Cierre";
      caso.fecha_paso_premediacion = casoHistorico.fecha_paso_premediacion;
      caso.fecha_paso_mediacion = casoHistorico.fecha_paso_mediacion;
      caso.fecha_inicio_seguimiento = casoHistorico.fecha_inicio_seguimiento;
      caso.fecha_inicio_cierre = casoHistorico.fecha_inicio_cierre;

      const cierreDocSnap = await getDoc(casoHistorico.cierreRef);
      let cierreData = cierreDocSnap.data();
      caso.cierreData = cierreData;
      /* caso.fecha_ultima_modificacion = cierreData.fecha_ultima_modificacion;
      updateDoc(casoRef, {
        fecha_inicio_cierre: cierreData.fecha_inicio_cierre,
      }); */
    }
    allCasos.push({
      ...caso,
    });

    counter = counter + 1;
    if (counter === querySnapshot.size) {
      let id_collection = [];
      /* for (let caso_i of allCasos) {
        id_collection.push(caso_i["ruc"]);
      }
      console.log(id_collection); */
      setAllCasos(allCasos);
      setIsCargandoAllCasos(false);
    }
  });
}

async function getUnCaso(caso, setCaso, setIsCargandoCaso) {
  const casoRef = doc(firestore, "historico", caso.id);
  const casoDocSnap = await getDoc(casoRef);
  let casoHistoricoData = casoDocSnap.data();
  const casoData = {
    id: caso.id,
    ruc: caso.ruc,
    etapa: caso.etapa,
    ingresoData: caso.ingresoData,
    encuestas: casoHistoricoData.encuestas,
    cierre_penal: casoHistoricoData.cierre_penal,
    premediacionData: {},
    mediacionData: {},
    seguimientoData: {},
    cierreData: {},
  };

  if (caso.etapa === "Premediación") {
    casoData.premediacionData = (
      await getDoc(casoHistoricoData.premediacionRef)
    ).data();
  }
  if (caso.etapa === "Mediación") {
    casoData.premediacionData = (
      await getDoc(casoHistoricoData.premediacionRef)
    ).data();
    casoData.mediacionData = (
      await getDoc(casoHistoricoData.mediacionRef)
    ).data();
  }
  if (caso.etapa === "Seguimiento") {
    casoData.premediacionData = (
      await getDoc(casoHistoricoData.premediacionRef)
    ).data();
    casoData.mediacionData = (
      await getDoc(casoHistoricoData.mediacionRef)
    ).data();
    casoData.seguimientoData = (
      await getDoc(casoHistoricoData.seguimientoRef)
    ).data();
  }
  if (caso.etapa === "Cierre") {
    casoData.premediacionData = (
      await getDoc(casoHistoricoData.premediacionRef)
    ).data();
    casoData.mediacionData = (
      await getDoc(casoHistoricoData.mediacionRef)
    ).data();
    casoData.seguimientoData = (
      await getDoc(casoHistoricoData.seguimientoRef)
    ).data();
    casoData.cierreData = (await getDoc(casoHistoricoData.cierreRef)).data();
  }
  setCaso(casoData);
  setIsCargandoCaso(false);
}

async function deleteSentitiveData() {
  const historicoSnapshot = await getDocs(collection(firestore, "historico"));
  const ingresoSnapshot = await getDocs(collection(firestore, "ingreso"));
  const premedSnapshot = await getDocs(collection(firestore, "premediacion"));
  const medSnapshot = await getDocs(collection(firestore, "mediacion"));
  const segSnapshot = await getDocs(collection(firestore, "seguimiento"));
  const cierreSnapshot = await getDocs(collection(firestore, "cierre"));

  ingresoSnapshot.forEach(async (ingdoc) => {
    updateDoc(ingdoc.ref, {
      mediador_asignado: "",
      comediador_asignado: "",
      datosDefensor: {},
      datosFiscal: {},
      datosJuez: {},
      datosOtro: {},
      descripcion: "",
      ofensores: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      victimas: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      concurrentes: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      ruc: "22222222-3",
    });
  });
  premedSnapshot.forEach(async (predoc) => {
    updateDoc(predoc.ref, {
      ofensores: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      victimas: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      ruc: "22222222-3",
      mediador_asignado: "",
      comediador_asignado: "",
      actividades: [],
      consentimiento_ofensor: "",
      consentimiento_victima: "",
    });
  });
  medSnapshot.forEach(async (meddoc) => {
    updateDoc(meddoc.ref, {
      ofensores: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      victimas: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      ruc: "22222222-3",
      mediador_asignado: "",
      comediador_asignado: "",
      sesiones: [],
      consentimiento_ofensor: "",
      consentimiento_victima: "",
      documento_seguimiento: "",
    });
  });
  segSnapshot.forEach(async (segdoc) => {
    updateDoc(segdoc.ref, {
      ofensores: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      victimas: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      ruc: "22222222-3",
      mediador_asignado: "",
      comediador_asignado: "",
      acciones_mediador: [],
      consentimiento_ofensor: "",
      consentimiento_victima: "",
      documento_seguimiento: "",
      observaciones_generales_seguimiento: "",
    });
  });
  cierreSnapshot.forEach(async (ciedoc) => {
    updateDoc(ciedoc.ref, {
      ofensores: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      victimas: [
        {
          nombres: "Demo Demo",
          apellidos: "Demo Demo",
          rut: "12345678-9",
          telefonos: [],
        },
      ],
      ruc: "22222222-3",
      mediador_asignado: "",
      comediador_asignado: "",
      adjunto_acta_cumplimiento: "",
    });
  });
  historicoSnapshot.forEach(async (histdoc) => {
    updateDoc(histdoc.ref, {
      ruc: "22222222-3",
    });
  });
}

export {
  casos,
  getCasosIngreso,
  getCasosPremediacion,
  getCasosMediacion,
  getCasosSeguimiento,
  getCasosCierre,
  getUnIngreso,
  getUnCaso,
  getAllCasos,
  getAllCasosEstadistica,
  deleteSentitiveData
};
