import classes from "./CasosMain.module.css";
import CasosTrack from "../components/layout/CasosTrack";
/* import { useState } from "react"; */
/* import casosIngreso from "../components/data/casosDummy"; */
import {
  getCasosIngreso,
  getCasosPremediacion,
  getCasosMediacion,
  getCasosSeguimiento,
  getCasosCierre,
} from "../firebase/firebaseGetData";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { userSignIn } from "../firebase/firebasePostData";

function CasosMain() {
  // STATES CASOS INGRESO
  const [isCargandoDatosIngreso, setIsCargandoCasosIngreso] = useState(true);
  const [casosIngreso, setCasosIngreso] = useState([]);

  // STATES CASOS PREMEDIACIÓN
  const [isCargandoDatosPremediacion, setIsCargandoCasosPremediacion] =
    useState(true);
  const [casosPremediacion, setCasosPremediacion] = useState([]);

  // STATES CASOS MEDIACIÓN
  const [isCargandoDatosMediacion, setIsCargandoCasosMediacion] =
    useState(true);
  const [casosMediacion, setCasosMediacion] = useState([]);

  // STATES CASOS SEGUIMIENTO
  const [isCargandoDatosSeguimiento, setIsCargandoCasosSeguimiento] =
    useState(true);
  const [casosSeguimiento, setCasosSeguimiento] = useState([]);

  // STATES CASOS CIERRE
  const [isCargandoDatosCierre, setIsCargandoCasosCierre] = useState(true);
  const [casosCierre, setCasosCierre] = useState([]);

  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      return navigate("/login");
    } else {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      return;
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user) {
      const unSubIngreso = getCasosIngreso(
        user.uid,
        setCasosIngreso,
        setIsCargandoCasosIngreso
      );
      return async () => await unSubIngreso;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unSubPremediacion = getCasosPremediacion(
        user.uid,
        setCasosPremediacion,
        setIsCargandoCasosPremediacion
      );
      return async () => await unSubPremediacion;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unSubMediacion = getCasosMediacion(
        user.uid,
        setCasosMediacion,
        setIsCargandoCasosMediacion
      );
      return async () => await unSubMediacion;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unSubSeguimiento = getCasosSeguimiento(
        user.uid,
        setCasosSeguimiento,
        setIsCargandoCasosSeguimiento
      );
      return async () => await unSubSeguimiento;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unSubCierre = getCasosCierre(
        user.uid,
        setCasosCierre,
        setIsCargandoCasosCierre
      );
      return async () => await unSubCierre;
    }
  }, [user]);

  return isCargandoDatosIngreso ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div className={classes.CasosMain}>
      <div className={classes.CasosFilterBar}>
        {/* <p>
          Ordenar: <span>Fecha</span>
        </p>
        <p>
          Filtrar: <span>Hurtos + Robos</span>
        </p> */}
      </div>
      <div
        className={[
          classes.CasosPanel /* , isModalActive ? "blur" : "" */,
        ].join(" ")}
      >
        <CasosTrack
          tipoCaso="panelIngreso"
          title="INGRESO"
          casos={casosIngreso}
          user={user}
        ></CasosTrack>
        <CasosTrack
          tipoCaso="panelPremediacion"
          title="PREMEDIACIÓN"
          casos={casosPremediacion}
          user={user}
        ></CasosTrack>
        <CasosTrack
          tipoCaso="panelMediacion"
          title="MEDIACIÓN"
          casos={casosMediacion}
          user={user}
        ></CasosTrack>
        <CasosTrack
          tipoCaso="panelSeguimiento"
          title="SEGUIMIENTO"
          casos={casosSeguimiento}
          user={user}
        ></CasosTrack>
        <CasosTrack
          tipoCaso="panelCierre"
          title="CIERRE DE SEGUIMIENTO"
          casos={casosCierre}
          user={user}
        ></CasosTrack>
      </div>
    </div>
  );
}

export default CasosMain;
