import classes from "./CasosCerradosProv.module.css";
/* import { useState } from "react"; */
/* import casosIngreso from "../components/data/casosDummy"; */
import { getAllCasos } from "../firebase/firebaseGetData";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { userSignIn } from "../firebase/firebasePostData";
import HistoricoRow from "../components/tarjetas/HistoricoRow";
import Pagination from "../helpers/paginacion";
import { delitos, mediadores, derivadores } from "../components/data/opciones";
import moment from "moment";

function CasosCerradosProv() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [isCargandoAllCasos, setIsCargandoAllCasos] = useState(true);
  const [isFiltrando, setIsFiltrando] = useState(true);
  const [isPaginando, setIsPaginando] = useState(true);

  const [allCasos, setAllCasos] = useState([]);
  const [casosFiltrados, setCasosFiltrados] = useState([]);
  const [casosPaginados, setCasosPaginados] = useState([]);

  // Pruebas Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const casosPorPagina = 4;

  // Estados para filtros
  const [estadoFiltro, setEstadoFiltro] = useState("Todos");
  const [estadoChecked, setEstadoChecked] = useState(false);

  const [delitoFiltro, setDelitoFiltro] = useState("Todos");
  const [delitoChecked, setDelitoChecked] = useState(false);

  const [derivadorFiltro, setDerivadorFiltro] = useState("Todos");
  const [derivadorChecked, setDerivadorChecked] = useState(false);

  const [mediadorFiltro, setMediadorFiltro] = useState("Todos");
  const [mediadorChecked, setMediadorChecked] = useState(false);

  const [etapaFiltro, setEtapaFiltro] = useState("Todos");
  const [etapaChecked, setEtapaChecked] = useState(false);

  const [apellidosFiltro, setApellidosFiltro] = useState("Todos");
  const [apellidosChecked, setApellidosChecked] = useState(false);

  const [rutFiltro, setRutFiltro] = useState("Todos");
  const [rutChecked, setRutChecked] = useState(false);

  const [rucFiltro, setRucFiltro] = useState("Todos");
  const [rucChecked, setRucChecked] = useState(false);
  const [nFiltros, setNFiltros] = useState(0);

  const [casosVerde, setcasosVerde] = useState(0);
  const [casosAmarillo, setcasosAmarillo] = useState(0);
  const [casosRojo, setcasosRojo] = useState(0);
  const [casosMorado, setcasosMorado] = useState(0);

  //Filtros disponibles

  const [filtros, setFiltros] = useState({
    estado: estadoFiltro,
    delito: delitoFiltro,
    ruc: rucFiltro,
    derivador_caso: derivadorFiltro,
    mediador_asignado: mediadorFiltro,
    etapa: etapaFiltro,
    fecha_ingreso: "Todos",
    fecha_ultima_modificacion: "Todos",
  });

  const [filtrosParticipante, setFiltrosParticipante] = useState({
    apellidos: apellidosFiltro,
    rut: rutFiltro,
  });

  //Manejo de inicio de sesión
  useEffect(() => {
    console.log("useEffect - inicio sesión");
    if (loading) return;
    if (!user) {
      return navigate("/login");
    } else {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid,
      };
      userSignIn(data);
      return;
    }
  }, [user, loading, navigate]);

  //Obtención de todos los casos
  useEffect(() => {
    console.log("useEffect - obtención de casos");
    if (user) {
      console.log("hay usuario");
      if (isCargandoAllCasos === true) {
        console.log("obteniendo casos");
        getAllCasos(setAllCasos, setIsCargandoAllCasos);
      } else {
        console.log("No cargué casos");
      }
    }
  }, [user, isCargandoAllCasos]);

  //Filtrado de casos
  useEffect(() => {
    let casosFiltrados = allCasos;
    casosFiltrados.sort(
      (a, b) =>
        b.ingresoData.fecha_ingreso.seconds -
        a.ingresoData.fecha_ingreso.seconds
    );
    let nFiltrosTemp = 0;
    //Filtros Generales
    for (const [key, value] of Object.entries(filtros)) {
      if (
        key === "estado" ||
        key === "fecha_ultima_modificacion" ||
        key === "etapa" ||
        key === "ruc"
      ) {
        if (value !== "Todos" && value !== "Todas") {
          nFiltrosTemp = nFiltrosTemp + 1;
          casosFiltrados = casosFiltrados.filter((caso) => caso[key] === value);
        }
      } else if (value !== "Todos" && value !== "Todas") {
        nFiltrosTemp = nFiltrosTemp + 1;
        casosFiltrados = casosFiltrados.filter(
          (caso) => caso.ingresoData[key] === value
        );
      }
    }
    //Filtros para Participantes
    for (const [key, value] of Object.entries(filtrosParticipante)) {
      if (value !== "Todos" && value !== "Todas") {
        nFiltrosTemp = nFiltrosTemp + 1;
        const condicionFiltro = (participante) => participante[key] === value;
        casosFiltrados = casosFiltrados.filter((caso) => {
          return (
            (caso.ingresoData["victimas"] !== ""
              ? caso.ingresoData["victimas"].some(condicionFiltro)
              : false) ||
            (caso.ingresoData["ofensores"] !== ""
              ? caso.ingresoData["ofensores"].some(condicionFiltro)
              : false) ||
            (caso.ingresoData["concurrentes"] !== ""
              ? caso.ingresoData["concurrentes"].some(condicionFiltro)
              : false)
          );
        });
      }
    }

    //Conteo de casos por fecha
    let verde = 0;
    let amarillo = 0;
    let rojo = 0;
    let morado = 0;
    for (const caso of casosFiltrados) {
      const convertirFecha = (fecha) => {
        try {
          const timestamp = fecha.seconds * 1000 + fecha.nanoseconds / 1000000; // Convertir el objeto de fecha a milisegundos
          return moment(timestamp);
        } catch {
          console.log(caso.id);
        }
      };

      let fechaPasoEtapaActual;
      if (caso.etapa === "Ingreso") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_ingreso);
      }
      if (caso.etapa === "Premediación") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_premediacion);
      }
      if (caso.etapa === "Mediación") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_paso_mediacion);
      }
      if (caso.etapa === "Seguimiento") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_seguimiento);
      }
      if (caso.etapa === "Cierre") {
        fechaPasoEtapaActual = convertirFecha(caso.fecha_inicio_cierre);
      }

      const fechaActual = moment();
      const diferenciaDias = fechaActual.diff(fechaPasoEtapaActual, "days");

      if (caso.estado === "finalizado") {
        morado = morado + 1;
      } else if (diferenciaDias <= 10) {
        verde = verde + 1;
      } else if (diferenciaDias > 10 && diferenciaDias <= 20) {
        amarillo = amarillo + 1;
      } else if (diferenciaDias > 20) {
        rojo = rojo + 1;
      }
    }
    setNFiltros(nFiltrosTemp);
    setCasosFiltrados(casosFiltrados);
    setIsFiltrando(false);
    setcasosVerde(verde);
    setcasosAmarillo(amarillo);
    setcasosRojo(rojo);
    setcasosMorado(morado);
  }, [allCasos, filtros, filtrosParticipante]);

  //Paginación de casos
  useEffect(() => {
    let casosPaginadosTemp = casosFiltrados.slice(
      (currentPage - 1) * casosPorPagina,
      currentPage * casosPorPagina
    );
    setCasosPaginados(casosPaginadosTemp);
    setIsPaginando(false);
  }, [casosFiltrados, currentPage]);

  // Función para cambiar de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //Función modificar filtros generales
  function addFiltro(tipo, campo, value) {
    if (tipo === "general") {
      let filtrosCopy = { ...filtros };
      filtrosCopy[campo] = value;
      setFiltros(filtrosCopy);
    }
    if (tipo === "participante") {
      let filtrosCopy = { ...filtrosParticipante };
      filtrosCopy[campo] = value;
      setFiltrosParticipante(filtrosCopy);
    }
  }

  return isCargandoAllCasos || isFiltrando || isPaginando ? (
    <div className={classes.loading}>
      <HashLoader color="#36d7b7" size={80} />
    </div>
  ) : (
    <div>
      <h2>SELECCIÓN DE FILTROS PARA BÚSQUEDA</h2>
      {/* Filtros para búsqueda */}
      <div className={classes.filtros_main}>
        <div className={classes.filtros_primer_bloque}>
          <div>
            <div>Filtros</div>
            <div></div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!rucChecked ? `${classes.gray_text}` : ""}>
                RUC
              </span>
              <input
                type="checkbox"
                name=""
                id=""
                checked={rucChecked}
                onChange={(event) => {
                  setRucChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                disabled={rucChecked !== true}
                value={rucFiltro === "Todos" ? "" : rucFiltro}
                onChange={(event) => {
                  if (rucChecked) {
                    setRucFiltro(event.target.value.toUpperCase());
                  } else {
                    setRucFiltro("Todos");
                  }
                }}
                type="text"
              />
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!rutChecked ? `${classes.gray_text}` : ""}>
                RUT
              </span>

              <input
                type="checkbox"
                checked={rutChecked}
                name=""
                id=""
                onChange={(event) => {
                  setRutChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                disabled={rutChecked !== true}
                value={rutFiltro === "Todos" ? "" : rutFiltro}
                onChange={(event) => {
                  setRutFiltro(event.target.value);
                }}
                type="text"
              />
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!derivadorChecked ? `${classes.gray_text}` : ""}>
                Quien deriva
              </span>

              <input
                type="checkbox"
                checked={derivadorChecked}
                onChange={(event) => {
                  setDerivadorChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={derivadorChecked !== true}
                onChange={(event) => {
                  setDerivadorFiltro(event.target.value);
                }}
                name=""
                id=""
                value={derivadorFiltro}
              >
                {derivadores.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div>
            <div className={classes.titleAndCheck}>
              <span className={!apellidosChecked ? `${classes.gray_text}` : ""}>
                Apellidos
              </span>

              <input
                type="checkbox"
                checked={apellidosChecked}
                onChange={(event) => {
                  setApellidosChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className={classes.actionBox}
                value={apellidosFiltro === "Todos" ? "" : apellidosFiltro}
                disabled={apellidosChecked !== true}
                onChange={(event) => {
                  setApellidosFiltro(event.target.value);
                }}
                type="text"
              />
            </div>
          </div>
        </div>
        <div className={classes.filtros_segundo_bloque}>
          <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!mediadorChecked ? `${classes.gray_text}` : ""}>
                Mediador
              </span>

              <input
                type="checkbox"
                checked={mediadorChecked}
                onChange={(event) => {
                  setMediadorChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={mediadorChecked !== true}
                value={mediadorFiltro}
                onChange={(event) => {
                  setMediadorFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos" key="">
                  Todos
                </option>
                {mediadores.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!etapaChecked ? `${classes.gray_text}` : ""}>
                Etapa
              </span>

              <input
                type="checkbox"
                checked={etapaChecked}
                name=""
                id=""
                onChange={(event) => {
                  setEtapaChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={etapaChecked !== true}
                value={etapaFiltro}
                onChange={(event) => {
                  setEtapaFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos">Todas</option>
                <option value="Ingreso">Ingreso</option>
                <option value="Premediación">Premediación</option>
                <option value="Mediación">Mediación</option>
                <option value="Seguimiento">Seguimiento</option>
                <option value="Cierre">Cierre</option>
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!delitoChecked ? `${classes.gray_text}` : ""}>
                Delito
              </span>

              <input
                type="checkbox"
                checked={delitoChecked}
                name=""
                id=""
                onChange={(event) => {
                  setDelitoChecked(event.target.checked);
                }}
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={delitoChecked !== true}
                value={delitoFiltro}
                onChange={(event) => {
                  setDelitoFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos"> TODOS </option>
                {delitos.map(function (delito, i) {
                  return (
                    <option value={delito} key={i}>
                      {delito}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div className={classes.titleAndCheck}>
              <span className={!estadoChecked ? `${classes.gray_text}` : ""}>
                Estado
              </span>

              <input
                type="checkbox"
                checked={estadoChecked}
                onChange={(event) => {
                  setEstadoChecked(event.target.checked);
                }}
                name=""
                id=""
              />
            </div>
            <div>
              <select
                className={classes.actionBox}
                disabled={estadoChecked !== true}
                value={estadoFiltro}
                onChange={(event) => {
                  setEstadoFiltro(event.target.value);
                }}
                name=""
                id=""
              >
                <option value="Todos" key="">
                  Todos
                </option>
                <option value="vigente">Vigente</option>
                <option value="finalizado">Finalizado</option>
              </select>
            </div>
          </div>
        </div>
        {/* <div className={classes.filtros_tercer_bloque}>
          <div></div>
          <div>
            <div>
              Fecha Ingreso <input type="checkbox" name="" id="" />
            </div>
            <div>
              Entre <input type="date" /> y <input type="date" />
            </div>
          </div>
          <div>
            <div>
              Última modificación <input type="checkbox" name="" id="" />
            </div>
            <div>
              {" "}
              Entre <input type="date" /> y <input type="date" />
            </div>
          </div>
          <div></div>
          <div></div>
        </div> */}
        <div className={classes.filtros_cuarto_bloque}>
          <div>
            Filtros claves{" "}
            <button
              className={classes.btn_aplicar}
              onClick={() => {
                setFiltros({
                  estado: estadoFiltro,
                  delito: delitoFiltro,
                  ruc: rucFiltro,
                  derivador_caso: derivadorFiltro,
                  mediador_asignado: mediadorFiltro,
                  etapa: etapaFiltro,
                  fecha_ingreso: "Todos",
                  fecha_ultima_modificacion: "Todos",
                });

                setFiltrosParticipante({
                  apellidos: apellidosFiltro,
                  rut: rutFiltro,
                });

                setCurrentPage(1);
              }}
            >
              APLICAR
            </button>{" "}
            <button
              onClick={() => {
                setFiltros({
                  estado: "Todos",
                  delito: "Todos",
                  ruc: "Todos",
                  derivador_caso: "Todos",
                  mediador_asignado: "Todos",
                  etapa: "Todos",
                  fecha_ingreso: "Todos",
                  fecha_ultima_modificacion: "Todos",
                });

                setFiltrosParticipante({
                  apellidos: "Todos",
                  rut: "Todos",
                });

                setEstadoChecked(false);
                setDelitoChecked(false);
                setDerivadorChecked(false);
                setMediadorChecked(false);
                setEtapaChecked(false);
                setApellidosChecked(false);
                setRutChecked(false);
                setRucChecked(false);

                setEstadoFiltro("Todos");
                setDelitoFiltro("Todos");
                setDerivadorFiltro("Todos");
                setMediadorFiltro("Todos");
                setEtapaFiltro("Todos");
                setApellidosFiltro("Todos");
                setRutFiltro("Todos");
                setRucFiltro("Todos");

                setCurrentPage(1);
              }}
              className={classes.btn_borrar}
            >
              RESTAURAR
            </button>
          </div>
          <div>
            <div className={classes.btn_filtros_totales}>
              Filtros aplicados: <span>{nFiltros}</span>
            </div>
            <div className={classes.btn_filtros_totales}>
              Casos totales:{" "}
              <span>
                {casosVerde + casosRojo + casosAmarillo + casosMorado}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Nomenclatura de colores */}

      <div className={classes.conteo_casos}>
        <div className={classes.conteo_10}>
          Casos: <span>{casosVerde}</span>
        </div>
        <div className={classes.conteo_20}>
          Casos: <span>{casosAmarillo}</span>
        </div>
        <div className={classes.conteo_30}>
          Casos: <span>{casosRojo}</span>
        </div>
        <div className={classes.conteo_finalizado}>
          Casos: <span>{casosMorado}</span>
        </div>
      </div>
      <div>
        {casosPaginados.map((caso) => {
          return (
            <HistoricoRow
              key={caso.ingresoData.ruc}
              caso={caso}
              setIsCargandoAllCasos={setIsCargandoAllCasos}
            ></HistoricoRow>
          );
        })}
      </div>
      <div>
        <div>
          <span className={classes.cuadro_10_dias}></span>Menos de 10 días en la
          etapa actual
        </div>
        <div>
          <span className={classes.cuadro_20_dias}></span>Entre 10 y 20 días en
          la etapa actual
        </div>
        <div>
          <span className={classes.cuadro_30_dias}> </span>Más de 20 días en la
          etapa actual
        </div>
        <div>
          <span className={classes.cuadro_finalizado}></span>Caso finalizado
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCases={casosFiltrados.length}
        casesPerPage={casosPorPagina}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default CasosCerradosProv;
