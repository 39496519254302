import { useState, useEffect, useRef } from "react";
import { postStorage } from "../../firebase/firebaseStorage";
import classes from "./Ingreso.module.css";
import DatosVictima from "./IngresoDatosVictima";
import "react-toastify/dist/ReactToastify.css";
import {
  delitos,
  comunas,
  devolucion,
  mediadores,
  comediadores,
  derivadores,
} from "../data/opciones";
import save from "../../img/icono_saved.png";

import {
  datosVictima,
  datosOfensor,
  datosConcurrente,
} from "../data/estructuras";
import DatosOfensor from "./IngresoDatosOfensor";
import DatosConcurrente from "./IngresoDatosConcurrente";

import {
  addCaso,
  addPremediacion,
  archivar,
} from "../../firebase/firebasePostData";
import { Timestamp } from "firebase/firestore";

import attach from "../../img/icono_attach.png";
import { Drawer } from "antd";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import axios from "axios";
import fileDownload from "js-file-download";

function FormularioIngreso(props) {
  const [file, setFile] = useState("");
  const [filePartePolicial, setFilePartePolicial] = useState("");
  const [fileNuevo, setFileNuevo] = useState("");

  const spanSubirRef = useRef(null);
  const inputNombreNuevoArchivoRef = useRef(null);
  const [adjuntos_ingreso, setAdjuntos_ingreso] = useState(
    props.caso ? props.caso.adjuntos_ingreso : []
  );
  const historicoRef = props.caso ? props.caso.historicoRef : "";
  const [nombreNuevoArchivo, setNombreNuevoArchivo] = useState("");
  const [guardado, setGuardado] = useState(true);
  const [idCaso] = useState(props.caso ? props.caso.id : "");
  const [ruc, setRuc] = useState(props.caso ? props.caso.ruc : "");
  const [delito, setDelito] = useState(props.caso ? props.caso.delito : "");
  const [otro_delito, setOtro_delito] = useState(
    props.caso ? props.caso.otro_delito : ""
  );
  const [derivador_caso, setDerivador_caso] = useState(
    props.caso ? props.caso.derivador_caso : ""
  );
  const [otro_derivador, setOtro_derivador] = useState(
    props.caso ? props.caso.otro_derivador : ""
  );
  const [fecha_delito, setFecha_delito] = useState(
    props.caso ? props.caso.fecha_delito : ""
  );
  const [fecha_denuncia, setFecha_denuncia] = useState(
    props.caso ? props.caso.fecha_denuncia : ""
  );
  const [caso_vinculado_bool, setCaso_vinculado_bool] = useState(
    props.caso ? props.caso.caso_vinculado_bool : false
  );
  const [rucs_vinculados, setRucs_vinculados] = useState(
    props.caso ? props.caso.rucs_vinculados : ""
  );
  const [descripcion, setDescripcion] = useState(
    props.caso ? props.caso.descripcion : ""
  );
  const [contexto_victimizacion, setContexto_victimizacion] = useState(
    props.caso ? props.caso.contexto_victimizacion : ""
  );
  const [comuna_hecho, setComuna_hecho] = useState(
    props.caso ? props.caso.comuna_hecho : ""
  );
  const [victimas, setVictimas] = useState(
    props.caso ? props.caso.victimas : ""
  );
  const [ofensores, setOfensores] = useState(
    props.caso ? props.caso.ofensores : ""
  );
  const [concurrentes, setConcurrentes] = useState(
    props.caso ? props.caso.concurrentes : ""
  );
  const [acepta_caso, setAcepta_caso] = useState(
    props.caso ? props.caso.acepta_caso : false
  );
  const [causal_devolucion, setCausal_devolucion] = useState(
    props.caso ? props.caso.causal_devolucion : ""
  );
  const [otra_causal_devolucion, setOtra_causal_devolucion] = useState(
    props.caso ? props.caso.causal_devolucion : ""
  );
  const [primera_vez_bool, setPrimera_vez_bool] = useState(
    props.caso ? props.caso.primera_vez_bool : true
  );
  const [mediador_asignado, setMediador_asignado] = useState(
    props.caso ? props.caso.mediador_asignado : ""
  );
  const [asigna_comediador_bool, setAsigna_comediador_bool] = useState(
    props.caso ? props.caso.asigna_comediador_bool : false
  );
  const [comediador_asignado, setComediador_asignado] = useState(
    props.caso ? props.caso.comediador_asignado : ""
  );
  const [fecha_ingreso, setFecha_ingreso] = useState(
    props.caso ? props.caso.fecha_ingreso : ""
  );
  const [datosFiscal, setDatosFiscal] = useState(
    props.caso ? props.caso.datosFiscal : {}
  );
  const [datosDefensor, setDatosDefensor] = useState(
    props.caso ? props.caso.datosDefensor : {}
  );
  const [datosJuez, setDatosJuez] = useState(
    props.caso ? props.caso.datosJuez : {}
  );
  const [datosOtro, setDatosOtro] = useState(
    props.caso ? props.caso.datosOtro : {}
  );

  const [parte_policial, setParte_policial] = useState(
    props.caso ? props.caso.parte_policial : ""
  );

  const [
    mostrar_confirmar_eliminar_incumbente_victima,
    setMostrar_confirmar_eliminar_incumbente_victima,
  ] = useState(false);

  const [
    mostrar_confirmar_eliminar_incumbente_ofensor,
    setMostrar_confirmar_eliminar_incumbente_ofensor,
  ] = useState(false);

  const [
    mostrar_confirmar_eliminar_incumbente_concurrente,
    setMostrar_confirmar_eliminar_incumbente_concurrente,
  ] = useState(false);

  function handlePlusMinus(type, action) {
    switch (type) {
      case "victima":
        if (action === "sumar") {
          let newVictima = JSON.parse(JSON.stringify(datosVictima));
          victimas.length === 0
            ? setVictimas([newVictima])
            : setVictimas([...victimas, newVictima]);
          setGuardado(false);
        } else {
          if (victimas.length > 0) {
            // setVictimas(victimas.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_victima(true);
          }
        }
        break;
      case "ofensor":
        if (action === "sumar") {
          let newOfensor = JSON.parse(JSON.stringify(datosOfensor));
          setOfensores([...ofensores, newOfensor]);
          setGuardado(false);
        } else {
          if (ofensores.length > 0) {
            // setOfensores(ofensores.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_ofensor(true);
          }
        }
        break;
      case "concurrente":
        if (action === "sumar") {
          setConcurrentes([
            ...concurrentes,
            JSON.parse(JSON.stringify(datosConcurrente)),
          ]);
          setGuardado(false);
        } else {
          if (concurrentes.length > 0) {
            // setConcurrentes(concurrentes.slice(0, -1));
            setMostrar_confirmar_eliminar_incumbente_concurrente(true);
          }
        }
        break;
      default:
        break;
    }
  }

  let ingresoData = {
    id: idCaso,
    ruc: ruc,
    fecha_ingreso: fecha_ingreso,
    delito: delito,
    derivador_caso: derivador_caso,
    datosFiscal: datosFiscal,
    datosDefensor: datosDefensor,
    datosJuez: datosJuez,
    datosOtro: datosOtro,
    otro_delito: otro_delito,
    fecha_delito: fecha_delito,
    fecha_denuncia: fecha_denuncia,
    guardado: guardado,
    caso_vinculado_bool: caso_vinculado_bool,
    rucs_vinculados: rucs_vinculados,
    descripcion: descripcion,
    parte_policial: parte_policial,
    adjuntos_ingreso: adjuntos_ingreso,
    /*     url_descripcion: url_descripcion, */
    contexto_victimizacion: contexto_victimizacion,
    comuna_hecho: comuna_hecho,
    victimas: victimas,
    ofensores: ofensores,
    concurrentes: concurrentes,
    acepta_caso: acepta_caso,
    causal_devolucion: causal_devolucion,
    otra_causal_devolucion: otra_causal_devolucion,
    primera_vez_bool: primera_vez_bool,
    mediador_asignado: mediador_asignado,
    asigna_comediador_bool: asigna_comediador_bool,
    comediador_asignado: comediador_asignado,
    estado: "ingresado",
    historicoRef: historicoRef,
  };

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  // STATES Y FUNCIONES DRAWER

  const [visible, setVisible] = useState(false);

  // ------------------------------

  const [mostrar_modal_guardar, setMostrar_modal_guardar] = useState(false);
  const [mostrar_modal_ingresar, setMostrar_modal_ingresar] = useState(false);
  const [mostrar_modal_derivar, setMostrar_modal_derivar] = useState(false);

  const guardarFunciones = async () => {
    setMostrar_modal_guardar(true);
  };

  // STATES Y FUNCIONES VERIFICACIONES

  const [errorGeneral, setErrorGeneral] = useState(false);
  const [errorRuc, setErrorRuc] = useState(false);
  const [errorFechaIngreso, setErrorFechaIngreso] = useState(false);
  const [errorRucVinculado, setErrorRucVinculado] = useState(false);
  const [errorDelitoOtros, setErrorDelitoOtros] = useState(false);
  const [
    datosObligatoriosVerificadosIngreso,
    setDatosObligatoriosVerificadosIngreso,
  ] = useState(false);
  const [
    datosObligatoriosVerificadosDerivar,
    setDatosObligatoriosVerificadosDerivar,
  ] = useState(false);

  const handleRuc = (event) => {
    if (/^\d{10}-[0-9Kk]$/.test(event)) {
      setErrorRuc(false);
      setErrorGeneral(false);
    } else {
      setErrorRuc(true);
      setErrorGeneral(true);
    }
    setRuc(event);
  };

  const handleFechaIngreso = (event) => {
    if (!event.target.value) {
      return;
    }
    const fechaIngresada = new Date(event.target.value + "T00:00");
    const fechaActual = new Date();
    const fechaIngresadaComparacion = new Date(event.target.value);

    if (fechaIngresadaComparacion > fechaActual) {
      setErrorFechaIngreso(true);
      setErrorGeneral(false);
    } else {
      setErrorFechaIngreso(false);
      setErrorGeneral(false);
      let newTimestamp = Timestamp.fromDate(fechaIngresada);
      setFecha_ingreso(newTimestamp);
      setGuardado(false);
    }
  };

  const handleRucVinculado = (event) => {
    // const event = Number(event);

    if (/^\d{10}-[0-9Kk]$/.test(event)) {
      setErrorRucVinculado(false);
      setErrorGeneral(false);
    } else {
      setErrorRucVinculado(true);
      setErrorGeneral(true);
    }

    setRucs_vinculados(event);
  };

  // EFECTO PARA DETECTAR CAMPOS OBLIGATORIOS

  useEffect(() => {
    let ruc_caso_vinculado = "No hay caso vinculado";
    if (caso_vinculado_bool === true && rucs_vinculados !== "") {
      ruc_caso_vinculado = "Existe";
    }
    if (caso_vinculado_bool === true && rucs_vinculados === "") {
      ruc_caso_vinculado = "No se coloca ruc";
    }

    if (
      ruc !== "" &&
      fecha_ingreso !== "" &&
      delito !== "" &&
      comuna_hecho !== "" &&
      fecha_delito !== "" &&
      fecha_denuncia !== "" &&
      (caso_vinculado_bool !== "" || !caso_vinculado_bool !== "") &&
      derivador_caso !== "" &&
      acepta_caso !== "" &&
      mediador_asignado !== "" &&
      primera_vez_bool !== "" &&
      victimas.length > 0 &&
      errorGeneral === false &&
      ruc_caso_vinculado !== "No se coloca ruc" &&
      ofensores.length > 0
    ) {
      setDatosObligatoriosVerificadosIngreso(true);
    } else {
      setDatosObligatoriosVerificadosIngreso(false);
    }
  }, [
    ruc,
    caso_vinculado_bool,
    rucs_vinculados,
    fecha_ingreso,
    delito,
    comuna_hecho,
    fecha_delito,
    fecha_denuncia,
    derivador_caso,
    acepta_caso,
    mediador_asignado,
    victimas,
    ofensores,
    errorGeneral,
    primera_vez_bool,
  ]);

  useEffect(() => {
    if (
      ruc !== "" &&
      fecha_ingreso !== "" &&
      delito !== "" &&
      comuna_hecho !== "" &&
      fecha_delito !== "" &&
      fecha_denuncia !== "" &&
      (caso_vinculado_bool !== "" || !caso_vinculado_bool !== "") &&
      derivador_caso !== "" &&
      causal_devolucion !== ""
    ) {
      setDatosObligatoriosVerificadosDerivar(true);
    } else {
      setDatosObligatoriosVerificadosDerivar(false);
    }
  }, [
    ruc,
    fecha_ingreso,
    delito,
    comuna_hecho,
    fecha_delito,
    fecha_denuncia,
    caso_vinculado_bool,
    derivador_caso,
    causal_devolucion,
  ]);

  const handleChangeDelitoOtros = (event) => {
    const valor = event.target.value;
    if (valor == null || valor.trim() === "") {
      setErrorDelitoOtros(true);
      setErrorGeneral(true);
    } else if (!/^[a-zA-Z\s'.áéíóúÁÉÍÓÚñÑüÜ]+$/u.test(valor)) {
      setErrorDelitoOtros(true);
      setErrorGeneral(true);
    } else {
      setErrorDelitoOtros(false);
      setErrorGeneral(false);
      setGuardado(false);
    }
    setOtro_delito(valor);
    setGuardado(false);
  };

  const notify = () => {
    toast.success("Datos Ingreso guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const paso_a_premediacion = () => {
    toast.success("Caso pasa a Etapa de Premediación de manera exitosa", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const derivado = () => {
    toast.success("Caso fue archivado exitosamente", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const documento_agregado = () => {
    toast.success(
      "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const victima_borrada = () => {
    toast.success("Se eliminan los datos de la última víctima ingresada", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const ofensor_borrado = () => {
    toast.success("Se eliminan los datos del último ofensor ingresado", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const concurrente_borrado = () => {
    toast.success("Se eliminan los datos del último concurrente ingresado", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorGuardado = () => {
    toast.error(
      "Tiene datos en formato incorrecto, corregir antes de guardar",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  function handleDownload(url, filename) {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  }

  useEffect(() => {
    // Este useEffect no tiene ningún código adicional
  }, [file, filePartePolicial]);

  // FUNCIONES Y STATES PARA VERIFICACIÓN DE DATOS

  const [error, setError] = useState(false);
  const [errorDefensor, setErrorDefensor] = useState(false);
  const [errorJuez, setErrorJuez] = useState(false);
  const [errorOtro, setErrorOtro] = useState(false);

  const [errorCorreo, setErrorCorreo] = useState(false);
  const [errorCorreoDefensor, setErrorCorreoDefensor] = useState(false);
  const [errorCorreoJuez, setErrorCorreoJuez] = useState(false);
  const [errorCorreoOtro, setErrorCorreoOtro] = useState(false);

  const [errorTelefonoFiscal, setErrorTelefonoFiscal] = useState(false);
  const [errorTelefonoDefensor, setErrorTelefonoDefensor] = useState(false);
  const [errorTelefonoJuez, setErrorTelefonoJuez] = useState(false);
  const [errorTelefonoOtro, setErrorTelefonoOtro] = useState(false);

  const handleChangeNombres = (event, tipo) => {
    const valor = event.target.value;

    if (valor == null || valor.trim() === "") {
      if (tipo === "Fiscal") {
        setError(true);
      }
      if (tipo === "Defensor") {
        setErrorDefensor(true);
      }
      if (tipo === "Juez") {
        setErrorJuez(true);
      }
      if (tipo === "Otro") {
        setErrorOtro(true);
      }
    } else if (!/^[a-zA-Z\s'.áéíóúÁÉÍÓÚñÑüÜ]+$/u.test(valor)) {
      if (tipo === "Fiscal") {
        setError(true);
        setErrorGeneral(true);
      }
      if (tipo === "Defensor") {
        setErrorDefensor(true);
        setErrorGeneral(true);
      }
      if (tipo === "Juez") {
        setErrorJuez(true);
        setErrorGeneral(true);
      }
      if (tipo === "Otro") {
        setErrorOtro(true);
        setErrorGeneral(true);
      }
    } else {
      if (tipo === "Defensor") {
        setErrorDefensor(false);
        setErrorGeneral(false);
      }
      if (tipo === "Fiscal") {
        setError(false);
        setErrorGeneral(false);
      }
      if (tipo === "Juez") {
        setErrorJuez(false);
        setErrorGeneral(false);
      }
      if (tipo === "Otro") {
        setErrorOtro(false);
        setErrorGeneral(false);
      }
    }

    setGuardado(false);
  };

  function validarCorreo(correo, tipo) {
    // if (/^([0-9]{7}|[0-9]{8})(-)?[0-9kK]$/.test(rut)) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(correo)) {
      if (tipo === "Fiscal") {
        setErrorGeneral(false);
        setErrorCorreo(false);
        return false;
      }
      if (tipo === "Defensor") {
        setErrorGeneral(false);
        setErrorCorreoDefensor(false);
        return false;
      }
      if (tipo === "Juez") {
        setErrorGeneral(false);
        setErrorCorreoJuez(false);
        return false;
      }
      if (tipo === "Otro") {
        setErrorGeneral(false);
        setErrorCorreoOtro(false);
        return false;
      }
    } else {
      if (tipo === "Fiscal") {
        setErrorGeneral(true);
        setErrorCorreo(true);
        return true;
      }
      if (tipo === "Defensor") {
        setErrorGeneral(true);
        setErrorCorreoDefensor(true);
        return true;
      }
      if (tipo === "Juez") {
        setErrorGeneral(true);
        setErrorCorreoJuez(true);
        return true;
      }
      if (tipo === "Otro") {
        setErrorGeneral(true);
        setErrorCorreoOtro(true);
        return true;
      }
    }
  }

  function validarTelefono(telefono, tipo) {
    // if (/^([0-9]{7}|[0-9]{8})(-)?[0-9kK]$/.test(rut)) {
    if (/^(\+?56)?\s*\d{8,9}$/.test(Number(telefono))) {
      if (tipo === "Fiscal") {
        setErrorGeneral(false);
        setErrorTelefonoFiscal(false);
        return false;
      }
      if (tipo === "Defensor") {
        setErrorGeneral(false);
        setErrorTelefonoDefensor(false);
        return false;
      }
      if (tipo === "Juez") {
        setErrorGeneral(false);
        setErrorTelefonoJuez(false);
        return false;
      }
      if (tipo === "Otro") {
        setErrorGeneral(false);
        setErrorTelefonoOtro(false);
        return false;
      }
    } else {
      if (tipo === "Fiscal") {
        setErrorGeneral(true);
        setErrorTelefonoFiscal(true);
        return true;
      }
      if (tipo === "Defensor") {
        setErrorGeneral(true);
        setErrorTelefonoDefensor(true);
        return true;
      }
      if (tipo === "Juez") {
        setErrorGeneral(true);
        setErrorTelefonoJuez(true);
        return true;
      }
      if (tipo === "Otro") {
        setErrorGeneral(true);
        setErrorTelefonoOtro(true);
        return true;
      }
    }
  }

  return (
    /* Container base, oculta todo */
    <div className={classes.mainContainer}>
      {errorGeneral ? (
        <div className={classes.save} onClick={errorGuardado}>
          <img className={classes.saveIcon_desactivado} src={save} alt="" />
        </div>
      ) : guardado === false ? (
        <div className={classes.save} onClick={guardarFunciones}>
          <img className={classes.saveIcon} src={save} alt="" />
        </div>
      ) : (
        <div className={classes.save}>
          <img className={classes.saveIcon_desactivado} src={save} alt="" />
        </div>
      )}

      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <img className={classes.attachIcon} src={attach} alt="" />
      </div>

      <div className={classes.encabezado}>INGRESO</div>
      <div className={[classes.container, classes.border1].join(" ")}>
        {/* MODAL DE CIERRE DE FORMULARIO */}
        {props.modal_cierre_visible === true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>CERRAR FORMULARIO</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de cerrar el formulario, recuerda que cualquier
                cambio que no hayas guardado se perderá. Por favor, asegúrate de
                haber guardado tus cambios.
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  props.setModal_cierre_visible(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Seguir editando
              </button>
              <button
                onClick={async () => {
                  if (file !== "") {
                    console.log(file);
                    let url;
                    await postStorage("ingreso/" + file.name, file).then(
                      (archivoRutaDescarga) => {
                        url = archivoRutaDescarga;
                        ingresoData["parte_policial"] = url;

                        setParte_policial(url);
                      }
                    );
                  }
                  if (filePartePolicial !== "") {
                    console.log(filePartePolicial);
                    let url;
                    await postStorage(
                      "ingreso/" + filePartePolicial.name,
                      filePartePolicial
                    ).then((archivoRutaDescarga) => {
                      url = archivoRutaDescarga;
                      ingresoData["parte_policial"] = url;

                      setParte_policial(url);
                    });
                  }
                  addCaso(ingresoData, notify ,notify, errorGuardado);
                  setGuardado(true);
                  props.closeModal();
                  //notify();
                }}
                className={classes.btn_guardar_en_form}
              >
                Guardar y salir
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  props.closeModal();
                }}
              >
                Salir sin guardar
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/* ***** MODALES DE CONFIRMACIÓN DE ELIMINACIÓN ***** */}

        {mostrar_confirmar_eliminar_incumbente_concurrente===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>
              Eliminar concurrente
            </div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar al último concurrente que agregaste,
                esto borrará de forma permanente todos los datos ingresados del
                mismo. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setConcurrentes(concurrentes.slice(0, -1));
                  setGuardado(false);
                  concurrente_borrado();
                  setMostrar_confirmar_eliminar_incumbente_concurrente(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar concurrente
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_concurrente(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_confirmar_eliminar_incumbente_ofensor===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>Eliminar ofensor</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar al último ofensor que agregaste, esto
                borrará de forma permanente todos los datos ingresados del
                mismo. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setOfensores(ofensores.slice(0, -1));
                  setGuardado(false);
                  ofensor_borrado();
                  setMostrar_confirmar_eliminar_incumbente_ofensor(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar ofensor
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_ofensor(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_confirmar_eliminar_incumbente_victima===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>Eliminar víctima</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de eliminar a la última víctima que agregaste,
                esto borrará de forma permanente todos los datos ingresados de
                la misma. ¿Quieres continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  setVictimas(victimas.slice(0, -1));
                  setGuardado(false);
                  victima_borrada();
                  setMostrar_confirmar_eliminar_incumbente_victima(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Borrar víctima
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_confirmar_eliminar_incumbente_victima(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/* ***** MODAL DE GUARDADO ***** */}
        {mostrar_modal_guardar===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>GUARDAR DATOS</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de guardar los datos del formulario, ¿como quieres
                continuar?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              {idCaso !== "" ? (
                <button
                  onClick={async () => {
                    if (file !== "") {
                      console.log(file);
                      let url;
                      await postStorage("ingreso/" + file.name, file).then(
                        (archivoRutaDescarga) => {
                          url = archivoRutaDescarga;
                          ingresoData["parte_policial"] = url;

                          setParte_policial(url);
                        }
                      );
                    }
                    if (filePartePolicial !== "") {
                      console.log(filePartePolicial);
                      let url;
                      await postStorage(
                        "ingreso/" + filePartePolicial.name,
                        filePartePolicial
                      ).then((archivoRutaDescarga) => {
                        url = archivoRutaDescarga;
                        ingresoData["parte_policial"] = url;

                        setParte_policial(url);
                      });
                    }
                    addCaso(ingresoData, notify ,notify, errorGuardado);
                    setGuardado(true);
                    setMostrar_modal_guardar(false);
                    //notify();
                  }}
                  className={classes.btn_seguir_en_form}
                >
                  Guardar y seguir
                </button>
              ) : (
                <button
                  onClick={async () => {
                    setMostrar_modal_guardar(false);
                  }}
                  className={classes.btn_seguir_en_form}
                >
                  Seguir sin guardar
                </button>
              )}

              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  if (file !== "") {
                    console.log(file);
                    let url;
                    await postStorage("ingreso/" + file.name, file).then(
                      (archivoRutaDescarga) => {
                        url = archivoRutaDescarga;
                        ingresoData["parte_policial"] = url;

                        setParte_policial(url);
                      }
                    );
                  }
                  addCaso(ingresoData, notify ,notify, errorGuardado);
                  props.closeModal();
                }}
              >
                {idCaso !== "" ? "Guardar y salir" : "Añadir caso a Ingreso"}
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_modal_ingresar===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>INGRESAR CASO</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de de ingresar el caso al sistema, ¿tienes
                seguridad de proceder?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  addCaso(ingresoData, notify ,notify, errorGuardado);
                  addPremediacion(ingresoData, paso_a_premediacion,errorGuardado);
                  paso_a_premediacion();
                  props.closeModal();
                }}
                className={classes.btn_seguir_en_form}
              >
                Ingresar el caso
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_modal_ingresar(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {mostrar_modal_derivar===true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>DERIVAR CASO</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de rechazar el ingreso del caso para derivarlo a
                Fiscalía, ¿tienes seguridad de proceder?
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  addCaso(ingresoData, notify ,notify, errorGuardado).then((data) => {
                    archivar(data, "ingreso");
                  });
                  setMostrar_modal_derivar(false);
                  derivado();
                  props.closeModal();
                }}
                className={classes.btn_seguir_en_form}
              >
                Derivar a Fiscalía
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  setMostrar_modal_derivar(false);
                }}
              >
                Seguir editando
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <p>
            <i>Datos Obligatorios (*)</i>
          </p>
        </div>
        <div className={classes.dosPartes}>
          <div>
            <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
              <p>Número de RUC*</p>
              <span>
                {errorRuc ? (
                  <p className={classes.mensaje_error_datos}>RUC inválido</p>
                ) : (
                  ""
                )}
              </span>
            </div>
            <input
              className={`${classes.actionBox} ${classes.input_cursiva} ${
                errorRuc ? classes.error : ""
              }`}
              type="text"
              name=""
              placeholder="Ejemplo: 2300522885-9"
              id="numero_ruc"
              onChange={(event) => {
                let rucIngresado = event.target.value.toUpperCase();
                handleRuc(rucIngresado);
                setRuc(rucIngresado);
                setGuardado(false);
              }}
              defaultValue={ruc}
            />
          </div>
          <div>
            <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
              <p>Fecha de ingreso*</p>
              <span>
                {errorFechaIngreso ? (
                  <p className={classes.mensaje_error_datos}>Fecha Inválida</p>
                ) : (
                  ""
                )}
              </span>{" "}
            </div>
            <input
              className={`${classes.actionBox} ${
                errorFechaIngreso ? classes.error : ""
              }`}
              type="date"
              name=""
              id="fecha_ingreso_caso"
              onChange={handleFechaIngreso}
              defaultValue={handleDate(fecha_ingreso)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className={[classes.container, classes.border2].join(" ")}>
        <Drawer
          open={visible}
          title="Archivos Adjuntos Ingreso"
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className={classes.div_principal_drawer}>
            {parte_policial!=="" ? (
              <div>
                <p>Documento Parte Policial</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      parte_policial,
                      `Parte_Policial_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              <div>
                {" "}
                <p className={classes.subtitulo}>Adjuntar Parte Policial</p>
                <label htmlFor="file-input1" className={classes.fileInputLabel}>
                  Subir archivo
                </label>
                <input
                  type="file"
                  id="file-input1"
                  className={classes.fileInput}
                  onChange={(e) => {
                    e.target.nextElementSibling.innerText =
                      e.target.files[0].name;
                    setFilePartePolicial(e.target.files[0]);
                    setGuardado(false);
                  }}
                />
                <span className={classes.fileName}>
                  {parte_policial !== "" ? "¿Reemplazar archivo?" : ""}
                </span>
              </div>
            )}
            <hr />
            <div className={[`${classes.nuevos_documentos}`]}>
              {adjuntos_ingreso!==undefined ? (
                <div>
                  {adjuntos_ingreso.map((documento, index) => (
                    <div key={index}>
                      <p>{documento.nombre}</p>
                      <button
                        className={classes.btn_descarga_drawer}
                        onClick={() => {
                          handleDownload(
                            documento.archivo,
                            `${documento.nombre}.pdf`
                          );
                        }}
                      >
                        Descargar
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.div_subir_nuevos_documentos}>
              <hr />

              <div className={classes.columna_subir_nuevo}>
                <p className={classes.subtitulo}>Subir Nuevo Documento</p>
                <label htmlFor="file-nuevo" className={classes.fileInputLabel}>
                  Seleccionar
                </label>
                <input
                  className={classes.fileInput}
                  type="file"
                  id="file-nuevo"
                  onChange={(e) => {
                    e.target.nextElementSibling.innerText =
                      e.target.files[0].name;
                    setFileNuevo(e.target.files[0]);
                  }}
                />
                <span
                  className={classes.fileName}
                  id="span_subir"
                  ref={spanSubirRef}
                ></span>
                <input
                  type="text"
                  placeholder="Ingrese nombre documento"
                  value={nombreNuevoArchivo}
                  ref={inputNombreNuevoArchivoRef}
                  onChange={(e) => setNombreNuevoArchivo(e.target.value)}
                />
                <button
                  className={classes.subir_nuevo_btn}
                  onClick={async () => {
                    if (fileNuevo !== "") {
                      console.log(fileNuevo);
                      let urlNuevo;
                      await postStorage(
                        "ingreso/" + fileNuevo.name,
                        fileNuevo
                      ).then((archivoRutaDescarga) => {
                        urlNuevo = archivoRutaDescarga;

                        setAdjuntos_ingreso([
                          ...adjuntos_ingreso,
                          { nombre: nombreNuevoArchivo, archivo: urlNuevo },
                        ]);
                      });
                      addCaso(ingresoData, notify ,notify, errorGuardado);
                      setGuardado(false);
                      documento_agregado();
                    }
                  }}
                >
                  Subir documento
                </button>
              </div>
            </div>
          </div>
        </Drawer>
        <p className={classes.titulo}>Datos de la causa</p>
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.subtitulo}>Delito*</p>
            <select
              className={classes.actionBox}
              name=""
              id="tipo_delito"
              defaultValue={delito ? delito : " "}
              onChange={(event) => {
                setDelito(event.target.value);
                setGuardado(false);
              }}
            >
              <option disabled value=" ">
                {" "}
                -- Seleccione --{" "}
              </option>
              {delitos.map(function (delito, i) {
                return (
                  <option value={delito} key={i}>
                    {delito}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <p className={classes.subtitulo}>Comuna del hecho*</p>
            <select
              className={classes.actionBox}
              name=""
              id="comuna_delito"
              defaultValue={comuna_hecho ? comuna_hecho : " "}
              onChange={(event) => {
                setComuna_hecho(event.target.value);
                setGuardado(false);
              }}
            >
              <option disabled value=" ">
                {" "}
                -- Seleccione --{" "}
              </option>
              {comunas.map(function (comuna, i) {
                return (
                  <option value={comuna} key={i}>
                    {comuna}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* *************** Renderizado para cuando delito es "OTROS" *************** */}
        {delito==="OTROS" ? (
          <div className={classes.div_ingreso}>
            <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
              <p>Especificar delito* </p>{" "}
            </div>
            <div>
              {errorDelitoOtros ? (
                <div className={classes.subtitulo}>
                  <p className={classes.mensaje_error_datos}>
                    Formato inválido
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <input
              className={`${classes.actionBox} ${
                errorDelitoOtros ? classes.error : ""
              }`}
              type="text"
              name=""
              id="DeliterrorDelitoOtros_victima"
              // onBlur={() => setNombres(nombres)}
              onChange={handleChangeDelitoOtros}
              value={otro_delito}
            />
          </div>
        ) : (
          ""
        )}
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.subtitulo}>Fecha delito*</p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id="fecha_delito"
              onBlur={(event) => {
                if (!event.target.value) {
                  return;
                }
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_delito(newTimestamp);
                setGuardado(false);
              }}
              defaultValue={handleDate(fecha_delito)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
          <div>
            <p className={classes.subtitulo}>Fecha de la denuncia*</p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id="fecha_denuncia"
              onBlur={(event) => {
                if (!event.target.value) {
                  return;
                }
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_denuncia(newTimestamp);
                setGuardado(false);
              }}
              defaultValue={handleDate(fecha_denuncia)
                .split("-")
                .reverse()
                .join("-")}
            />
          </div>
        </div>
        <div>
          <p className={classes.subtitulo}>
            Vinculación con otro caso derivado*
          </p>
          <div className={classes.selectOne}>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={caso_vinculado_bool}
                onChange={() => {
                  setCaso_vinculado_bool(true);
                  setGuardado(false);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={!caso_vinculado_bool}
                onChange={() => {
                  setCaso_vinculado_bool(false);
                  setRucs_vinculados("");
                  setGuardado(false);
                }}
              />
              <span>No</span>
            </div>
          </div>
        </div>
        {caso_vinculado_bool ? (
          <>
            {/* TODO: Lógica para crear como un array y agregar más opciones */}
            <div className={classes.subtitulo}>Número de RUC vinculado</div>
            {errorRucVinculado ? (
              <p className={classes.mensaje_error_datos}>RUC inválido</p>
            ) : (
              ""
            )}
            <input
              className={`${classes.actionBox} ${classes.input_cursiva} ${
                errorRucVinculado ? classes.error : ""
              }`}
              type="text"
              name=""
              placeholder="Ejemplo: 2300522885-9"
              id=""
              onChange={(event) => {
                const rucIngresado = event.target.value;
                handleRucVinculado(rucIngresado);

                setGuardado(false);
              }}
              defaultValue={rucs_vinculados}
            />
          </>
        ) : (
          ""
        )}
        <div className={classes.dosPartes}>
          <div>
            {" "}
            <p className={classes.subtitulo}>Quien deriva el caso*</p>
            <select
              name=""
              id="derivador_caso"
              className={classes.actionBox}
              value={derivador_caso}
              onChange={(event) => {
                setDerivador_caso(event.target.value);
                setGuardado(false);
              }}
            >
              <option disabled value="">
                - Seleccione derivador -
              </option>
              {derivadores.map(function (derivador, i) {
                return (
                  <option value={derivador} key={i}>
                    {derivador}
                  </option>
                );
              })}
              {/* <option value="fiscalia">Fiscalía</option>
              <option value="fiscal_a_cargo">Fiscal a cargo del caso</option>
              <option value="defensor">Defensor</option>
              <option value="juez">Juez</option>
              <option value="otro" key="">
                Otro
              </option> */}
            </select>
          </div>

          {derivador_caso === "Otro" ? (
            <div>
              <p className={classes.subtitulo}>Especificar "Otro"</p>
              <input
                className={classes.actionBox}
                type="text"
                defaultValue={otro_derivador ? otro_derivador : ""}
                onBlur={(event) => {
                  setOtro_derivador(event.target.value);
                  setGuardado(false);
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.div_conjunto_derivadores}>
          {derivador_caso === "Fiscal a cargo" ||
          derivador_caso === "Defensor" ||
          derivador_caso === "Juez" ? (
            <div>
              <p className={classes.subtitulo}>Datos Fiscal a cargo del caso</p>
              <div className={classes.datos_derivador}>
                <div>
                  <p className={classes.subtitulo}>Nombre</p>
                  {error ? (
                    <p className={classes.mensaje_error_datos}>
                      Nombre inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      error ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: María Salas"
                    onChange={(event) => {
                      handleChangeNombres(event, "Fiscal");
                      let oldState = { ...datosFiscal };
                      oldState["nombre"] = event.target.value;
                      setDatosFiscal(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosFiscal["nombre"] ? datosFiscal["nombre"] : ""
                    }
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Teléfono</p>
                  {errorTelefonoFiscal ? (
                    <p className={classes.mensaje_error_datos}>
                      Teléfono inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorTelefonoFiscal ? classes.error : ""
                    }`}
                    placeholder="Ejemplo: +56987761166"
                    type="text"
                    onChange={(event) => {
                      validarTelefono(event.target.value, "Fiscal");
                      let oldState = { ...datosFiscal };
                      oldState["telefono"] = event.target.value;
                      setDatosFiscal(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosFiscal.telefono ? datosFiscal.telefono : ""
                    }
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Email</p>
                  {errorCorreo ? (
                    <p className={classes.mensaje_error_datos}>
                      Email inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorCorreo ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: correo@email.com"
                    onChange={(event) => {
                      validarCorreo(event.target.value, "Fiscal");
                      let oldState = { ...datosFiscal };
                      oldState["mail"] = event.target.value;
                      setDatosFiscal(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosFiscal.mail ? datosFiscal.mail : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Nombre tribunal</p>
                  <input
                    className={`${classes.input_cursiva}`}
                    type="text"
                    placeholder="Ejemplo: Tribunal de Familia"
                    onChange={(event) => {
                      let oldState = { ...datosFiscal };
                      oldState["tribunal"] = event.target.value;
                      setDatosFiscal(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosFiscal.tribunal ? datosFiscal.tribunal : ""
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {derivador_caso === "Fiscal a cargo" ||
          derivador_caso === "Defensor" ||
          derivador_caso === "Juez" ? (
            <div className={classes.div_datos_derivador}>
              <p className={classes.subtitulo}>Datos Defensor</p>
              <div className={classes.datos_derivador}>
                <div>
                  <p className={classes.subtitulo}>Nombre</p>
                  {errorDefensor ? (
                    <p className={classes.mensaje_error_datos}>
                      Nombre inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorDefensor ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: María Salas"
                    onChange={(event) => {
                      handleChangeNombres(event, "Defensor");
                      let oldState = { ...datosDefensor };
                      oldState["nombre"] = event.target.value;
                      setDatosDefensor(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosDefensor.nombre ? datosDefensor.nombre : ""
                    }
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Teléfono</p>
                  {errorTelefonoDefensor ? (
                    <p className={classes.mensaje_error_datos}>
                      Teléfono inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorTelefonoDefensor ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: +56987761166"
                    onChange={(event) => {
                      validarTelefono(event.target.value, "Defensor");
                      let oldState = { ...datosDefensor };
                      oldState["telefono"] = event.target.value;
                      setDatosDefensor(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosDefensor.telefono ? datosDefensor.telefono : ""
                    }
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Email</p>
                  {errorCorreoDefensor ? (
                    <p className={classes.mensaje_error_datos}>
                      Email inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorCorreoDefensor ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: correo@email.com"
                    onChange={(event) => {
                      validarCorreo(event.target.value, "Defensor");
                      let oldState = { ...datosDefensor };
                      oldState["mail"] = event.target.value;
                      setDatosDefensor(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosDefensor.mail ? datosDefensor.mail : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Nombre tribunal</p>
                  <input
                    className={`${classes.input_cursiva}`}
                    type="text"
                    placeholder="Ejemplo: Tribunal de Familia"
                    onChange={(event) => {
                      let oldState = { ...datosDefensor };
                      oldState["tribunal"] = event.target.value;
                      setDatosDefensor(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={
                      datosDefensor.tribunal ? datosDefensor.tribunal : ""
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {derivador_caso === "Juez" ? (
            <div className={classes.div_datos_derivador}>
              <p className={classes.subtitulo}>Datos Juez</p>
              <div className={classes.datos_derivador}>
                <div>
                  <p className={classes.subtitulo}>Nombre</p>
                  {errorJuez ? (
                    <p className={classes.mensaje_error_datos}>
                      Nombre inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorJuez ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: María Salas"
                    onChange={(event) => {
                      handleChangeNombres(event, "Juez");
                      let oldState = { ...datosJuez };
                      oldState["nombre"] = event.target.value;
                      setDatosJuez(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosJuez.nombre ? datosJuez.nombre : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Teléfono</p>
                  {errorTelefonoJuez ? (
                    <p className={classes.mensaje_error_datos}>
                      Teléfono inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    type="text"
                    placeholder="Ejemplo: +56987761166"
                    className={`${classes.input_cursiva} ${
                      errorTelefonoJuez ? classes.error : ""
                    }`}
                    onChange={(event) => {
                      validarTelefono(event.target.value, "Juez");
                      let oldState = { ...datosJuez };
                      oldState["telefono"] = event.target.value;
                      setDatosJuez(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosJuez.telefono ? datosJuez.telefono : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Email</p>
                  {errorCorreoJuez ? (
                    <p className={classes.mensaje_error_datos}>
                      Email inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorCorreoJuez ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: correo@email.com"
                    onChange={(event) => {
                      validarCorreo(event.target.value, "Juez");
                      let oldState = { ...datosJuez };
                      oldState["mail"] = event.target.value;
                      setDatosJuez(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosJuez.mail ? datosJuez.mail : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Nombre tribunal</p>
                  <input
                    className={`${classes.input_cursiva}`}
                    type="text"
                    placeholder="Ejemplo: Tribunal de Familia"
                    onChange={(event) => {
                      let oldState = { ...datosJuez };
                      oldState["tribunal"] = event.target.value;
                      setDatosJuez(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosJuez.tribunal ? datosJuez.tribunal : ""}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {derivador_caso === "Otro" ? (
            <div className={classes.div_datos_derivador}>
              <p className={classes.subtitulo}>Datos de Otro</p>
              <div className={classes.datos_derivador}>
                <div>
                  <p className={classes.subtitulo}>Nombre</p>
                  {errorOtro ? (
                    <p className={classes.mensaje_error_datos}>
                      Nombre inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorOtro ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: María Salas"
                    onChange={(event) => {
                      handleChangeNombres(event, "Otro");
                      let oldState = { ...datosOtro };
                      oldState["nombre"] = event.target.value;
                      setDatosOtro(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosOtro ? datosOtro.nombre : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Teléfono</p>
                  {errorTelefonoOtro ? (
                    <p className={classes.mensaje_error_datos}>
                      Teléfono inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorTelefonoOtro ? classes.error : ""
                    }`}
                    placeholder="Ejemplo: +56987761166"
                    type="text"
                    onChange={(event) => {
                      validarTelefono(event.target.value, "Otro");
                      let oldState = { ...datosOtro };
                      oldState["telefono"] = event.target.value;
                      setDatosOtro(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosOtro ? datosOtro.telefono : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Email</p>
                  {errorCorreoOtro ? (
                    <p className={classes.mensaje_error_datos}>
                      Email inválido
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    className={`${classes.input_cursiva} ${
                      errorCorreoOtro ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: correo@email.com"
                    onChange={(event) => {
                      validarCorreo(event.target.value, "Otro");
                      let oldState = { ...datosOtro };
                      oldState["mail"] = event.target.value;
                      setDatosOtro(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosOtro ? datosOtro.mail : ""}
                  />
                </div>
                <div>
                  <p className={classes.subtitulo}>Nombre tribunal</p>
                  <input
                    type="text"
                    placeholder="Ejemplo: Tribunal de Familia"
                    className={`${classes.input_cursiva}`}
                    onChange={(event) => {
                      let oldState = { ...datosOtro };
                      oldState["tribunal"] = event.target.value;
                      setDatosOtro(oldState);
                      setGuardado(false);
                    }}
                    defaultValue={datosOtro ? datosOtro.tribunal : ""}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <p className={classes.subtitulo}>
            Descripción de los hechos (texto obtenido del parte policial o lo
            que haya)
          </p>
          <textarea
            className={`${classes.textArea} ${classes.textarea_placeholder}`}
            name=""
            placeholder="Ingrese aquí texto con descripción de los hechos"
            id=""
            cols="20"
            rows="10"
            onBlur={(event) => {
              setDescripcion(event.target.value);
              setGuardado(false);
            }}
            defaultValue={descripcion ? descripcion : ""}
          ></textarea>
          {/* <div>
            <input type="file" name="" id="" />
          </div> */}
        </div>
        <div>
          {" "}
          <p className={classes.subtitulo}>Adjuntar Parte Policial</p>
          <label htmlFor="file-input" className={classes.fileInputLabel}>
            Subir archivo
          </label>
          <input
            type="file"
            id="file-input"
            className={classes.fileInput}
            onChange={(e) => {
              e.target.nextElementSibling.innerText = e.target.files[0].name;
              setFile(e.target.files[0]);
              setGuardado(false);
            }}
          />
          <span className={classes.fileName}>
            {parte_policial !== "" ? "¿Reemplazar archivo?" : ""}
          </span>
        </div>

        <div>
          <p className={classes.subtitulo}>
            Contexto victimización. Campo abierto para dar cuenta de alguna
            información relevante(por ejemplo: agresión entre vecinos; agresión
            por discriminación, etc.)
          </p>
          <textarea
            className={`${classes.textArea} ${classes.textarea_placeholder}`}
            name=""
            placeholder="Ingrese aquí texto con el contexto de victimización"
            id=""
            cols="20"
            rows="10"
            onBlur={(event) => {
              setContexto_victimizacion(event.target.value);
              setGuardado(false);
            }}
            defaultValue={contexto_victimizacion ? contexto_victimizacion : ""}
          ></textarea>
        </div>
      </div>
      <hr />
      <div className={[classes.container, classes.border3].join(" ")}>
        <div className={classes.titulo}>Datos de las partes</div>
        <div className={classes.subtitulo}>Agregar persona involucrada</div>
        <div className={classes.gridPartes}>
          <div>Víctimas</div>
          <div
            className={classes.menos}
            onClick={() => {
              handlePlusMinus("victima", "menos");
            }}
          >
            -
          </div>
          <div className={classes.cantidad}>{victimas.length}</div>
          <div
            className={classes.mas}
            onClick={() => {
              handlePlusMinus("victima", "sumar");
            }}
          >
            +
          </div>

          <div>Ofensores</div>
          <div
            className={classes.menos}
            onClick={() => {
              handlePlusMinus("ofensor", "menos");
            }}
          >
            -
          </div>
          <div className={classes.cantidad}>{ofensores.length}</div>
          <div
            className={classes.mas}
            onClick={() => {
              handlePlusMinus("ofensor", "sumar");
            }}
          >
            +
          </div>

          <div>Concurrentes</div>
          <div
            className={classes.menos}
            onClick={() => {
              handlePlusMinus("concurrente", "menos");
            }}
          >
            -
          </div>
          <div className={classes.cantidad}>{concurrentes.length}</div>
          <div
            className={classes.mas}
            onClick={() => {
              handlePlusMinus("concurrente", "sumar");
            }}
          >
            +
          </div>
        </div>

        {victimas.length > 0
          ? victimas.map((number, index) => {
              return (
                <div key={"victima_" + index}>
                  <DatosVictima
                    setGuardado={setGuardado}
                    data={victimas[index]}
                    fullArray={victimas}
                    index={index}
                    setVictimas={setVictimas}
                  ></DatosVictima>
                </div>
              );
            })
          : ""}
        {ofensores.length > 0
          ? ofensores.map((number, index) => {
              return (
                <div key={"ofensor_" + index}>
                  <DatosOfensor
                    setGuardado={setGuardado}
                    data={ofensores[index]}
                    fullArray={ofensores}
                    index={index}
                    setOfensores={setOfensores}
                  ></DatosOfensor>
                </div>
              );
            })
          : ""}
        {concurrentes.length > 0
          ? concurrentes.map((number, index) => {
              return (
                <div key={"concurrente_" + index}>
                  <DatosConcurrente
                    setGuardado={setGuardado}
                    data={concurrentes[index]}
                    fullArray={concurrentes}
                    index={index}
                    setConcurrentes={setConcurrentes}
                  ></DatosConcurrente>
                </div>
              );
            })
          : ""}
      </div>
      <hr />
      <div className={[classes.container, classes.border4].join(" ")}>
        <div>
          <p className={classes.subtitulo}>Se acepta caso para ingreso*</p>
          <div className={classes.selectOne}>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={acepta_caso}
                onChange={() => {
                  setAcepta_caso(true);
                  setGuardado(false);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={!acepta_caso}
                onChange={() => {
                  setAcepta_caso(false);
                  setGuardado(false);
                }}
              />
              <span>No</span>
            </div>
          </div>
        </div>
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.subtitulo}>Mediador Asignado*</p>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={mediador_asignado ? mediador_asignado : ""}
              onChange={(event) => {
                setMediador_asignado(event.target.value);
                setGuardado(false);
              }}
            >
              <option disabled value="">
                {" "}
                -- Seleccione --{" "}
              </option>
              {mediadores.map(function (mediador, i) {
                return (
                  <option value={mediador} key={i}>
                    {mediador}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <p className={classes.subtitulo}>Comediador Asignado</p>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={
                comediador_asignado
                  ? comediador_asignado
                  : "No asigna comediador"
              }
              onChange={(event) => {
                setComediador_asignado(event.target.value);
                if (event.target.value === "No asigna comediador") {
                  setAsigna_comediador_bool(false);
                } else {
                  setAsigna_comediador_bool(true);
                }
                setGuardado(false);
              }}
            >
              <option disabled value=" ">
                {" "}
                -- Seleccione --{" "}
              </option>
              {comediadores.map(function (comediador, i) {
                return (
                  <option value={comediador} key={i}>
                    {comediador}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <br />
        {acepta_caso ? (
          <div>
            <p className={classes.subtitulo}>
              Caso ingresado por primera vez o reingresado (reingreso se cuenta
              como caso nuevo para efectos estadísticos). Seleccione la opción
              que corresponda.
            </p>
            <div className={classes.selectOne}>
              <div className={classes.checkWithOption}>
                <input
                  type="checkbox"
                  checked={primera_vez_bool}
                  onChange={() => {
                    setPrimera_vez_bool(true);
                    setGuardado(false);
                  }}
                />
                <span>Primera vez</span>
              </div>
              <div className={classes.checkWithOption}>
                <input
                  type="checkbox"
                  checked={!primera_vez_bool}
                  onChange={() => {
                    setPrimera_vez_bool(false);
                    setGuardado(false);
                  }}
                />
                <span>Reingresado</span>
              </div>
            </div>
            {datosObligatoriosVerificadosIngreso===false ? (
              <p className={classes.mensaje_error_datos}>
                Debe rellenar/seleccionar todos los campos obligatorios.
                Recuerde que al menos 1 Ofensor y 1 Víctima debe contar con
                datos válidos*
              </p>
            ) : (
              ""
            )}
            <div
              className={`${
                datosObligatoriosVerificadosIngreso===false
                  ? classes.boton_desactivado
                  : classes.boton
              }`}
              onClick={() => {
                setMostrar_modal_ingresar(true);
              }}
            >
              Ingresar
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!acepta_caso ? (
          <div>
            <div className={classes.subtitulo}>Causal de devolución*</div>
            <div>
              <select
                className={classes.actionBox}
                name=""
                id=""
                value={causal_devolucion ? causal_devolucion : ""}
                onChange={(event) => {
                  setCausal_devolucion(event.target.value);
                  setGuardado(false);
                }}
              >
                <option disabled value="">
                  {" "}
                  -- Seleccione --{" "}
                </option>
                {devolucion.map(function (causal, i) {
                  return (
                    <option value={causal} key={i}>
                      {causal}
                    </option>
                  );
                })}
              </select>
            </div>
            {causal_devolucion === "Otro" ? (
              <div>
                <div
                  className={`${classes.subtitulo} ${classes.subtitulo_div} ${classes.margin_top}`}
                >
                  <p>Especificar otra causal de derivación</p>
                </div>
                <input
                  className={classes.actionBox}
                  onChange={(event) => {
                    setOtra_causal_devolucion(event.target.value);
                    setGuardado(false);
                  }}
                  type="text"
                />
              </div>
            ) : (
              ""
            )}
            {!datosObligatoriosVerificadosDerivar ? (
              <p className={classes.mensaje_error_datos}>
                Debe rellenar/seleccionar todos los campos obligatorios*
              </p>
            ) : (
              ""
            )}
            <div
              className={`${
                !datosObligatoriosVerificadosDerivar
                  ? classes.boton_desactivado
                  : classes.boton
              }`}
              onClick={async () => {
                setMostrar_modal_derivar(true);
              }}
            >
              Derivar
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {/* <Tooltip id="my-tooltip" /> */}
    </div>
  );
}

export default FormularioIngreso;
