import { useState, useEffect } from "react";
import {
  addPremediacion,
  updatePremediacion,
} from "../../firebase/firebasePostData";
import classes from "./PremediacionConvocatoria.module.css";
import { Timestamp } from "firebase/firestore";
import { canales } from "../data/opciones";
import { toast } from "react-toastify";

function PremediacionConvocatoria(props) {
  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  const [guardado, setGuardado] = useState(true);
  const [tipoParticipante, setTipoParticipante] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.tipoParticipante : ""
  );
  const [participante, setParticipante] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.participante : ""
  );
  const [numero_convocatoria, setNumero_convocatoria] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.numero_convocatoria : 1
  );
  const [fecha_interaccion, setFecha_interaccion] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.fecha_interaccion : ""
  );
  const [canal_convocatoria, setCanal_convocatoria] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.canal_convocatoria : ""
  );
  const [volver_a_contactar, setVolver_a_contactar] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.volver_a_contactar : ""
  );
  const [
    fija_fecha_proxima_entrevista_bool,
    setFija_fecha_proxima_entrevista_bool,
  ] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.fija_fecha_proxima_entrevista_bool
      : false
  );
  const [fecha_proxima_entrevista, setFecha_proxima_entrevista] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.fecha_proxima_entrevista
      : ""
  );
  const [fija_fecha_mediacion_bool, setFija_fecha_mediacion_bool] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.fija_fecha_mediacion_bool
      : false
  );
  const [fecha_sesion_mediacion, setFecha_sesion_mediacion] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.fecha_sesion_mediacion
      : ""
  );
  const [observaciones_convocatoria, setObservaciones_convocatoria] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.observaciones_convocatoria
      : ""
  );
  const [
    observaciones_convocatoria_ofensor,
    setObservaciones_convocatoria_ofensor,
  ] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.observaciones_convocatoria_ofensor
      : ""
  );
  // Booleanos de objetivos alcanzados victima
  const [entrega_informacion_bool, setEntrega_informacion_bool] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.entrega_informacion_bool
      : false
  );
  const [convoca_entrevista_bool, setConvoca_entrevista_bool] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.convoca_entrevista_bool
      : false
  );
  const [acepta_mediacion_bool, setAcepta_mediacion_bool] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.acepta_mediacion_bool
      : false
  );
  const [
    se_informa_contraparte_no_participa_bool,
    setSe_informa_contraparte_no_participa_bool,
  ] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.se_informa_contraparte_no_participa_bool
      : false
  );
  const [no_se_ubica_bool, setNo_se_ubica_bool] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.no_se_ubica_bool : false
  );
  const [rechaza_participar_bool, setRechaza_participar_bool] = useState(
    props.datosConvocatoria
      ? props.datosConvocatoria.rechaza_participar_bool
      : false
  );
  const [ninguno_bool, setNinguno_bool] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.ninguno_bool : false
  );
  const [resultado, setResultado] = useState(
    props.datosConvocatoria ? props.datosConvocatoria.resultado : ""
  );

  let participantes = [];
  let numeroInteracciones = {};
  let tipoParticipantes = {};

  props.participantes.map((participante) => {
    let nombreApellido = participante.nombres + " " + participante.apellidos;
    tipoParticipantes[nombreApellido] = participante.tipo;
    participantes.push(nombreApellido);
    numeroInteracciones[nombreApellido] = { entrevistas: 0, convocatorias: 0 };
    return true;
  });

  props.actividades.map((actividad) => {
    for (let i = 0; i < participantes.length; i++) {
      if (
        actividad.participante === participantes[i] &&
        actividad.tipo === "convocatoria"
      ) {
        numeroInteracciones[participantes[i]]["convocatorias"] =
          numeroInteracciones[participantes[i]]["convocatorias"] + 1;
      }
    }
    return true;
  });

  let actividadData = {
    tipo: "convocatoria",
    participante: participante,
    numero_convocatoria: numero_convocatoria,
    fecha_interaccion: fecha_interaccion,
    entrega_informacion_bool: entrega_informacion_bool,
    convoca_entrevista_bool: convoca_entrevista_bool,
    acepta_mediacion_bool: acepta_mediacion_bool,
    se_informa_contraparte_no_participa_bool:
      se_informa_contraparte_no_participa_bool,
    no_se_ubica_bool: no_se_ubica_bool,
    rechaza_participar_bool: rechaza_participar_bool,
    ninguno_bool: ninguno_bool,
    canal_convocatoria: canal_convocatoria,
    volver_a_contactar: volver_a_contactar,
    //cerrar_en_convocatoria: false,
    fija_fecha_proxima_entrevista_bool: fija_fecha_proxima_entrevista_bool,
    fecha_proxima_entrevista: fecha_proxima_entrevista,
    fija_fecha_mediacion_bool: fija_fecha_mediacion_bool,
    fecha_sesion_mediacion: fecha_sesion_mediacion,
    observaciones_convocatoria: observaciones_convocatoria,
    resultado: resultado,
  };

  // EFECTO PARA DETECTAR CAMPOS OBLIGATORIOS

  const [datosObligatoriosVerificados, setDatosObligatoriosVerificados] =
    useState(false);

  const [objetivo_checked, setObjetivo_checked] = useState(false);

  // useEffect(() => {
  //   if (resultado !== "") {
  //     if (tipoParticipante === "ofensor") {
  //       props.setEstadoActualOfensor(resultado);
  //     } else if (tipoParticipante === "victima") {
  //       props.setEstadoActualVictima(resultado);
  //     }
  //   } else {
  //     if (tipoParticipante === "ofensor") {
  //       props.setEstadoActualOfensor(resultado);
  //     } else if (tipoParticipante === "victima") {
  //       props.setEstadoActualVictima(resultado);
  //     }
  //   }
  // }, [resultado]);

  useEffect(() => {
    console.log(datosObligatoriosVerificados);
    if (
      participante != "" &&
      fecha_interaccion != "" &&
      canal_convocatoria != "" &&
      (entrega_informacion_bool == true ||
        convoca_entrevista_bool == true ||
        acepta_mediacion_bool == true ||
        se_informa_contraparte_no_participa_bool == true ||
        no_se_ubica_bool == true ||
        rechaza_participar_bool == true ||
        ninguno_bool == true)
    ) {
      setDatosObligatoriosVerificados(true);
    } else {
      setDatosObligatoriosVerificados(false);
    }
  }, [
    participante,
    fecha_interaccion,
    canal_convocatoria,
    entrega_informacion_bool,
    convoca_entrevista_bool,
    acepta_mediacion_bool,
    se_informa_contraparte_no_participa_bool,
    no_se_ubica_bool,
    rechaza_participar_bool,
    ninguno_bool,
  ]);

  const notify = () => {
    toast.success("Datos Convocatoria guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div className={classes.mainComponente}>
      <h2>Datos Convocatoria</h2>
      <div>
        {guardado ? (
          <div className={classes.saveTrue}></div>
        ) : (
          <div className={classes.saveFalse}>Sin Guardar</div>
        )}
        <div className={classes.subtitulo}>Participante*</div>
        <div>
          <select
            className={classes.actionBox}
            name=""
            id=""
            defaultValue={participante}
            onChange={(event) => {
              setParticipante(event.target.value);
              setNumero_convocatoria(
                numeroInteracciones[event.target.value]["convocatorias"] + 1
              );
              setTipoParticipante(tipoParticipantes[event.target.value]);
              setGuardado(false);
              console.log(tipoParticipantes[event.target.value]);
            }}
          >
            <option disabled value="">
              {" "}
              -- Seleccione --{" "}
            </option>
            {participantes.map(function (participante, i) {
              return (
                <option value={participante} key={i}>
                  {participante}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <p className={classes.subtitulo}>
          Número de Interacción: {numero_convocatoria}
        </p>
      </div>
      {/* Fecha y canal de Convocatoria */}
      <div className={classes.dosPartes}>
        <div>
          <div className={classes.subtitulo}>Fecha interacción*</div>
          <input
            className={classes.actionBox}
            type="date"
            name=""
            id=""
            onBlur={(event) => {
              let newDate = new Date(event.target.value + "T00:00");
              let newTimestamp = Timestamp.fromDate(newDate);
              setFecha_interaccion(newTimestamp);
              setGuardado(false);
            }}
            defaultValue={handleDate(fecha_interaccion)
              .split("-")
              .reverse()
              .join("-")}
          />
        </div>
        {/* Canal de convocatoria */}
        <div>
          <div className={classes.subtitulo}>Canal convocatoria*</div>
          <div>
            <select
              className={classes.actionBox}
              name=""
              id=""
              defaultValue={canal_convocatoria}
              onChange={(event) => setCanal_convocatoria(event.target.value)}
            >
              <option disabled value="">
                {" "}
                -- Seleccione --{" "}
              </option>
              {canales.map(function (canal, i) {
                return (
                  <option value={canal} key={"canal_" + i}>
                    {canal}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div>
        <p className={classes.subtitulo}>Objetivo(s) Alcanzado(s)*</p>
        <div className={classes.dosPartes}>
          <div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={entrega_informacion_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(true);
                  setAcepta_mediacion_bool(false);
                  setRechaza_participar_bool(false);
                  setConvoca_entrevista_bool(false);
                  setNo_se_ubica_bool(false);
                  setNinguno_bool(false);
                  setResultado("Entrega información");
                }}
              />
              <span>Entrega información</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={acepta_mediacion_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setAcepta_mediacion_bool(true);
                  setRechaza_participar_bool(false);
                  setConvoca_entrevista_bool(false);
                  setNo_se_ubica_bool(false);
                  setNinguno_bool(false);
                  setResultado("AceptaMediacion");
                }}
              />
              <span>Acepta mediación</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={rechaza_participar_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setAcepta_mediacion_bool(false);
                  setRechaza_participar_bool(true);
                  setConvoca_entrevista_bool(false);
                  setNo_se_ubica_bool(false);
                  setNinguno_bool(false);
                  setResultado("Rechaza participar");
                }}
              />
              <span>Rechaza participar</span>
            </div>
          </div>
          <div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={convoca_entrevista_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setAcepta_mediacion_bool(false);
                  setRechaza_participar_bool(false);
                  setConvoca_entrevista_bool(true);
                  setNo_se_ubica_bool(false);
                  setNinguno_bool(false);
                  setResultado("Fija entrevista");
                }}
              />
              <span>Convoca a entrevista</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={no_se_ubica_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setAcepta_mediacion_bool(false);
                  setRechaza_participar_bool(false);
                  setConvoca_entrevista_bool(false);
                  setNo_se_ubica_bool(true);
                  setNinguno_bool(false);
                  setResultado("No se ubica");
                }}
              />
              <span>No es ubicable</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={ninguno_bool}
                onChange={(event) => {
                  setEntrega_informacion_bool(false);
                  setAcepta_mediacion_bool(false);
                  setRechaza_participar_bool(false);
                  setConvoca_entrevista_bool(false);
                  setNo_se_ubica_bool(false);
                  setNinguno_bool(true);
                  setResultado("Ninguno");
                }}
              />
              <span>Ninguno</span>
            </div>
          </div>
        </div>
      </div>
      {no_se_ubica_bool ? (
        <div>
          <p className={classes.subtitulo}>Volver a contactar</p>
          <select
            className={classes.actionBox}
            value={volver_a_contactar}
            onChange={(event) => {
              setVolver_a_contactar(event.target.value);
              setResultado(event.target.value);
            }}
          >
            <option value="Volver a contactar">Volver a contactar</option>
            <option value="Cerrar caso">Cerrar caso</option>
          </select>
        </div>
      ) : (
        ""
      )}
      {resultado === "Fija entrevista" || resultado == "AceptaMediacion" ? (
        <div>
          <p className={classes.subtitulo}>Fija fecha para entrevista</p>
          <div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={fija_fecha_proxima_entrevista_bool}
                onChange={() => setFija_fecha_proxima_entrevista_bool(true)}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                checked={!fija_fecha_proxima_entrevista_bool}
                onChange={() => setFija_fecha_proxima_entrevista_bool(false)}
              />
              <span>No</span>
            </div>
          </div>
          {fija_fecha_proxima_entrevista_bool ? (
            <div>
              <p>Fecha de próxima entrevista</p>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                onChange={(event) => {
                  let newDate = new Date(event.target.value + "T00:00");
                  let newTimestamp = Timestamp.fromDate(newDate);
                  setFecha_proxima_entrevista(newTimestamp);
                  setGuardado(false);
                }}
                defaultValue={handleDate(fecha_proxima_entrevista)
                  .split("-")
                  .reverse()
                  .join("-")}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div>
        <p className={classes.subtitulo}>Observaciones del evento</p>
        <textarea
          cols="60"
          rows="10"
          className={classes.textarea}
          name=""
          id=""
          defaultValue={observaciones_convocatoria}
          onBlur={(event) => {
            setObservaciones_convocatoria(event.target.value);
            setGuardado(false);
          }}
        ></textarea>
      </div>
      {!datosObligatoriosVerificados ? (
        <p className={classes.mensaje_error_datos}>
          Debe rellenar/seleccionar todos los campos obligatorios*
        </p>
      ) : (
        ""
      )}
      <div>
        <button
          className={`${
            !datosObligatoriosVerificados
              ? classes.boton1_desactivado
              : classes.boton1
          }`}
          onClick={async () => {
            // console.log(tipoParticipante)
            if (
              tipoParticipante === "ofensor" &&
              props.estadoActualOfensor !== "Expresa formalmente consentimiento"
            ) {
              props.setEstadoActualOfensor(resultado);
            } else if (
              tipoParticipante === "victima" &&
              props.estadoActualVictima !== "Expresa formalmente consentimiento"
            ) {
              props.setEstadoActualVictima(resultado);
            }
            let helperArray = [...props.actividades];
            if (props.indexConvocatoria) {
              helperArray[props.indexConvocatoria] = actividadData;
            } else {
              helperArray.push(actividadData);
            }

            console.log(actividadData);
            props.setActividades(helperArray);
            setGuardado(true);
            notify();
            props.volverEventos();
          }}
        >
          GUARDAR
        </button>
      </div>
    </div>
  );
}

export default PremediacionConvocatoria;
