import { useEffect, useState } from "react";
import Likert from "./Likert";
import Acuerdo from "./Acuerdo";
import classes from "./Satisfaccion.module.css";
import { updateEncuesta } from "../../../firebase/firebasePostData";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";

function EncuestaSatisfaccion(props) {
  const etapa = props.etapa;
  let level = 0;
  if (etapa === "Ingreso") {
    level = 0;
  } else if (etapa === "Premediación") {
    level = 1;
  } else if (etapa === "Mediación") {
    level = 2;
  } else if (etapa === "Seguimiento") {
    level = 3;
  } else if (etapa === "Cierre") {
    level = 4;
  } else {
    level = 4;
  }

  //Obtener participantes
  function getParticipantes() {
    let victimas = [];
    let ofensores = [];
    for (let victima of props.victimas) {
      victimas.push(victima.nombres + " " + victima.apellidos);
    }
    for (let ofensor of props.ofensores) {
      ofensores.push(ofensor.nombres + " " + ofensor.apellidos);
    }
    let participantes = [...victimas, ...ofensores];
    return participantes;
  }
  //Preguntas de satisfacción
  const [encuestas, setEncuestas] = useState(props.encuestas);
  const [encuestado, setEncuestado] = useState(getParticipantes()[0]);
  const [sencilla, setSencilla] = useState("");
  const [claridadMediacion, setClaridadMediacion] = useState("");
  const [participacion, setParticipacion] = useState("");
  const [neutralidad, setNeutralidad] = useState("");
  const [claridadSeguimiento, setClaridadSeguimiento] = useState("");
  const [plazo, setPlazo] = useState("");
  const [trabajoMediadora, setTrabajoMediadora] = useState("");
  const [accesibilidad, setAccesibilidad] = useState("");
  const [calidadInfraestructura, setCalidadInfraestructura] = useState("");
  const [resultadoEjecucion, setResultadoEjecucion] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [justicia, setJusticia] = useState("");
  const [experiencia, setExperiencia] = useState("");
  //Preguntas de afirmación
  const [decisionVoluntaria, setDecisionVoluntaria] = useState("");
  const [escuchado, setEscuchado] = useState("");
  const [victimaOfensorAsume, setVictimaOfensorAsume] = useState("");
  const [victimaReparacion, setVictimaReparacion] = useState("");
  const [ofensorReparo, setOfensorReparo] = useState("");
  const [ofensorResponsabilizado, setOfensorResponsabilizado] = useState("");
  const [recomendaria, setRecomendaria] = useState("");
  const [satisfecho, setSatisfecho] = useState("");

  //Actualización de encuestas
  useEffect(() => {
    if (encuestas[encuestado]) {
      let satisfaccion = props.encuestas[encuestado]["satisfaccion"];
      let afirmacion = props.encuestas[encuestado]["afirmacion"];
      setSencilla(satisfaccion["sencilla"]);
      setClaridadMediacion(satisfaccion["claridadMediacion"]);
      setParticipacion(satisfaccion["participacion"]);
      setNeutralidad(satisfaccion["neutralidad"]);
      setClaridadSeguimiento(satisfaccion["claridadSeguimiento"]);
      setPlazo(satisfaccion["plazo"]);
      setTrabajoMediadora(satisfaccion["trabajoMediadora"]);
      setAccesibilidad(satisfaccion["accesibilidad"]);
      setCalidadInfraestructura(satisfaccion["calidadInfraestructura"]);
      setResultadoEjecucion(satisfaccion["resultadoEjecucion"]);
      setTiempo(satisfaccion["tiempo"]);
      setJusticia(satisfaccion["justicia"]);
      setExperiencia(satisfaccion["experiencia"]);
      setDecisionVoluntaria(afirmacion["decisionVoluntaria"]);
      setEscuchado(afirmacion["escuchado"]);
      setVictimaOfensorAsume(afirmacion["victimaOfensorAsume"]);
      setVictimaReparacion(afirmacion["victimaReparacion"]);
      setOfensorReparo(afirmacion["ofensorReparo"]);
      setOfensorResponsabilizado(afirmacion["ofensorResponsabilizado"]);
      setRecomendaria(afirmacion["recomendaria"]);
      setSatisfecho(afirmacion["satisfecho"]);
    } else {
      setSencilla("");
      setClaridadMediacion("");
      setParticipacion("");
      setNeutralidad("");
      setClaridadSeguimiento("");
      setPlazo("");
      setTrabajoMediadora("");
      setAccesibilidad("");
      setCalidadInfraestructura("");
      setResultadoEjecucion("");
      setTiempo("");
      setJusticia("");
      setExperiencia("");
      setDecisionVoluntaria("");
      setEscuchado("");
      setVictimaOfensorAsume("");
      setVictimaReparacion("");
      setOfensorReparo("");
      setOfensorResponsabilizado("");
      setRecomendaria("");
      setSatisfecho("");
    }
  }, [encuestas, encuestado, props.encuestas]);

  let satisfaccion = {
    sencilla: sencilla,
    claridadMediacion: claridadMediacion,
    participacion: participacion,
    neutralidad: neutralidad,
    claridadSeguimiento: claridadSeguimiento,
    plazo: plazo,
    trabajoMediadora: trabajoMediadora,
    accesibilidad: accesibilidad,
    calidadInfraestructura: calidadInfraestructura,
    resultadoEjecucion: resultadoEjecucion,
    tiempo: tiempo,
    justicia: justicia,
    experiencia: experiencia,
  };
  let afirmacion = {
    decisionVoluntaria: decisionVoluntaria,
    escuchado: escuchado,
    victimaOfensorAsume: victimaOfensorAsume,
    ofensorReparo: ofensorReparo,
    ofensorResponsabilizado: ofensorResponsabilizado,
    recomendaria: recomendaria,
    satisfecho: satisfecho,
  };

  let data = {
    [encuestado]: {
      satisfaccion: satisfaccion,
      afirmacion: afirmacion,
    },
  };

  let participantes = getParticipantes();

  const encuestaGuardada = () => {
    toast.success("Se ha guardado la información con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorGuardado = () => {
    toast.error(
      "Hubo un error en el guardado, intente nuevamente o comuníquese con soporte",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  return (
    <div className={classes.mainContainer}>
      <div>
        <div className={classes.subtitulo}>Participante</div>
        <div>
          <select
            className={classes.actionBox}
            name=""
            id=""
            defaultValue={getParticipantes()[0]}
            onChange={(event) => setEncuestado(event.target.value)}
          >
            <option disabled value=" ">
              {" "}
              -- Seleccione --{" "}
            </option>
            {participantes.map(function (participante, i) {
              return (
                <option value={participante} key={i}>
                  {participante}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {level === 0 ? (
        <div className={classes.sinEncuesta}>
          No corresponde realizar encuesta de satisfacción o de afirmación
        </div>
      ) : (
        <div>
          {/* Encuesta de Satisfacción */}
          <div>
            <div className={classes.titulo}>Satisfacción</div>
            <div className={classes.description}>
              Por favor califique con nota de 1 a 7, donde 1 es muy malo y 7 es
              excelente, los siguientes aspectos del proceso de mediación en los
              que usted ha participado:
            </div>

            {level >= 1 ? (
              <div className={classes.option}>
                <div>Lo sencilla que fue la comunicación con la mediadora</div>
                <div>
                  <Likert setValue={setSencilla} value={sencilla}></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 1 ? (
              <div className={classes.option}>
                <div>
                  La claridad para explicarle en qué consiste el proceso de
                  mediación
                </div>
                <div>
                  <Likert
                    setValue={setClaridadMediacion}
                    value={claridadMediacion}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level > 1 ? (
              <div className={classes.option}>
                <div>
                  Su participación -como víctima/ofensor- en la construcción del
                  acuerdo {/*  (M sólo cuando hay acuerdo) */}
                </div>
                <div>
                  <Likert
                    setValue={setParticipacion}
                    value={participacion}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>
                  La neutralidad que mostró la mediadora en las sesiones de
                  mediación
                </div>
                <div>
                  <Likert
                    setValue={setNeutralidad}
                    value={neutralidad}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 3 ? (
              <div className={classes.option}>
                <div>
                  La claridad con que se le explicó el proceso de seguimiento
                  del acuerdo
                </div>
                <div>
                  <Likert
                    setValue={setClaridadSeguimiento}
                    value={claridadSeguimiento}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 3 ? (
              <div className={classes.option}>
                <div>
                  El cumplimiento del plazo comprometido con usted para
                  contactarles para el seguimiento
                </div>
                <div>
                  <Likert setValue={setPlazo} value={plazo}></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>
                  El trabajo desarrollado por la mediadora a lo largo de su
                  experiencia de mediación
                </div>
                <div>
                  <Likert
                    setValue={setTrabajoMediadora}
                    value={trabajoMediadora}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 1 ? (
              <div className={classes.option}>
                <div>La accesibilidad o ubicación del centro de mediación</div>
                <div>
                  <Likert
                    setValue={setAccesibilidad}
                    value={accesibilidad}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 1 ? (
              <div className={classes.option}>
                <div>
                  La calidad de la infraestructura del centro de mediación
                </div>
                <div>
                  <Likert
                    setValue={setCalidadInfraestructura}
                    value={calidadInfraestructura}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>El resultado de la ejecución del acuerdo</div>
                <div>
                  <Likert
                    setValue={setResultadoEjecucion}
                    value={resultadoEjecucion}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {level >= 4 ? (
              <div className={classes.option}>
                <div>Con el cierre penal de su proceso</div>
                <div>
                  <Likert
                    setValue={setCierrePenal}
                    value={cierrePenal}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )} */}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>
                  El tiempo total que tomó el proceso de mediación (desde el
                  inicio hasta el cierre)
                </div>
                <div>
                  <Likert setValue={setTiempo} value={tiempo}></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>
                  El nivel de justicia que, para usted, se alcanzó con el
                  proceso de mediación
                </div>
                <div>
                  <Likert setValue={setJusticia} value={justicia}></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
            {level >= 2 ? (
              <div className={classes.option}>
                <div>
                  Califique, en general, su experiencia con el proceso total de
                  mediación en el que usted participó
                </div>
                <div>
                  <Likert
                    setValue={setExperiencia}
                    value={experiencia}
                  ></Likert>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className={classes.separator} />
          {/* Encuesta Afirmación */}
          {level === 1 ? (
            <div className={classes.sinEncuesta}>
              No corresponde realizar encuesta de afirmación
            </div>
          ) : (
            <div>
              <div className={classes.titulo}>Afirmación</div>
              <div className={classes.description}>
                Ante las siguientes afirmaciones, por favor indique si ested sí
                está de acuerdo, si está medianamente de acuerdo o no está de
                acuerdo:
              </div>
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    Tomó la decisión de participar de la mediación de modo
                    completamente voluntario
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setDecisionVoluntaria}
                      value={decisionVoluntaria}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>Se sintó escuchado/a en el espacio de mediación</div>
                  <div>
                    <Acuerdo
                      setValue={setEscuchado}
                      value={escuchado}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    (Para víctima) Siente que el proceso logró que el ofensor
                    asumiera su responsabilidad
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setVictimaOfensorAsume}
                      value={victimaOfensorAsume}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    (Para víctima) Siente que el proceso respondió a su
                    necesidad de reparación
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setVictimaReparacion}
                      value={victimaReparacion}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    (Para ofensor) Siente que ha reparado el daño que produjo a
                    la víctima
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setOfensorReparo}
                      value={ofensorReparo}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    (Para ofensor) Siente que se ha responsabilizado por el daño
                    causado a la víctima
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setOfensorResponsabilizado}
                      value={ofensorResponsabilizado}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    Recomendaría a otra persona participar de un proceso de
                    mediación como este
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setRecomendaria}
                      value={recomendaria}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
              {level >= 2 ? (
                <div className={classes.option}>
                  <div>
                    Se encuentra satisfecho, en general, con haber participado
                    de esta forma de implementar la justicia
                  </div>
                  <div>
                    <Acuerdo
                      setValue={setSatisfecho}
                      value={satisfecho}
                    ></Acuerdo>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className={classes.div_boton}>
            <button
              className={classes.boton_enviar}
              onClick={() => {
                if (Object.keys(props.encuestas).length !== 0) {
                  data = { ...props.encuestas };
                  data[encuestado] = {
                    satisfaccion: satisfaccion,
                    afirmacion: afirmacion,
                  };
                  updateEncuesta(
                    props.id,
                    { encuestas: data },
                    encuestaGuardada,
                    errorGuardado,
                    props.setIsCargandoCaso
                  );
                } else {
                  updateEncuesta(
                    props.id,
                    { encuestas: data },
                    encuestaGuardada,
                    errorGuardado,
                    props.setIsCargandoCaso
                  );
                }
              }}
            >
              GUARDAR ENCUESTA
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EncuestaSatisfaccion;
