// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//Elegir "demo", "test" o "prod" según se necesite
const selectedDb = "demo";

const firebaseConfig = {
  apiKey: "AIzaSyDoO5b3eFd-joH9yRT2G3VJ5-yvFOEO4ic",
  authDomain: "sam-piloto-d938f.firebaseapp.com",
  projectId: "sam-piloto-d938f",
  storageBucket: "sam-piloto-d938f.appspot.com",
  messagingSenderId: "453598469287",
  appId: "1:453598469287:web:faa886c17c9bac0c230f82",
  measurementId: "G-8Z9NCYSPSR",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyCyxPqqDNIJYNVshPpyPx7qHNj5d6HQgjI",
  authDomain: "sam-prod-a57b8.firebaseapp.com",
  projectId: "sam-prod-a57b8",
  storageBucket: "sam-prod-a57b8.appspot.com",
  messagingSenderId: "515093502492",
  appId: "1:515093502492:web:88794ab07d9a00124b5dd9",
  measurementId: "G-TMTH1WGWRL",
};

const firebaseConfigDemo = {
  apiKey: "AIzaSyB-oUiriRhSCSzlEV7zIxaSS91OTKif3Yg",
  authDomain: "fir-sg-b38d9.firebaseapp.com",
  projectId: "fir-sg-b38d9",
  storageBucket: "fir-sg-b38d9.appspot.com",
  messagingSenderId: "1054638695265",
  appId: "1:1054638695265:web:03ad67ee5c17491f2d1b20"
};

const database = {
  test: firebaseConfig,
  prod: firebaseConfigProd,
  demo: firebaseConfigDemo
}

// Initialize Firebase
const app = initializeApp(database[selectedDb]);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, auth };
