import { useState, useRef, useEffect } from "react";
import classes from "./Seguimiento.module.css";
import save from "../../img/icono_saved.png";
import attach from "../../img/icono_attach.png";
import { Drawer } from "antd";
import black_clip from "../../img/black_clip.png";
import download from "../../img/download.png";
import check from "../../img/check.png";
import { addCierre, updateSeguimiento } from "../../firebase/firebasePostData";
import { postStorage } from "../../firebase/firebaseStorage";
import axios from "axios";
import fileDownload from "js-file-download";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";

function FormularioSeguimiento(props) {
  const [mostrar_modal_pasar_a_cierre, setMostrar_modal_pasar_a_cierre] =
    useState("true");

  // *****************************************
  // Funciones, Hooks y States para Subir Archivo Ratificación a Storage
  const [fileNuevo, setFileNuevo] = useState("");
  // const [uploadeableFileRatificacion, setUploadeableFileRatificacion] =
  //   useState("");
  const [fileRatificacion, setFileRatificacion] = useState("");
  const [fileActaCumplimiento, setFileActaCumplimiento] = useState("");

  const [adjuntos_seguimiento, setAdjuntos_seguimiento] = useState(
    props.caso.adjuntos_seguimiento ? props.caso.adjuntos_seguimiento : []
  );

  const [nombreNuevoArchivo, setNombreNuevoArchivo] = useState("");

  function handleDate(timestamp) {
    if (typeof timestamp === typeof Timestamp.now()) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "";
    }
  }

  // *****************************************

  // ESTADOS PARA GUARDADO EN BACKEND
  const id = props.caso.id;
  const historicoRef = props.caso.historicoRef;
  const [fecha_inicio_seguimiento, setFecha_inicio_seguimiento] = useState(
    props.caso.fecha_inicio_seguimiento
      ? props.caso.fecha_inicio_seguimiento
      : ""
  );
  const [victimas, setVictimas] = useState(
    props.caso.victimas ? props.caso.victimas : ""
  );
  const [ofensores, setOfensores] = useState(
    props.caso.ofensores ? props.caso.ofensores : ""
  );
  const [ruc, setRuc] = useState(props.caso.ruc ? props.caso.ruc : "");
  const [fecha_acuerdo, setFecha_acuerdo] = useState(
    props.caso.fecha_acuerdo ? props.caso.fecha_acuerdo : ""
  );
  const [acuerdo_adjunto, setAcuerdo_adjunto] = useState(
    props.caso.acuerdo_adjunto ? props.caso.acuerdo_adjunto : ""
  );
  const [tipo_acuerdo, setTipo_acuerdo_seguimiento] = useState(
    props.caso.tipo_acuerdo ? props.caso.tipo_acuerdo : ""
  );
  const [cantidad_dias_para_cumplimiento, setCantidad_dias_para_cumplimiento] =
    useState(
      props.caso.cantidad_dias_para_cumplimiento
        ? props.caso.cantidad_dias_para_cumplimiento
        : ""
    );
  const [cumplimiento_cuotas_bool, setCumplimiento_cuotas_bool] = useState(
    props.caso.cumplimiento_cuotas_bool
      ? props.caso.cumplimiento_cuotas_bool
      : ""
  );
  const [fecha_cumplimiento_sin_cuotas, setFecha_cumplimiento_sin_cuotas] =
    useState(
      props.caso.fecha_cumplimiento_sin_cuotas
        ? props.caso.fecha_cumplimiento_sin_cuotas
        : ""
    );
  const [cuotas, setCuotas] = useState(
    props.caso.cuotas ? props.caso.cuotas : []
  );

  const [cantidad_cuotas, setCantidad_cuotas] = useState(
    props.caso.cantidad_cuotas ? props.caso.cantidad_cuotas : 0
  );
  const [acciones_mediador, setAcciones_mediador] = useState(
    props.caso.acciones_mediador ? props.caso.acciones_mediador : []
  );
  const [cumple_acuerdo, setCumple_acuerdo] = useState(
    props.caso.cumple_acuerdo ? props.caso.cumple_acuerdo : ""
  );
  const [observaciones_cumple_acuerdo, setObservaciones_cumple_acuerdo] =
    useState(
      props.caso.observaciones_cumple_acuerdo
        ? props.caso.observaciones_cumple_acuerdo
        : ""
    );
  const [
    requiere_firmas_ratificacion_bool,
    setRequiere_firmas_ratificacion_bool,
  ] = useState(
    props.caso.requiere_firmas_ratificacion_bool
      ? props.caso.requiere_firmas_ratificacion_bool
      : ""
  );
  const [se_realizan_firmas_bool, setSe_realizan_firmas_bool] = useState(
    props.caso.se_realizan_firmas_bool ? props.caso.se_realizan_firmas_bool : ""
  );
  const [acta_cumplimiento_mediador_bool, setActa_cumplimiento_mediador_bool] =
    useState(
      props.caso.acta_cumplimiento_mediador_bool
        ? props.caso.acta_cumplimiento_mediador_bool
        : ""
    );
  const [adjunto_ratificacion, setAdjunto_ratificacion] = useState(
    props.caso.adjunto_ratificacion ? props.caso.adjunto_ratificacion : ""
  );
  const [adjunto_acta_cumplimiento, setAdjunto_acta_cumplimiento] = useState(
    props.caso.adjunto_acta_cumplimiento
      ? props.caso.adjunto_acta_cumplimiento
      : ""
  );
  const [
    observaciones_generales_seguimiento,
    setObservaciones_generales_seguimiento,
  ] = useState(
    props.caso.observaciones_generales_seguimiento
      ? props.caso.observaciones_generales_seguimiento
      : ""
  );
  const [fecha_termino_seguimiento, setFecha_termino_seguimiento] = useState(
    props.caso.fecha_termino_seguimiento
      ? props.caso.fecha_termino_seguimiento
      : ""
  );
  const [fecha_derivacion_caso_fiscalia, setFecha_derivacion_caso_fiscalia] =
    useState(
      props.caso.fecha_derivacion_caso_fiscalia
        ? props.caso.fecha_derivacion_caso_fiscalia
        : ""
    );
  const [resultados_seguimiento, setResultados_seguimiento] = useState(
    props.caso.resultados_seguimiento ? props.caso.resultados_seguimiento : ""
  );
  const [ultima_modificacion, setUltima_modificacion] = useState(
    props.caso.ultima_modificacion ? props.caso.ultima_modificacion : ""
  );
  const [estado_seguimiento, setEstado_seguimiento] = useState(
    props.caso.estado_seguimiento ? props.caso.estado_seguimiento : ""
  );

  const [mediador_asignado, setMediador_asignado] = useState(
    props.caso ? props.caso.mediador_asignado : ""
  );

  const [comediador_asignado, setComediador_asignado] = useState(
    props.caso ? props.caso.comediador_asignado : ""
  );

  const ingresoRef = props.caso.ingresoRef;
  const premediacionRef = props.caso.premediacionRef;
  const mediacionRef = props.caso.mediacionRef;
  const delito = props.caso.delito;

  // ESTADOS PARA DINAMICAS DE FRONTEND

  const [cuotasCantidad, setCuotasCantidad] = useState(
    props.caso.cuotasCantidad ? props.caso.cuotasCantidad : 0
  );
  const cuotasRef = useRef();

  const [isCheckedCumplimientoCuotas, setIsCheckedCumplimientoCuotas] =
    useState(null);

  const handleCheckboxChangeCumplimientoCuotas = (event) => {
    setIsCheckedCumplimientoCuotas(event.target.value);
  };

  // const handleCuotasChange = (event) => {
  //   setCuotasCantidad(parseInt(event.target.value)); // Convierte el valor ingresado a un número entero
  // };

  const handleCuotasButtonClick = () => {
    const cuotasValue = parseInt(cuotasRef.current.value); // Obtiene el valor ingresado en el input
    setCuotasCantidad(cuotasValue); // Actualiza el estado del componente con el valor ingresado
  };

  const cuotasArray = Array.from({ length: cantidad_cuotas });

  // Acciones en Seguimiento prePush a Acciones_mediacion

  const [acciones, setAcciones] = useState([]);

  const handleAgregarAccionClick = () => {
    const fechaAccion = fechaAccionRef.current.value; // Obtiene la fecha seleccionada en el input
    const tipoAccion = document.querySelector("#tipo-accion").value; // Obtiene el tipo de acción seleccionada en el select

    const nuevaAccion = {
      fecha: fechaAccion,
      tipo: tipoAccion,
      index: acciones.length,
    };
    setAcciones([...acciones, nuevaAccion]);
  };

  const fechaAccionRef = useRef();

  const [isCheckedAcuerdoRatificacion, setIsCheckedAcuerdoRatificacion] =
    useState(null);

  const handleCheckboxChangeAcuerdoRatificacion = (event) => {
    setIsCheckedAcuerdoRatificacion(event.target.value);
  };

  const [isCheckedActaMediador, setIsCheckedActaMediador] = useState(null);

  const handleCheckboxChangeActaMediador = (event) => {
    setIsCheckedActaMediador(event.target.value);
  };

  // STATES Y FUNCIONES DRAWER

  const [visible, setVisible] = useState(false);
  const [guardado, setGuardado] = useState(true);

  let seguimientoData = {
    id: id,
    ruc: ruc,
    delito: delito,
    fecha_inicio_seguimiento: fecha_inicio_seguimiento,
    victimas: victimas,
    ofensores: ofensores,
    fecha_acuerdo: fecha_acuerdo,
    acuerdo_adjunto: acuerdo_adjunto,
    tipo_acuerdo: tipo_acuerdo,
    adjuntos_seguimiento: adjuntos_seguimiento,
    cantidad_dias_para_cumplimiento: cantidad_dias_para_cumplimiento,
    cumplimiento_cuotas_bool: cumplimiento_cuotas_bool,
    fecha_cumplimiento_sin_cuotas: fecha_cumplimiento_sin_cuotas,
    cantidad_cuotas: cantidad_cuotas,
    cuotas: cuotas,
    acciones_mediador: acciones_mediador,
    cumple_acuerdo: cumple_acuerdo,
    observaciones_cumple_acuerdo: observaciones_cumple_acuerdo,
    requiere_firmas_ratificacion_bool: requiere_firmas_ratificacion_bool,
    se_realizan_firmas_bool: se_realizan_firmas_bool,
    acta_cumplimiento_mediador_bool: acta_cumplimiento_mediador_bool,
    adjunto_ratificacion: adjunto_ratificacion,
    adjunto_acta_cumplimiento: adjunto_acta_cumplimiento,
    observaciones_generales_seguimiento: observaciones_generales_seguimiento,
    fecha_termino_seguimiento: fecha_termino_seguimiento,
    fecha_derivacion_caso_fiscalia: fecha_derivacion_caso_fiscalia,
    resultados_seguimiento: resultados_seguimiento,
    fecha_ultima_modificacion: ultima_modificacion,
    estado: "",
    ingresoRef: ingresoRef,
    premediacionRef: premediacionRef,
    mediacionRef: mediacionRef,
    historicoRef: historicoRef,
    mediador_asignado: mediador_asignado,
    comediador_asignado: comediador_asignado,
  };

  let victima =
    props.caso.victimas.length > 0
      ? props.caso.victimas.length > 1
        ? "Múltiple"
        : props.caso.victimas[0].nombres +
          " " +
          props.caso.victimas[0].apellidos
      : "Sin información";

  let ofensor =
    props.caso.ofensores.length > 0
      ? props.caso.ofensores.length > 1
        ? "Múltiple"
        : props.caso.ofensores[0].nombres +
          " " +
          props.caso.ofensores[0].apellidos
      : "Sin información";

  function handleDownload(url, filename) {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  }

  const [mostrar_modal_guardar, setMostrar_modal_guardar] = useState(false);

  const guardarFunciones = async () => {
    setMostrar_modal_guardar(true);
  };

  const notify = () => {
    toast.success("Datos Seguimiento guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const documento_agregado = () => {
    toast.success(
      "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const notify_accion = () => {
    toast.success("Datos Acción Mediador guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const paso_a_cierre = () => {
    toast.success("Caso pasa a etapa de Cierre con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.save} onClick={guardarFunciones}>
        <img className={classes.saveIcon} src={save} alt="" />
      </div>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <img className={classes.attachIcon} src={attach} alt="" />
      </div>
      {mostrar_modal_pasar_a_cierre == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>PASO A CIERRE</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de pasar el caso a la fase de Cierre, asegúrate de
              haber ingresado todos los datos que necesitas.
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                let url;
                let url1;
                if (fileRatificacion !== "") {
                  await postStorage(
                    "seguimiento/" + fileRatificacion.name,
                    fileRatificacion
                  ).then((archivoRutaDescargaRatificacion) => {
                    url = archivoRutaDescargaRatificacion;
                    seguimientoData.adjunto_ratificacion = url;
                  });
                }

                if (fileActaCumplimiento !== "") {
                  await postStorage(
                    "seguimiento/" + fileActaCumplimiento.name,
                    fileActaCumplimiento
                  ).then((archivoRutaDescargaAcuefileActaCumplimiento) => {
                    url1 = archivoRutaDescargaAcuefileActaCumplimiento;
                    seguimientoData.adjunto_acta_cumplimiento = url1;
                  });
                }
                updateSeguimiento(seguimientoData);
                addCierre(seguimientoData);
                paso_a_cierre();
                props.closeModal();
              }}
              className={classes.btn_seguir_en_form}
            >
              Pasar a Cierre
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_pasar_a_cierre(false);
              }}
            >
              Seguir editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_guardar == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>GUARDAR DATOS</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de guardar los datos del formulario, ¿como quieres
              continuar?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                let url;
                let url1;
                if (fileRatificacion !== "") {
                  await postStorage(
                    "seguimiento/" + fileRatificacion.name,
                    fileRatificacion
                  ).then((archivoRutaDescargaRatificacion) => {
                    url = archivoRutaDescargaRatificacion;
                    seguimientoData.adjunto_ratificacion = url;
                  });
                }

                if (fileActaCumplimiento !== "") {
                  await postStorage(
                    "seguimiento/" + fileActaCumplimiento.name,
                    fileActaCumplimiento
                  ).then((archivoRutaDescargaAcuefileActaCumplimiento) => {
                    url1 = archivoRutaDescargaAcuefileActaCumplimiento;
                    seguimientoData.adjunto_acta_cumplimiento = url1;
                  });
                }
                updateSeguimiento(seguimientoData);

                setMostrar_modal_guardar(false);
                notify();
                if (!props.caso) {
                  props.closeModal();
                }
              }}
              className={classes.btn_seguir_en_form}
            >
              Guardar y seguir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                let url;
                let url1;
                if (fileRatificacion !== "") {
                  await postStorage(
                    "seguimiento/" + fileRatificacion.name,
                    fileRatificacion
                  ).then((archivoRutaDescargaRatificacion) => {
                    url = archivoRutaDescargaRatificacion;
                    seguimientoData.adjunto_ratificacion = url;
                  });
                }

                if (fileActaCumplimiento !== "") {
                  await postStorage(
                    "seguimiento/" + fileActaCumplimiento.name,
                    fileActaCumplimiento
                  ).then((archivoRutaDescargaAcuefileActaCumplimiento) => {
                    url1 = archivoRutaDescargaAcuefileActaCumplimiento;
                    seguimientoData.adjunto_acta_cumplimiento = url1;
                  });
                }
                updateSeguimiento(seguimientoData);
                notify();
                props.closeModal();
              }}
            >
              Guardar y salir
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={[classes.encabezado, classes.border1].join(" ")}>
        SEGUIMIENTO
      </div>
      <div className={[classes.infoInicial]}>
        <div className={[classes.infoInicial_columna1]}>
          <div className={[classes.etiquetaCaso]}>
            <p>
              CASO: <span>{props.caso.ruc ? props.caso.ruc : "000"}</span>
            </p>
          </div>
          {/* <div>
            <p>
              Fecha de Ingreso{" "}
              <span className={[classes.etiquetaFechas]}>14/01/2023</span>
            </p>
          </div> */}
        </div>
        <div className={[classes.infoInicial_columna2]}>
          <div>
            <div
              className={classes.acuerdoPartes}
              onClick={() => {
                handleDownload(
                  acuerdo_adjunto,
                  `Acuerdo${victima}_${ofensor}.pdf`
                );
              }}
            >
              <img className={classes.blackClip} src={black_clip} alt="" />
              <p>
                <i>Acuerdo entre las partes</i>{" "}
              </p>
              <img className={classes.download} src={download} alt="" />
            </div>
          </div>
          <div className={classes.fecha_acuerdo}>
            <p>Fecha del acuerdo</p>
            <p className={[classes.etiquetaFechas]}>
              {handleDate(fecha_acuerdo)}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className={classes.contenido_seguimiento}>
        {props.modal_cierre_visible == true ? (
          <div className={classes.guardarModalContainer}>
            <div className={classes.encabezadoGuardar}>CERRAR FORMULARIO</div>
            <div className={classes.enunciado_modal}>
              <p>
                Estás a punto de cerrar el formulario, recuerda que cualquier
                cambio que no hayas guardado se perderá. Por favor, asegúrate de
                haber guardado tus cambios.
              </p>
            </div>
            <div className={classes.botones_modales_verificacion}>
              <button
                onClick={async () => {
                  props.setModal_cierre_visible(false);
                }}
                className={classes.btn_seguir_en_form}
              >
                Seguir editando
              </button>
              <button
                onClick={async () => {
                  let url;
                  let url1;
                  if (fileRatificacion !== "") {
                    await postStorage(
                      "seguimiento/" + fileRatificacion.name,
                      fileRatificacion
                    ).then((archivoRutaDescargaRatificacion) => {
                      url = archivoRutaDescargaRatificacion;
                      seguimientoData.adjunto_ratificacion = url;
                    });
                  }

                  if (fileActaCumplimiento !== "") {
                    await postStorage(
                      "seguimiento/" + fileActaCumplimiento.name,
                      fileActaCumplimiento
                    ).then((archivoRutaDescargaAcuefileActaCumplimiento) => {
                      url1 = archivoRutaDescargaAcuefileActaCumplimiento;
                      seguimientoData.adjunto_acta_cumplimiento = url1;
                    });
                  }
                  updateSeguimiento(seguimientoData);
                  notify();
                  props.closeModal();
                }}
                className={classes.btn_guardar_en_form}
              >
                Guardar y salir
              </button>
              <button
                className={classes.btn_cerrar_form}
                onClick={async () => {
                  props.closeModal();
                }}
              >
                Salir sin guardar
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <Drawer
          open={visible}
          title="Archivos Adjuntos Seguimiento"
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className={classes.div_principal_drawer}>
            {/* Archivos provenientes de Database */}
            {acuerdo_adjunto != "" ? (
              <div>
                <p>Acuerdo entre las partes</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      acuerdo_adjunto,
                      `Acuerdo_Partes_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            {adjunto_ratificacion != "" ? (
              <div>
                <p>Documento Firma/Ratificación Acuerdo</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      adjunto_ratificacion,
                      `Ratificacion_Acuerdo_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            {adjunto_acta_cumplimiento != "" ? (
              <div>
                <p>Acta Cumplimiento Mediador</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      adjunto_acta_cumplimiento,
                      `Acta_Cumplimiento_Mediador_Caso_${ruc}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
            ) : (
              ""
            )}
            {/* {adjunto_acta_cumplimiento == "" && adjunto_ratificacion == "" ? <div>Seguimiento sin documentos adjuntos hasta la fecha</div> : ""} */}
          </div>
          <hr />
          <div className={[`${classes.nuevos_documentos}`]}>
            {adjuntos_seguimiento != undefined ? (
              <div>
                {adjuntos_seguimiento.map((documento, index) => (
                  <div key={index}>
                    <p>{documento.nombre}</p>
                    <button
                      className={classes.btn_descarga_drawer}
                      onClick={() => {
                        handleDownload(
                          documento.archivo,
                          `${documento.nombre}.pdf`
                        );
                      }}
                    >
                      Descargar
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.div_subir_nuevos_documentos}>
            <hr />

            <div className={classes.columna_subir_nuevo}>
              <p className={classes.subtitulo}>Subir Nuevo Documento</p>
              <label htmlFor="file-nuevo" className={classes.fileInputLabel}>
                Seleccionar
              </label>
              <input
                className={classes.fileInput}
                type="file"
                id="file-nuevo"
                onChange={(e) => {
                  e.target.nextElementSibling.innerText =
                    e.target.files[0].name;
                  setFileNuevo(e.target.files[0]);
                  console.log(fileNuevo);
                }}
              />
              <span className={classes.fileName} id="span_subir"></span>
              <input
                type="text"
                placeholder="Ingrese nombre documento"
                value={nombreNuevoArchivo}
                onChange={(e) => setNombreNuevoArchivo(e.target.value)}
              />
              <button
                className={classes.subir_nuevo_btn}
                onClick={async () => {
                  if (fileNuevo !== "") {
                    console.log(fileNuevo);
                    let urlNuevo;
                    await postStorage(
                      "seguimiento/" + fileNuevo.name,
                      fileNuevo
                    ).then((archivoRutaDescarga) => {
                      urlNuevo = archivoRutaDescarga;

                      setAdjuntos_seguimiento([
                        ...adjuntos_seguimiento,
                        { nombre: nombreNuevoArchivo, archivo: urlNuevo },
                      ]);
                    });
                    updateSeguimiento(seguimientoData);

                    documento_agregado();
                  }
                }}
              >
                Subir documento
              </button>
            </div>
          </div>
        </Drawer>
        {/* Inicio Formulario */}
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.titulos_div}>Tipo de acuerdo:</p>
            {tipo_acuerdo.map((acuerdo, index) => (
              <p key={index}>{acuerdo}</p>
            ))}
          </div>
          <div>
            <p className={classes.titulos_div}>
              Cantidad de días para el cumplimiento total del acuerdo
            </p>
            <div className={classes.div_cantidad_dias}>
              <input
                type="text"
                defaultValue={cantidad_dias_para_cumplimiento}
                onBlur={(event) => {
                  setCantidad_dias_para_cumplimiento(event.target.value);
                  setGuardado(false);
                }}
              />
              <button className={classes.check_btn}>
                <img className={classes.check} src={check} alt="" />
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.titulos_div}>Cumplimiento en cuotas</p>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="si"
                checked={cumplimiento_cuotas_bool}
                onChange={(event) => {
                  setCumplimiento_cuotas_bool(true);
                  handleCheckboxChangeCumplimientoCuotas(event);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="no"
                checked={!cumplimiento_cuotas_bool}
                onChange={(event) => {
                  setCumplimiento_cuotas_bool(false);
                  handleCheckboxChangeCumplimientoCuotas(event);
                }}
              />
              <span>No</span>
            </div>
          </div>
          {cumplimiento_cuotas_bool ? (
            <div>
              <p className={classes.titulos_div}>Cantidad de cuotas</p>
              <div className={classes.div_cantidad_dias}>
                <input
                  type="text"
                  defaultValue={cantidad_cuotas}
                  ref={cuotasRef}
                  onBlur={(event) => {
                    setCantidad_cuotas(event.target.value);
                  }}
                />{" "}
                <button
                  className={classes.check_btn}
                  onClick={handleCuotasButtonClick}
                >
                  <img className={classes.check} src={check} alt="" />
                </button>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {cumplimiento_cuotas_bool && (
          <div>
            {cantidad_cuotas > 0 && (
              <div>
                {Array.from({ length: cantidad_cuotas }).map((_, index) => {
                  return (
                    <div key={"cuota_" + index}>
                      <p>Cuota {index + 1}</p>
                      <input
                        type="date"
                        onBlur={(event) => {
                          let newDate = new Date(event.target.value + "T00:00");
                          let newTimestamp = Timestamp.fromDate(newDate);
                          let helperArray = [...cuotas];
                          helperArray[index] = newTimestamp;
                          setCuotas(helperArray);
                          setGuardado(false);
                        }}
                        defaultValue={handleDate(cuotas[index])
                          .split("-")
                          .reverse()
                          .join("-")}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className={classes.div_acciones_mediador}>
          <p className={classes.titulos_div}>Acciones del mediador</p>
          <div className={classes.div_inputs_accion}>
            <div>
              {" "}
              <input type="date" ref={fechaAccionRef} />
            </div>
            <div>
              {" "}
              <select id="tipo-accion">
                <option disabled value="">
                  - Seleccionar vía -
                </option>
                <option value="Llamada">Llamada</option>
                <option value="Whatsapp">Whatsapp</option>
                <option value="Visita">Visita</option>
                <option value="Otra">Otra</option>
              </select>
            </div>
            <div className={classes.div_boton_agregar_accion}>
              <button
                className={classes.boton_agregar_accion}
                onClick={handleAgregarAccionClick}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div>
          {/* Acciones ya existentes */}
          {acciones_mediador.map((accion, index) => (
            <div
              className={classes.div_accion_seguimiento}
              key={`${index}-${accion.fecha}-${accion.tipo}`}
            >
              <p className={classes.titulos_div}>
                Acción Nº {index + 1} - {accion.fecha} - {accion.tipo}
              </p>
              <p className={classes.titulos_div}>Observaciones acción</p>
              <textarea
                className={classes.textAreaDiv}
                defaultValue={accion.observaciones}
                cols="35"
                rows="3"
                onBlur={(event) => {
                  accion.observaciones = event.target.value;
                }}
              ></textarea>
              <div className={classes.guardar_accion_div}>
                <button
                  className={classes.guardar_accion_btn}
                  onClick={() => {
                    let handleArray = [...acciones_mediador];
                    handleArray[index] = {
                      fecha: accion.fecha,
                      tipo: accion.tipo,
                      observaciones: accion.observaciones,
                    };
                    setAcciones_mediador(handleArray);
                    updateSeguimiento(seguimientoData);
                    console.log(handleArray);
                    notify_accion();
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </div>
          ))}
          {/* Acciones a agregar */}
          {acciones.map((accion, index) => (
            <div
              className={classes.div_accion_seguimiento}
              key={`${index}-${accion.fecha}-${accion.tipo}`}
            >
              <p className={classes.titulos_div}>
                Acción Nº {index + 1 + acciones_mediador.length} -{" "}
                {accion.fecha} - {accion.tipo}
              </p>
              <p className={classes.titulos_div}>Observaciones acción</p>
              <textarea
                cols="35"
                rows="3"
                onBlur={(event) => {
                  accion.observaciones = event.target.value;
                }}
              ></textarea>
              <div className={classes.guardar_accion_div}>
                <button
                  className={classes.guardar_accion_btn}
                  onClick={() => {
                    let handleArray = [...acciones_mediador];
                    handleArray[index + acciones_mediador.length] = {
                      fecha: accion.fecha,
                      tipo: accion.tipo,
                      observaciones: accion.observaciones,
                    };
                    setAcciones_mediador(handleArray);
                    updateSeguimiento(seguimientoData);
                    let secondHandlerArray = [...acciones_mediador];
                    setAcciones(
                      acciones.filter((accion) => accion.index !== index)
                    );
                    // acciones =  acciones.filter(accion => accion.index !== index);
                    console.log(handleArray);
                    notify_accion();
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <div>
          <p className={classes.titulos_div}>Cumple acuerdo*</p>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={cumple_acuerdo === "Sí totalmente"}
              onChange={(event) => {
                if (event.target.checked) {
                  setCumple_acuerdo("Sí totalmente");
                } else {
                  setCumple_acuerdo("");
                }
              }}
            />
            <span>Sí, totalmente</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={cumple_acuerdo === "Sí parcialmente -Víctima conforme-"}
              onChange={(event) => {
                if (event.target.checked) {
                  setCumple_acuerdo("Sí parcialmente -Víctima conforme-");
                } else {
                  setCumple_acuerdo("");
                }
              }}
            />
            <span>Sí, parcialmente -Víctima conforme-</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={
                cumple_acuerdo === "Sí parcialmente -Víctima disconforme-"
              }
              onChange={(event) => {
                if (event.target.checked) {
                  setCumple_acuerdo("Sí parcialmente -Víctima disconforme-");
                } else {
                  setCumple_acuerdo("");
                }
              }}
            />
            <span>Sí, parcialmente -Víctima disconforme-</span>
          </div>
          <div className={classes.checkWithOption}>
            <input
              type="checkbox"
              checked={cumple_acuerdo === "No"}
              onChange={(event) => {
                if (event.target.checked) {
                  setCumple_acuerdo("No");
                } else {
                  setCumple_acuerdo("");
                }
              }}
            />
            <span>No</span>
          </div>
        </div>
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.titulos_div}>
              Acuerdo requiere firma o ratificación
            </p>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="si"
                checked={requiere_firmas_ratificacion_bool}
                onChange={() => {
                  setRequiere_firmas_ratificacion_bool(true);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="no"
                checked={!requiere_firmas_ratificacion_bool}
                onChange={() => {
                  setRequiere_firmas_ratificacion_bool(false);
                }}
              />
              <span>No</span>
            </div>
          </div>
          {requiere_firmas_ratificacion_bool ? (
            <div>
              <p className={classes.titulos_div}>
                Se realiza ratificación entre las partes
              </p>
              <div className={classes.checkWithOption}>
                <input
                  type="checkbox"
                  checked={se_realizan_firmas_bool}
                  onChange={(event) => {
                    setSe_realizan_firmas_bool(event.target.checked);
                  }}
                />
                <span>Sí</span>
              </div>
              <div className={classes.checkWithOption}>
                <input
                  type="checkbox"
                  checked={!se_realizan_firmas_bool}
                  onChange={(event) => {
                    setSe_realizan_firmas_bool(!event.target.checked);
                  }}
                />
                <span>No</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.dosPartes}>
          <div>
            <p className={classes.titulos_div}>
              Se genera acta de cumplimiento del mediador
            </p>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="si"
                checked={acta_cumplimiento_mediador_bool}
                onChange={(event) => {
                  setActa_cumplimiento_mediador_bool(event.target.checked);
                }}
              />
              <span>Sí</span>
            </div>
            <div className={classes.checkWithOption}>
              <input
                type="checkbox"
                value="no"
                checked={!acta_cumplimiento_mediador_bool}
                onChange={(event) => {
                  setActa_cumplimiento_mediador_bool(!event.target.checked);
                }}
              />
              <span>No</span>
            </div>
          </div>
        </div>
        <div>
          {se_realizan_firmas_bool ? (
            <div>
              <p className={classes.titulos_div}>
                Documento de firma/ratificación del acuerdo
              </p>
              <label htmlFor="file-input" className={classes.fileInputLabel}>
                Subir Documento Ratificación
              </label>
              <input
                type="file"
                id="file-input"
                className={classes.fileInput}
                onChange={(e) => {
                  e.target.nextElementSibling.innerText =
                    e.target.files[0].name;
                  setFileRatificacion(e.target.files[0]);
                }}
              />
              <span className={classes.fileName}></span>
            </div>
          ) : (
            <div></div>
          )}
          {acta_cumplimiento_mediador_bool ? (
            <div>
              <p className={classes.titulos_div}>
                Acta de Cumplimiento Mediador
              </p>
              <label htmlFor="file-input1" className={classes.fileInputLabel}>
                Subir Acta de Cumplimiento Mediador
              </label>
              <input
                type="file"
                id="file-input1"
                className={classes.fileInput1}
                onChange={(e) => {
                  e.target.nextElementSibling.innerText =
                    e.target.files[0].name;
                  setFileActaCumplimiento(e.target.files[0]);
                }}
              />
              <span className={classes.fileName}></span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          <p className={classes.titulos_div}>
            Observaciones Generales Seguimiento
          </p>
          <textarea
            cols="40"
            rows="10"
            defaultValue={observaciones_generales_seguimiento}
            onBlur={(event) => {
              setObservaciones_generales_seguimiento(event.target.value);
            }}
          ></textarea>
        </div>
        {/*  <div className={classes.div_btn_guardar}>
          <button className={classes.boton_seguimiento}>GUARDAR</button>
        </div>
        <div className={classes.dosPartes}>
          <div>
            <p>
              Término de esta etapa <span>19 de abril</span>
            </p>
            <p>
              Derivación del caso a Fiscalía <span>21 de Abril</span>
            </p>
          </div>
          <div className={classes.div_btn_paso_a_cierre}>
            <button className={classes.boton_seguimiento} onClick={() => {}}>
              PASAR A ETAPA DE CIERRE
            </button>
          </div>
        </div> */}
        <div className={classes.div_btn_paso_a_cierre}>
          <button
            className={classes.boton_seguimiento}
            onClick={async () => {
              setMostrar_modal_pasar_a_cierre(true);
            }}
          >
            PASAR A ETAPA DE CIERRE
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormularioSeguimiento;
