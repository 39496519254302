import { useEffect, useState } from "react";
import classes from "./Mediacion.module.css";
import {
  addSeguimiento,
  updateMediacion,
  archivar,
} from "../../firebase/firebasePostData";
import MediacionSesion from "./MediacionSesion";
import save from "../../img/icono_saved.png";
import attach from "../../img/icono_attach.png";
import right_arrow from "../../img/right_arrow.png";
import black_clip from "../../img/black_clip.png";
import download from "../../img/download.png";
import { Drawer } from "antd";
import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { postStorage } from "../../firebase/firebaseStorage";

function FormularioMediacion(props) {
  // *****************************************
  // Funciones, Hooks y States para Subir Archivo a Storage

  // const [uploadeableFileDocSeguimiento, setUploadeableFileDocSeguimiento] =
  //   useState("");
  // const [file, setFile] = useState("");
  let file;
  let uploadeableFileDocSeguimiento;
  const [fileNuevo, setFileNuevo] = useState("");

  const [adjuntos_mediacion, setAdjuntos_mediacion] = useState(
    props.caso.adjuntos_mediacion ? props.caso.adjuntos_mediacion : []
  );

  const [nombreNuevoArchivo, setNombreNuevoArchivo] = useState("");
  const historicoRef = props.caso.historicoRef;

  const [guardado, setGuardado] = useState(false);

  async function readFile(file) {
    if (file !== "") {
      return new Promise((res, rej) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", (e) => res(e.target.result));
        reader.addEventListener("error", rej);
        reader.readAsArrayBuffer(file);
      });
    }
  }

  async function main(file) {
    if (file !== "") {
      let fileArrayBuffer = new Uint8Array(await readFile(file));
      uploadeableFileDocSeguimiento = fileArrayBuffer;
      //setUploadeableFile(fileArrayBuffer);
      return true;
    } else {
      console.log("No hay archivo para leer");
    }
  }

  // useEffect(() => {
  //   const uploadDocSeguimiento = document.querySelector("#file-input-documento-seguimiento");

  //   function handleUploadChange(e) {
  //     setFile(e.target.files[0]);
  //   }

  //   uploadDocSeguimiento.addEventListener("change", handleUploadChange);

  //   return () => {
  //     uploadDocSeguimiento.removeEventListener("change", handleUploadChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   main(file).then(() => {
  //     setUploadeableFileDocSeguimiento(uploadeableFileDocSeguimiento);
  //   });
  // }, [file]);

  const id = props.caso ? props.caso.id : "";
  const [ruc, setRuc] = useState(props.caso ? props.caso.ruc : "");
  const [consentimiento_victima, setConsentimientoVictima] = useState(
    props.caso.consentimiento_victima ? props.caso.consentimiento_victima : ""
  );
  const [consentimiento_ofensor, setConsentimientoOfensor] = useState(
    props.caso.consentimiento_ofensor ? props.caso.consentimiento_ofensor : ""
  );
  const [delito, setDelito] = useState(
    props.caso.delito ? props.caso.delito : ""
  );
  const [estado_actual_mediacion, setEstadoActualMediacion] = useState(
    props.caso.estado_actual_mediacion
      ? props.caso.estado_actual_mediacion
      : "Sin información"
  );
  const [fecha_inicio_mediacion, setFechaInicioMediacion] = useState(
    props.caso.fecha_inicio_mediacion ? props.caso.fecha_inicio_mediacion : ""
  );
  const [sesiones, setSesiones] = useState(
    props.caso.sesiones ? props.caso.sesiones : []
  );
  const [fecha_devolucion_fiscalia, setFechaDevolucionFiscalia] = useState(
    props.caso.fecha_devolucion_fiscalia
      ? props.caso.fecha_devolucion_fiscalia
      : ""
  );
  const [acuerdo_ambos_firman_bool, setAcuerdoAmbosFirman] = useState(
    props.caso.acuerdo_ambos_firman_bool
      ? props.caso.acuerdo_ambos_firman_bool
      : false
  );
  const [acuerdo_requiere_seguimiento_bool, setAcuerdoRequiereSeguimiento] =
    useState(
      props.caso.acuerdo_requiere_seguimiento_bool
        ? props.caso.acuerdo_requiere_seguimiento_bool
        : false
    );
  const [ofensores, setOfensores] = useState(
    props.caso.ofensores ? props.caso.ofensores : ""
  );
  const [victimas, setVictimas] = useState(
    props.caso.victimas ? props.caso.victimas : ""
  );

  const [fecha_termino_mediacion, setFechaTerminoMediacion] = useState(
    props.caso.fecha_termino_mediacion ? props.caso.fecha_termino_mediacion : ""
  );
  const [documento_seguimiento, setDocumentoSeguimiento] = useState(
    props.caso.documento_seguimiento ? props.caso.documento_seguimiento : ""
  );
  const [observaciones_seguimiento, setObservacionesSeguimiento] = useState(
    props.caso.observaciones_seguimiento
      ? props.caso.observaciones_seguimiento
      : ""
  );
  const [fecha_acuerdo, setFechaAcuerdo] = useState(
    props.caso.fecha_acuerdo ? props.caso.fecha_acuerdo : ""
  );
  const [tipo_acuerdo, setTipoAcuerdo] = useState(
    props.caso.tipo_acuerdo ? props.caso.tipo_acuerdo : ""
  );

  const [mediador_asignado, setMediador_asignado] = useState(
    props.caso ? props.caso.mediador_asignado : ""
  );

  const [comediador_asignado, setComediador_asignado] = useState(
    props.caso ? props.caso.comediador_asignado : ""
  );

  const ingresoRef = props.caso.ingresoRef;
  const premediacionRef = props.caso.premediacionRef;

  useEffect(() => {
    sesiones.map((sesion) => {
      if (sesion.resultados_sesion === "Cierre con acuerdo y seguimiento") {
        setAcuerdoRequiereSeguimiento(true);
      }
      return true;
    });
  }, [sesiones]);

  const agregarSesion = () => {
    const nuevaSesion = {
      fecha_sesion_mediacion: "dd-mm-yyyy",
      asistentes_mediacion: "",
      tipo_sesion_mediacion: "",
      existe_asesor_legal_bool: false,
      tipo_asesor_legal: "",
      observaciones_sesion_mediacion: "",
      resultados_sesion: "",
      tipo_acuerdo_mediacion: "",
      documento_acuerdo_mediacion: "",
      fecha_proxima_sesion_mediacion: "dd-mm-yyyy",
    };
    setSesiones([...sesiones, nuevaSesion]);
  };

  let mediacionData = {
    id: id,
    ruc: ruc,
    delito: delito,
    adjuntos_mediacion: adjuntos_mediacion,
    consentimiento_victima: consentimiento_victima,
    consentimiento_ofensor: consentimiento_ofensor,
    estado_actual_mediacion: estado_actual_mediacion,
    fecha_inicio_mediacion: fecha_inicio_mediacion,
    sesiones: sesiones,
    fecha_devolucion_fiscalia: fecha_devolucion_fiscalia,
    acuerdo_ambos_firman_bool: acuerdo_ambos_firman_bool,
    acuerdo_requiere_seguimiento_bool: acuerdo_requiere_seguimiento_bool,
    fecha_termino_mediacion: fecha_termino_mediacion,
    documento_seguimiento: documento_seguimiento,
    tipo_acuerdo: tipo_acuerdo,
    observaciones_seguimiento: observaciones_seguimiento,
    victimas: victimas,
    ofensores: ofensores,
    fecha_acuerdo: fecha_acuerdo,
    ingresoRef: ingresoRef,
    premediacionRef: premediacionRef,
    historicoRef: historicoRef,
    mediador_asignado: mediador_asignado,
    comediador_asignado: comediador_asignado,
  };

  let victima =
    props.caso.victimas.length > 0
      ? props.caso.victimas.length > 1
        ? "Múltiple"
        : props.caso.victimas[0].nombres +
          " " +
          props.caso.victimas[0].apellidos
      : "Sin información";

  let ofensor =
    props.caso.ofensores.length > 0
      ? props.caso.ofensores.length > 1
        ? "Múltiple"
        : props.caso.ofensores[0].nombres +
          " " +
          props.caso.ofensores[0].apellidos
      : "Sin información";

  let participantes = [...victimas, ...ofensores];

  function participantesMediacion(participantes) {
    let datosParticipantes = [];
    for (let i = 0; i < participantes.length; i++) {
      let participante =
        participantes[i].nombres + " " + participantes[i].apellidos;
      datosParticipantes.push(participante);
    }
    return datosParticipantes;
  }

  function handleDownload(url, filename) {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  }

  function mediacionResult(tipo_acuerdo) {
    switch (tipo_acuerdo) {
      case "Cierre sin acuerdo":
        return <div>Devolver a fiscalía</div>;
      case "Cierre con acuerdo y seguimiento":
        return <div>Avanzar a seguimiento</div>;
      case "Cierre con acuerdo cumplido":
        return <div>Avanzar a cierre</div>;
      default:
        return <div></div>;
    }
  }

  // STATES Y FUNCIONES DRAWER

  const [visible, setVisible] = useState(false);
  const [
    mostrar_modal_pasar_a_seguimiento,
    setMostrar_modal_pasar_a_seguimiento,
  ] = useState(false);
  const [mostrar_modal_derivar_fiscalia, setMostrar_modal_derivar_fiscalia] =
    useState(false);
  const [mostrar_modal_guardar, setMostrar_modal_guardar] = useState(false);

  const notify = () => {
    toast.success("Datos Mediación guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const paso_a_fiscalia = () => {
    toast.success("Caso derivado a Fiscalía", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const documento_agregado = () => {
    toast.success(
      "Documento agregado con éxito, recuerda guardar el caso para mantener estos cambios",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const paso_a_seguimiento = () => {
    toast.success("Caso pasa a fase de Seguimiento con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const archivo_adjunto_exitoso = () => {
    toast.success("Archivo subido con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const archivo_adjunto_fallido = () => {
    toast.warning(
      "Problemas con la subida del archivo. Por favor, inténtelo nuevamente",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const derivado = () => {
    toast.success("Caso fue archivado exitosamente", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div
        className={classes.save}
        onClick={async () => {
          setMostrar_modal_guardar(true);
        }}
      >
        <img className={classes.saveIcon} src={save} alt="" />
      </div>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <img className={classes.attachIcon} src={attach} alt="" />
      </div>
      <div className={[classes.encabezado, classes.border1].join(" ")}>
        MEDIACIÓN
      </div>
      <div className={[classes.infoInicial]}>
        <div className={[classes.infoInicial_columna1]}>
          <div className={[classes.etiquetaCaso]}>
            <p>
              CASO: <span>{ruc}</span>
            </p>
          </div>
          <div>
            <p>
              <strong>Estado actual </strong>
              <span className={[classes.etiquetaEstado]}>
                {estado_actual_mediacion}
              </span>
            </p>
          </div>
          <div className={classes.flex_row}>
            <button
              className={`${
                estado_actual_mediacion !== "Cierre sin acuerdo" &&
                estado_actual_mediacion !== "Cierre con acuerdo cumplido" &&
                estado_actual_mediacion !== "Frustrada por víctima" &&
                estado_actual_mediacion !== "Frustrada por ofensor" &&
                estado_actual_mediacion !== "Frustrada por ambas partes"
                  ? classes.boton_sesion
                  : classes.boton_sesion_desactivado
              }`}
              onClick={agregarSesion}
            >
              +
            </button>
            <p
              className={`${
                estado_actual_mediacion !== "Cierre sin acuerdo" &&
                estado_actual_mediacion !== "Cierre con acuerdo cumplido" &&
                estado_actual_mediacion !== "Frustrada por víctima" &&
                estado_actual_mediacion !== "Frustrada por ofensor" &&
                estado_actual_mediacion !== "Frustrada por ambas partes"
                  ? ""
                  : classes.gray_font
              }`}
            >
              Agregar Sesión
            </p>
          </div>
        </div>
        <div className={[classes.infoInicial_columna2]}>
          <div>
            <div
              className={classes.consentimientoMediacion}
              onClick={() => {
                handleDownload(
                  consentimiento_victima,
                  `Consentimiento${victima}.pdf`
                );
              }}
            >
              <img className={classes.blackClip} src={black_clip} alt="" />
              <p>Consentimiento: {victima}</p>
              <img className={classes.download} src={download} alt="" />
            </div>
          </div>
          <div>
            <div
              className={classes.consentimientoMediacion}
              onClick={() => {
                handleDownload(
                  consentimiento_ofensor,
                  `Consentimiento${ofensor}.pdf`
                );
              }}
            >
              <img className={classes.blackClip} src={black_clip} alt="" />
              <p>Consentimiento: {ofensor}</p>
              <img className={classes.download} src={download} alt="" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      {props.modal_cierre_visible == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>CERRAR FORMULARIO</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el formulario, recuerda que cualquier
              cambio que no hayas guardado se perderá. Por favor, asegúrate de
              haber guardado tus cambios.
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                props.setModal_cierre_visible(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Seguir editando
            </button>
            <button
              onClick={async () => {
                console.log(mediacionData);
                updateMediacion(mediacionData);
                notify();
                props.closeModal();
              }}
              className={classes.btn_guardar_en_form}
            >
              Guardar y salir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                props.closeModal();
              }}
            >
              Salir sin guardar
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_derivar_fiscalia == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>DERIVAR A FISCALÍA</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de cerrar el caso de Mediación y derivar a Fiscalía,
              ¿estás seguro de proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                updateMediacion(mediacionData);
                archivar(mediacionData, "mediacion");
                derivado();
                props.closeModal();
              }}
              className={classes.btn_seguir_en_form}
            >
              Cerrar y derivar caso
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_derivar_fiscalia(false);
              }}
            >
              Seguir editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_pasar_a_seguimiento == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>PASO A SEGUIMIENTO</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de pasar el caso a Seguimiento, ¿estás seguro de
              proceder?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                if (acuerdo_requiere_seguimiento_bool) {
                  updateMediacion(mediacionData);
                  addSeguimiento(mediacionData);
                  paso_a_seguimiento();
                  props.closeModal();
                }
              }}
              className={classes.btn_seguir_en_form}
            >
              Pasar a Seguimiento
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                setMostrar_modal_pasar_a_seguimiento(false);
              }}
            >
              Seguir editando
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {mostrar_modal_guardar == true ? (
        <div className={classes.guardarModalContainer}>
          <div className={classes.encabezadoGuardar}>GUARDAR DATOS</div>
          <div className={classes.enunciado_modal}>
            <p>
              Estás a punto de guardar los datos del formulario, ¿como quieres
              continuar?
            </p>
          </div>
          <div className={classes.botones_modales_verificacion}>
            <button
              onClick={async () => {
                console.log(mediacionData);
                updateMediacion(mediacionData);
                notify();
                setMostrar_modal_guardar(false);
              }}
              className={classes.btn_seguir_en_form}
            >
              Guardar y seguir
            </button>
            <button
              className={classes.btn_cerrar_form}
              onClick={async () => {
                console.log(mediacionData);
                updateMediacion(mediacionData);
                notify();
                props.closeModal();
              }}
            >
              Guardar y salir
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div>
        <Drawer
          open={visible}
          title="Archivos Adjuntos Mediación"
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className={classes.div_principal_drawer}>
            {/* Archivos provenientes de Database */}
            <div>
              <div>
                <p>Consentimiento {victima}</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      consentimiento_victima,
                      `Consentimiento_${victima}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
              <div>
                <p>Consentimiento {ofensor}</p>
                <button
                  className={classes.btn_descarga_drawer}
                  onClick={() => {
                    handleDownload(
                      consentimiento_ofensor,
                      `Consentimiento_${ofensor}.pdf`
                    );
                  }}
                >
                  Descargar
                </button>
              </div>
              {documento_seguimiento != "" ? (
                <div>
                  <p>Documento seguimiento acuerdo</p>
                  <button
                    className={classes.btn_descarga_drawer}
                    onClick={() => {
                      handleDownload(
                        documento_seguimiento,
                        `Documento_Seguimiento_Caso_${ruc}.pdf`
                      );
                    }}
                  >
                    Descargar
                  </button>
                </div>
              ) : (
                // Aún no existe el adjunto de acuerdo seguimiento
                <div className={classes.div_drawer_subir}>
                  <p>Adjuntar Documento seguimiento acuerdo </p>
                  <label
                    htmlFor="file-input-documento-seguimiento"
                    className={classes.fileInputLabel1}
                  >
                    <img
                      className={classes.attach_drawer}
                      src={attach}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file-input-documento-seguimiento"
                    className={classes.fileInput}
                    onChange={(e) => {
                      e.target.nextElementSibling.innerText =
                        e.target.files[0].name;
                      file = e.target.files[0];
                      //setFile(e.target.files[0]);
                      console.log(file);
                      main(file);
                    }}
                  />
                  <span className={classes.fileName}></span>
                  <button
                    className={classes.btn_descarga_drawer}
                    style={{ marginTop: "1rem" }}
                    onClick={async () => {
                      try {
                        let url;
                        console.log(file);
                        console.log(uploadeableFileDocSeguimiento);
                        if (
                          uploadeableFileDocSeguimiento !== "" &&
                          uploadeableFileDocSeguimiento !== undefined
                        ) {
                          await postStorage(
                            "mediacion/" + file.name,
                            uploadeableFileDocSeguimiento
                          ).then((archivoRutaDescarga) => {
                            url = archivoRutaDescarga;
                            setDocumentoSeguimiento(url);
                          });
                        }
                        archivo_adjunto_exitoso();
                      } catch (error) {
                        console.log(error);
                        archivo_adjunto_fallido();
                      }

                      // handleDownload(
                      //   documento_seguimiento,
                      //   `Documento_Seguimiento_Caso_${ruc}.pdf`
                      // );
                    }}
                  >
                    Subir Archivo
                  </button>
                </div>
              )}
              <hr />
              <div className={[`${classes.nuevos_documentos}`]}>
                {adjuntos_mediacion != undefined ? (
                  <div>
                    {adjuntos_mediacion.map((documento, index) => (
                      <div key={index}>
                        <p>{documento.nombre}</p>
                        <button
                          className={classes.btn_descarga_drawer}
                          onClick={() => {
                            handleDownload(
                              documento.archivo,
                              `${documento.nombre}.pdf`
                            );
                          }}
                        >
                          Descargar
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.div_subir_nuevos_documentos}>
                <hr />

                <div className={classes.columna_subir_nuevo}>
                  <p className={classes.subtitulo}>Subir Nuevo Documento</p>
                  <label
                    htmlFor="file-nuevo"
                    className={classes.fileInputLabel}
                  >
                    Seleccionar
                  </label>
                  <input
                    className={classes.fileInput}
                    type="file"
                    id="file-nuevo"
                    onChange={(e) => {
                      e.target.nextElementSibling.innerText =
                        e.target.files[0].name;
                      setFileNuevo(e.target.files[0]);
                      console.log(fileNuevo);
                    }}
                  />
                  <span className={classes.fileName} id="span_subir"></span>
                  <input
                    type="text"
                    placeholder="Ingrese nombre documento"
                    value={nombreNuevoArchivo}
                    onChange={(e) => setNombreNuevoArchivo(e.target.value)}
                  />
                  <button
                    className={classes.subir_nuevo_btn}
                    onClick={async () => {
                      if (fileNuevo !== "") {
                        console.log(fileNuevo);
                        let urlNuevo;
                        await postStorage(
                          "mediacion/" + fileNuevo.name,
                          fileNuevo
                        ).then((archivoRutaDescarga) => {
                          urlNuevo = archivoRutaDescarga;

                          setAdjuntos_mediacion([
                            ...adjuntos_mediacion,
                            {
                              nombre: nombreNuevoArchivo,
                              archivo: urlNuevo,
                            },
                          ]);
                        });
                        updateMediacion(mediacionData);

                        documento_agregado();
                      }
                    }}
                  >
                    Subir documento
                  </button>
                </div>
              </div>
            </div>

            {/* <div>
              <p>Subir Nuevo Documento</p>
              <input type="file" />
              <input type="text" placeholder="Ingrese nombre documento" />
              <button>Subir documento</button>
            </div> */}
          </div>
        </Drawer>
        {sesiones.length > 0
          ? sesiones.map((number, index) => {
              return (
                <div key={"mediacion_" + index}>
                  <MediacionSesion
                    participantes={participantesMediacion(participantes)}
                    data={sesiones[index]}
                    fullArray={sesiones}
                    index={index}
                    setSesiones={setSesiones}
                    setEstadoActualMediacion={setEstadoActualMediacion}
                    setDocumentoSeguimiento={setDocumentoSeguimiento}
                    setFechaAcuerdo={setFechaAcuerdo}
                    setTipoAcuerdo={setTipoAcuerdo}
                  ></MediacionSesion>
                </div>
              );
            })
          : ""}
      </div>
      {mediacionResult(sesiones.slice(-1))}
      <div className={classes.flex_row_botones}>
        <div>
          <button
            className={`${
              estado_actual_mediacion == "Cierre con acuerdo y seguimiento"
                ? classes.boton_mediacion
                : classes.boton_mediacion_desactivado
            }`}
            onClick={() => {
              console.log(mediacionData);
              // if (acuerdo_requiere_seguimiento_bool) {
              //   updateMediacion(mediacionData);
              //   addSeguimiento(mediacionData);
              //   props.closeModal();
              // }
              setMostrar_modal_pasar_a_seguimiento(true);
            }}
          >
            PASAR A SEGUIMIENTO{" "}
            <span>
              <img className={classes.arrowIcon} src={right_arrow} alt="" />
            </span>
          </button>
        </div>
        <div>
          <button
            className={`${
              estado_actual_mediacion === "Cierre sin acuerdo" ||
              estado_actual_mediacion === "Cierre con acuerdo cumplido" ||
              estado_actual_mediacion === "Frustrada por víctima" ||
              estado_actual_mediacion === "Frustrada por ofensor" ||
              estado_actual_mediacion === "Frustrada por ambas partes"
                ? classes.boton_mediacion
                : classes.boton_mediacion_desactivado
            }`}
            onClick={async () => {
              setMostrar_modal_derivar_fiscalia(true);
            }}
          >
            DERIVAR A FISCALÍA{" "}
            <span>
              <img className={classes.arrowIcon} src={right_arrow} alt="" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormularioMediacion;
