const etapas = [
  "Ingreso",
  "Premediación",
  "Mediación",
  "Seguimiento",
  "Cierre",
];

const delitos = [
  "AMENAZAS",
  "ESTAFAS",
  "HURTOS",
  "APROPIACIÓN INDEBIDA",
  "CUASIDELITO DE LESIONES",
  "CUASIDELITO DE HOMICIDIO",
  "CUASIDELITO VEHÍCULO MOTORIZADO LEY TRÁNSITO",
  "DAÑOS SIMPLES",
  "LESIONES LEVES",
  "LESIONES MENOS GRAVES",
  "LESIONES GRAVES",
  "OTROS",
];

const comunas = [
  "Santiago",
  "Estación Central",
  "Quinta Normal",
  "Independencia",
  "Recoleta",
  "Cerro Navia",
  "Lo Prado",
  "Renca",
  "Conchalí",
  "Quilicura",
  "Huechuraba",
  "Colina",
  "Lampa",
  "Til Til",
];

const derivadores = ["Fiscalía", "Fiscal a cargo", "Defensor", "Juez", "Otro"];

const devolucion = [
  "Faltan antecedentes de la víctima",
  "Faltan antecedentes del ofensor",
  "No cumple requisito ingreso a piloto",
  "Otro",
];

const mediadores = [
  "Mónica Valdés",
  "Estefanie Villalobos",
  "Natalia Droguett",
  "Ana Fuentes",
  "Carolina Contreras"
];

const mediadoresUid = {
  "No asigna comediador": "",
  "Mónica Valdés": "sQVI72Tp7cS5RJVoGefiqMkjudF3",
  "Estefanie Villalobos": "xDG6diLkjQTFuNuZC7cKGxKePmI3",
  "Natalia Droguett": "hP19Bg0wCNg5mTvaQ4Dw7pUgDUo2",
  "Ximena Ríos": "roF6HA9r8PR0EJudmHfyybss3QW2",
  "Ana Fuentes": "IVviEGnd7AgO6L3k2cELdIhEtKl2",
  "Carolina Contreras": "YM48CQoxrsS9GO3UeKUolfa1t3C3"
};

const comediadores = ["No asigna comediador", ...mediadores];

const regiones = [
  "Arica y Parinacota",
  "Tarapacá",
  "Antofagasta",
  "Atacama",
  "Coquimbo",
  "Valparaíso",
  "Metropolitana",
  "O'Higgins",
  "Maule",
  "Ñuble",
  "Bío Bío",
  "La Araucanía",
  "Los Ríos",
  "Los Lagos",
  "Aysén",
  "Magallanes",
];

const regionesYcomunas = {
  default: [],
  "Arica y Parinacota": ["Arica", "Camarones", "Putre", "General Lagos"],
  Tarapacá: [
    "Iquique",
    "Alto Hospicio",
    "Pozo Almonte",
    "Camiña",
    "Colchane",
    "Huara",
    "Pica",
  ],
  Antofagasta: [
    "Antofagasta",
    "Mejillones",
    "Sierra Gorda",
    "Taltal",
    "Calama",
    "Ollagüe",
    "San Pedro de Atacama",
    "Tocopilla",
    "María Elena",
  ],
  Atacama: [
    "Copiapó",
    "Caldera",
    "Tierra Amarilla",
    "Chañaral",
    "Diego de Almagro",
    "Vallenar",
    "Alto del Carmen",
    "Freirina",
    "Huasco",
  ],
  Coquimbo: [
    "La Serena",
    "Coquimbo",
    "Andacollo",
    "La Higuera",
    "Paiguano",
    "Vicuña",
    "Illapel",
    "Canela",
    "Los Vilos",
    "Salamanca",
    "Ovalle",
    "Combarbalá",
    "Monte Patria",
    "Punitaqui",
    "Río Hurtado",
  ],
  Valparaíso: [
    "Valparaíso",
    "Casablanca",
    "Concón",
    "Juan Fernández",
    "Puchuncaví",
    "Quintero",
    "Viña del Mar",
    "Isla de Pascua",
    "Los Andes",
    "Calle Larga",
    "Rinconada",
    "San Esteban",
    "La Ligua",
    "Cabildo",
    "Papudo",
    "Petorca",
    "Zapallar",
    "Quillota",
    "Calera",
    "Hijuelas",
    "La Cruz",
    "Nogales",
    "San Antonio",
    "Algarrobo",
    "Cartagena",
    "El Quisco",
    "El Tabo",
    "Santo Domingo",
    "San Felipe",
    "Catemu",
    "Llaillay",
    "Panquehue",
    "Putaendo",
    "Santa María",
    "Quilpué",
    "Limache",
    "Olmué",
    "Villa Alemana",
  ],
  "O'Higgins": [
    "Rancagua",
    "Codegua",
    "Coinco",
    "Coltauco",
    "Doñihue",
    "Graneros",
    "Las Cabras",
    "Machalí",
    "Malloa",
    "Mostazal",
    "Olivar",
    "Peumo",
    "Pichidegua",
    "Quinta de Tilcoco",
    "Rengo",
    "Requínoa",
    "San Vicente",
    "Pichilemu",
    "La Estrella",
    "Litueche",
    "Marchihue",
    "Navidad",
    "Paredones",
    "San Fernando",
    "Chépica",
    "Chimbarongo",
    "Lolol",
    "Nancagua",
    "Palmilla",
    "Peralillo",
    "Placilla",
    "Pumanque",
    "Santa Cruz",
  ],
  Maule: [
    "Talca",
    "ConsVtución",
    "Curepto",
    "Empedrado",
    "Maule",
    "Pelarco",
    "Pencahue",
    "Río Claro",
    "San Clemente",
    "San Rafael",
    "Cauquenes",
    "Chanco",
    "Pelluhue",
    "Curicó",
    "Hualañé",
    "Licantén",
    "Molina",
    "Rauco",
    "Romeral",
    "Sagrada Familia",
    "Teno",
    "Vichuquén",
    "Linares",
    "Colbún",
    "Longaví",
    "Parral",
    "ReVro",
    "San Javier",
    "Villa Alegre",
    "Yerbas Buenas",
  ],
  Ñuble: [
    "Cobquecura",
    "Coelemu",
    "Ninhue",
    "Portezuelo",
    "Quirihue",
    "Ránquil",
    "Trehuaco",
    "Bulnes",
    "Chillán Viejo",
    "Chillán",
    "El Carmen",
    "Pemuco",
    "Pinto",
    "Quillón",
    "San Ignacio",
    "Yungay",
    "Coihueco",
    "Ñiquén",
    "San Carlos",
    "San Fabián",
    "San Nicolás",
  ],
  "Bío Bío": [
    "Concepción",
    "Coronel",
    "Chiguayante",
    "Florida",
    "Hualqui",
    "Lota",
    "Penco",
    "San Pedro de la Paz",
    "Santa Juana",
    "Talcahuano",
    "Tomé",
    "Hualpén",
    "Lebu",
    "Arauco",
    "Cañete",
    "Contulmo",
    "Curanilahue",
    "Los Álamos",
    "Tirúa",
    "Los Ángeles",
    "Antuco",
    "Cabrero",
    "Laja",
    "Mulchén",
    "Nacimiento",
    "Negrete",
    "Quilaco",
    "Quilleco",
    "San Rosendo",
    "Santa Bárbara",
    "Tucapel",
    "Yumbel",
    "Alto Biobío",
  ],
  "La Araucanía": [
    "Temuco",
    "Carahue",
    "Cunco",
    "Curarrehue",
    "Freire",
    "Galvarino",
    "Gorbea",
    "Lautaro",
    "Loncoche",
    "Melipeuco",
    "Nueva Imperial",
    "Padre las Casas",
    "Perquenco",
    "Pitrufquén",
    "Pucón",
    "Saavedra",
    "Teodoro Schmidt",
    "Toltén",
    "Vilcún",
    "Villarrica",
    "Cholchol",
    "Angol",
    "Collipulli",
    "Curacautín",
    "Ercilla",
    "Lonquimay",
    "Los Sauces",
    "Lumaco",
    "Purén",
    "Renaico",
    "Traiguén",
    "Victoria",
  ],
  "Los Ríos": [
    "Valdivia",
    "Corral",
    "Lanco",
    "Los Lagos",
    "Máfil",
    "Mariquina",
    "Paillaco",
    "Panguipulli",
    "La Unión",
    "Futrono",
    "Lago Ranco",
    "Río Bueno",
  ],
  "Los Lagos": [
    "Puerto Montt",
    "Calbuco",
    "Cochamó",
    "Fresia",
    "FruVllar",
    "Los Muermos",
    "Llanquihue",
    "Maullín",
    "Puerto Varas",
    "Castro",
    "Ancud",
    "Chonchi",
    "Curaco de Vélez",
    "Dalcahue",
    "Puqueldón",
    "Queilén",
    "Quellón",
    "Quemchi",
    "Quinchao",
    "Osorno",
    "Puerto Octay",
    "Purranque",
    "Puyehue",
    "Río Negro",
    "San Juan de la Costa",
    "San Pablo",
    "Chaitén",
    "Futaleufú",
    "Hualaihué",
    "Palena",
  ],
  Aysén: [
    "Coihaique",
    "Lago Verde",
    "Aisén",
    "Cisnes",
    "Guaitecas",
    "Cochrane",
    "O’Higgins",
    "Tortel",
    "Chile Chico",
    "Río Ibáñez",
  ],
  Magallanes: [
    "Punta Arenas",
    "Laguna Blanca",
    "Río Verde",
    "San Gregorio",
    "Cabo de Hornos (Ex Navarino)",
    "AntárVca",
    "Porvenir",
    "Primavera",
    "Timaukel",
    "Natales",
    "Torres del Paine",
  ],
  Metropolitana: [
    "Cerrillos",
    "Cerro Navia",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "Ñuñoa",
    "Pedro Aguirre Cerda",
    "Peñalolén",
    "Providencia",
    "Pudahuel",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "Santiago",
    "San Joaquín",
    "San Miguel",
    "San Ramón",
    "Vitacura",
    "Puente Alto",
    "Pirque",
    "San José de Maipo",
    "Colina",
    "Lampa",
    "TilVl",
    "San Bernardo",
    "Buin",
    "Calera de Tango",
    "Paine",
    "Melipilla",
    "Alhué",
    "Curacaví",
    "María Pinto",
    "San Pedro",
    "Talagante",
    "El Monte",
    "Isla de Maipo",
    "Padre Hurtado",
    "Peñaflor",
  ],
};

const nacionalidades = [
  "Chilena",
  "Venezolana",
  "Peruana",
  "Haitiana",
  "Colombiana",
  "Boliviana",
  "Argentina",
  "Otra",
];

const puebloOriginario = [
  "No pertenece",
  "Aymara",
  "Quechua",
  "Atacameño",
  "Colla",
  "Diaguita",
  "Mapuche",
  "Kawashqar",
  "Yámana",
  "Rapa Nui",
];

const nivelEducacional = [
  "Básica incompleta",
  "Básica completa",
  "Media incompleta",
  "Media completa",
  "Técnica incompleta",
  "Técnica completa",
  "Universitaria incompleta",
  "Universitaria completa",
];

const generos = [
  "Femenino",
  "Masculino",
  "Transfemenino",
  "Transmasculino",
  "No binario",
  "Otro",
];

const estadoCivil = [
  "Soltero",
  "Casado",
  "Divorciado",
  "Separación en proceso",
  "Viudo",
  "Acuerdo de unión civil",
];

const relacionPartes = [
  "Ninguna",
  "Pareja",
  "Familiar",
  "Vecino",
  "Amigo",
  "Colega",
  "Contexto laboral",
  "Miembros de la comunidad educativa",
  "Compañeros de estudios",
  "Arrendatario",
];

/* TODO: Agregar origen del caso 
  Agregar además nombre del derivador, nombre del fiscal

*/
const asesoresLegales = [
  "Privado",
  "Fiscalía",
  "Defensoría",
  "Asesor Mediación",
];
const origen = ["Fiscalía", "Poder Judicial", "Defensoría", "Otro"];
const tipoSesion = ["Individual", "Conjunta", "Indirecta"];
const resultadosSesion = [
  "Agenda nueva sesión",
  "Cierre sin acuerdo",
  "Cierre con acuerdo y seguimiento",
  "Cierre con acuerdo cumplido",
  "Frustrada por víctima",
  "Frustrada por ofensor",
  "Frustrada por ambas partes",
];
const acuerdos = [
  "Disculpas privadas",
  "Disculpas públicas",
  "Pago en dinero",
  "Pago en especies",
  "Restitución o reparación",
  "Otro",
];
const estadosPremediacion = [
  "Sin iniciar",
  "Sin ubicar",
  "Convocado",
  "Entrevistado",
  "Acepta mediación",
  "Rechaza mediación",
];
const canales = ["Telefónico", "Presencial", "Whatsapp", "Correo"];

const reparacion = {
  "2300524141-3": "APROPIACIÓN INDEBIDA",
  "2300535161-8": "APROPIACIÓN INDEBIDA",
  "2300540442-8": "APROPIACIÓN INDEBIDA",
  "2300568994-5": "APROPIACIÓN INDEBIDA",
  "2200442981-1": "ESTAFAS",
  "2300599716-K": "APROPIACIÓN INDEBIDA",
  "2300614104-8": "APROPIACIÓN INDEBIDA",
  "2300614272-9": "APROPIACIÓN INDEBIDA",
  "2300646176-K": "ESTAFAS",
  "2300642950-5": "ESTAFAS",
  "2300642995-5": "ESTAFAS",
  "2300643370-7": "ESTAFAS",
  "2300643371-5": "ESTAFAS",
  "2300646157-3": "ESTAFAS",
  "2300641209-2": "ESTAFAS",
  "2300643240-9": "ESTAFAS",
  "2300643002-3": "ESTAFAS",
  "2300647493-4": "ESTAFAS",
  "2300645062-8": "ESTAFAS",
  "2300643822-9": "APROPIACIÓN INDEBIDA",
  "2300647490-K": "ESTAFAS",
  "2300642295-0": "ESTAFAS",
  "2300647491-8": "APROPIACIÓN INDEBIDA",
  "2300647352-0": "ESTAFAS",
  "2300647338-5": "ESTAFAS",
  "2300647345-8": "ESTAFAS",
  "2300647641-4": "ESTAFAS",
  "2300645417-8": "ESTAFAS",
  "2300655187-4": "ESTAFAS",
  "2300652484-2": "ESTAFAS",
  "2300655208-0": "ESTAFAS",
  "2300655150-5": "ESTAFAS",
  "2300658804-2": "ESTAFAS",
  "2300644685-K": "ESTAFAS",
  "2300662156-2": "ESTAFAS",
  "2300656569-7": "APROPIACIÓN INDEBIDA",
  "2300665543-2": "APROPIACIÓN INDEBIDA",
  "2300671486-2": "ESTAFAS",
  "2300663623-3": "APROPIACIÓN INDEBIDA",
  "2300666408-3": "ESTAFAS",
  "2300662637-8": "APROPIACIÓN INDEBIDA",
  "2300674870-0": "ESTAFAS",
  "2300658632-5": "ESTAFAS",
  "2300670227-9": "ESTAFAS",
  "2300681654-1": "APROPIACIÓN INDEBIDA",
  "2300681646-0": "ESTAFAS",
  "2300681766-1": "ESTAFAS",
  "2300681652-5": "APROPIACIÓN INDEBIDA",
  "2300681651-7": "ESTAFAS",
  "2300681653-3": "ESTAFAS",
  "2300681636-3": "APROPIACIÓN INDEBIDA",
  "2300679677-K": "APROPIACIÓN INDEBIDA",
  "2300681641-K": "ESTAFAS",
  "2300681642-8": "APROPIACIÓN INDEBIDA",
  "2300686130-K": "ESTAFAS",
  "2300694729-8": "ESTAFAS",
  "2300695906-7": "ESTFAS ",
  "2300695913-K": "ESTAFAS",
  "2300701516-K": "APROPIACIÓN INDEBIDA",
  "2300703689-2": "APROPIACIÓN INDEBIDA",
  "2300701224-1": "APROPIACIÓN INDEBIDA",
  "2300703423-7": "ESTAFAS",
  "2300701593-3": "APROPIACIÓN INDEBIDA",
  "2300703428-8": "ESTAFAS",
  "2300700419-2": "APROPIACIÓN INDEBIDA",
  "2300707909-5": "APROPIACIÓN INDEBIDA",
  "2300712595-K": "APROPIACIÓN INDEBIDA",
  "2300712211-K": "ESTAFAS",
  "2300713864-4": "APROPIACIÓN INDEBIDA",
  "2300675993-9": "APROPIACIÓN INDEBIDA",
  "2300695874-5": "ESTAFAS",
  "2300669945-6": "ESTAFAS",
  "2300730889-2": "APROPIACIÓN INDEBIDA",
  "2300730132-4": "APROPIACIÓN INDEBIDA",
  "2300730338-6": "APROPIACIÓN INDEBIDA",
  "2300730134-0": "ESTAFAS",
  "2300737165-9": "ESTAFAS",
  "2300740307-0": "ESTAFAS",
  "2300689232-9": "ESTAFAS",
  "2300743328-K": "ESTAFAS",
  "2300752196-0": "ESTAFAS",
  "2300752221-5": "ESTAFAS",
  "2300757386-3": "ESTAFAS",
  "2300755036-7": "APROPIACIÓN INDEBIDA",
  "2300751765-3": "ESTAFAS",
  "2300743320-4": "ESTAFAS",
  "2300745774-K": "ESTAFAS",
  "2300755034-0": "APROPIACIÓN INDEBIDA",
  "2300749938-8": "APROPIACIÓN INDEBIDA",
  "2300752209-6": "APRPIACION INDEBIDA ",
  "2300761415-2": "APROPIACIÓN INDEBIDA",
  "2300762840-4": "ESTAFAS",
  "2300761772-0": "ESTAFAS",
  "2300761487-K": "ESTAFAS",
  "2300761498-5": "ESTAFAS",
  "2300759245-0": "ESTAFAS",
  "2300769086-K": "ESTAFAS",
  "2300759243-4": "APROPIACIÓN INDEBIDA",
  "2300768759-1": "ESTAFAS",
  "2300699202-1": "ESTAFAS",
  "2300764104-4": "APROPIACIÓN INDEBIDA",
  "2300771663-K": "APROPIACIÓN INDEBIDA",
  "2300768737-0": "APROPIACIÓN INDEBIDA",
  "2300756141-5": "APROPIACIÓN INDEBIDA",
  "2300771642-7": "ESTAFAS",
  "2300771646-K": "ESTAFAS",
  "2300674874-0":"ESTAFAS"
};

export {
  etapas,
  delitos,
  comunas,
  devolucion,
  mediadores,
  comediadores,
  regiones,
  regionesYcomunas,
  nacionalidades,
  puebloOriginario,
  nivelEducacional,
  generos,
  estadoCivil,
  relacionPartes,
  origen,
  tipoSesion,
  asesoresLegales,
  resultadosSesion,
  acuerdos,
  estadosPremediacion,
  canales,
  derivadores,
  mediadoresUid,
  reparacion
};
