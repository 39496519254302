import classes from "./SignInDemo.module.css";
import logo from "../img/logoUnimico.png";
import logoUc from "../img/Logo-TSocial.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, auth } from "../firebase/firebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import { userSignIn } from "../firebase/firebasePostData";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const success = () => {
    toast.success("Inicio de sesión correcto!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errorToast = () => {
    toast.error(
      "Hubo un error con sus credenciales, por favor intente nuevamente",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      let data = {
        email: user.email,
        displayName: user.displayName ? user.displayName : "",
        uid: user.uid
      };
      userSignIn(data);
      success();
      navigate("/");
    }
    if (error) {
      errorToast();
    }
  }, [user, loading, error, navigate]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.cardContainer}>
        <div>
          <img
            className={classes.logo}
            src={logo}
            alt="Logo de Servicio de mediación penal restaurativa"
          />
        </div>
        {/* <div className={classes.iniciarSesion}>
          INICIAR SESIÓN
        </div> */}
        <div className={classes.inputBox}>
          <div className={classes.hintText}>Correo</div>
          <div>
            <input
              className={classes.inputField}
              type="email"
              name="Correo"
              id="mail"
              placeholder="usuario@correo.cl"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.inputBox}>
          <div className={classes.hintText}>Contraseña</div>
          <div>
            <input
              className={classes.inputField}
              type="password"
              name=""
              id="pass"
              placeholder="Contraseña"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.submit}>
          <button
            className={classes.button}
            onClick={async () => {
              console.log(await signIn(email, password));
            }}
          >
            Iniciar sesión
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
