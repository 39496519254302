import CasoHistoricoModal from "../modal/CasoHistoricoModal";
import { useState, useEffect } from "react";
import classes from "./HistoricoRow.module.css";
import moment from "moment";

function HistoricoRow(props) {
  const [modalHistorico, setShowModalHistorico] = useState(false);
  const showModal = () => {
    setShowModalHistorico(true);
  };

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  function ultimaModificacion() {
    if (props.caso.etapa === "Ingreso")
      return handleDate(props.caso.ingresoData.fecha_ultima_modificacion);
    if (props.caso.etapa === "Premediación")
      return handleDate(props.caso.premediacionData.fecha_ultima_modificacion);
    if (props.caso.etapa === "Mediación")
      return handleDate(props.caso.mediacionData.fecha_ultima_modificacion);
    // if (props.caso.etapa === "Seguimiento")
    //   return handleDate(props.caso.seguimientoData.fecha_ultima_modificacion);
    if (props.caso.etapa === "Cierre")
      return handleDate(props.caso.cierreData.fecha_ultima_modificacion);
  }

  const convertirFecha = (fecha) => {
    const timestamp = fecha.seconds * 1000 + fecha.nanoseconds / 1000000; // Convertir el objeto de fecha a milisegundos
    return moment(timestamp);
  };

  let fechaPasoEtapaActual;
  try {
    if (props.caso.etapa === "Ingreso") {
      fechaPasoEtapaActual = convertirFecha(props.caso.fecha_ingreso);
    }
    if (props.caso.etapa === "Premediación") {
      fechaPasoEtapaActual = convertirFecha(props.caso.fecha_paso_premediacion);
    }
    if (props.caso.etapa === "Mediación") {
      fechaPasoEtapaActual = convertirFecha(props.caso.fecha_paso_mediacion);
    }
    if (props.caso.etapa === "Seguimiento") {
      fechaPasoEtapaActual = convertirFecha(
        props.caso.fecha_inicio_seguimiento
      );
    }
    if (props.caso.etapa === "Cierre") {
      fechaPasoEtapaActual = convertirFecha(props.caso.fecha_inicio_cierre);
    }
  } catch {
    console.log(props.caso.ruc)
  }

  const fechaActual = moment();
  const diferenciaDias = fechaActual.diff(fechaPasoEtapaActual, "days");
  console.log(diferenciaDias);

  const borderStyle = {
    border: `2px solid ${
      props.caso.estado === "finalizado"
        ? "purple"
        : diferenciaDias <= 10
        ? "#2cbc81"
        : diferenciaDias > 10 && diferenciaDias <= 20
        ? "#ff7c1b"
        : diferenciaDias > 20
        ? "#ff6767"
        : ""
    }`,
  };

  return (
    <div>
      <div
        className={classes.mainContainer}
        style={borderStyle}
        onClick={showModal}
      >
        <div className={classes.containerRow}>
          <div className={`${classes.row}  ${classes.bold}`}>CASO</div>
          <div className={classes.row}></div>
          <div className={classes.row}></div>
          <div className={classes.row}></div>
          <div className={classes.row}></div>
          <div className={classes.row}></div>
        </div>

        <div className={classes.containerRow}>
          <div className={`${classes.row}  ${classes.bold}`}>
            {props.caso.ingresoData.ruc}
          </div>
          <div className={classes.row}>Delito</div>
          <div className={classes.row}>Mediador</div>
          <div className={classes.row}>Etapa</div>
          <div className={classes.row}>Fecha ingreso</div>
          <div className={classes.row}>Última modificación</div>
        </div>
        <div className={classes.containerRow}>
          <div className={classes.row}>
            {/* {props.caso.etapa !== "Ingreso" ? (
              <div>
                <div>
                  Convocatorias:{" "}
                  {
                    props.caso.premediacionData.actividades.filter(
                      (actividad) => actividad.tipo === "convocatoria"
                    ).length
                  }
                </div>
                <div>
                  Entrevistas:{" "}
                  {
                    props.caso.premediacionData.actividades.filter(
                      (actividad) => actividad.tipo === "entrevista"
                    ).length
                  }
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
          <div className={`${classes.row2} ${classes.value_box}`}>
            {props.caso.ingresoData.delito}{" "}
          </div>
          <div className={`${classes.row2} ${classes.value_box}`}>
            {props.caso.ingresoData.mediador_asignado
              ? props.caso.ingresoData.mediador_asignado
              : "Sin asignar"}
          </div>
          <div className={`${classes.row2} ${classes.value_box}`}>
            {props.caso.etapa ? props.caso.etapa : "Sin asignar"}
          </div>
          <div className={`${classes.row2} ${classes.value_box}`}>
            {handleDate(props.caso.ingresoData.fecha_ingreso)}
          </div>
          <div className={`${classes.row2} ${classes.value_box}`}>
            {
              handleDate(props.caso.fecha_ultima_modificacion)
              /* ultimaModificacion() */
            }
          </div>
        </div>
      </div>
      <div className={classes.modalContainer}>
        {modalHistorico ? (
          <CasoHistoricoModal
            setShowModalHistorico={setShowModalHistorico}
            setIsCargandoAllCasos={props.setIsCargandoAllCasos}
            key={
              props.caso.ingresoData
                ? props.caso.ingresoData.ruc
                : "ingresoEnProcesoCard"
            }
            caso={props.caso}
          ></CasoHistoricoModal>
        ) : null}
      </div>
    </div>
  );
}

export default HistoricoRow;
