import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import CasosMain from "./pages/CasosMain";
import { ToastContainer, toast } from "react-toastify";
import SignIn from "./pages/SignInDemo.js";
import CasosCerradosProv from "./pages/CasosCerradosProv.js";
import "react-toastify/dist/ReactToastify.css";
import Estadistica from "./pages/Estadistica";
import Admin from "./pages/Admin";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<SignIn></SignIn>} />
        <Route
          path="/"
          element={
            <Layout>
              <CasosMain></CasosMain>
            </Layout>
          }
        />
        <Route
          path="/historicos"
          element={
            <Layout>
              <CasosCerradosProv></CasosCerradosProv>
            </Layout>
          }
        ></Route>
        <Route
          path="/estadisticas"
          element={
            <Layout>
              <Estadistica></Estadistica>
            </Layout>
          }
        ></Route>
        <Route
          path="/admin"
          element={
            <Layout>
              <Admin></Admin>
            </Layout>
          }
        ></Route>
      </Routes>

      <ToastContainer autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default App;
