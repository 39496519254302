import { useState } from "react";
import classes from "./CasosTrack.module.css";
import IngresoCard from "../tarjetas/IngresoCard";
import PremediacionCard from "../tarjetas/PremediacionCard";
import MediacionCard from "../tarjetas/MediacionCard";
import SeguimientoCard from "../tarjetas/SeguimientoCard";
import CierreCard from "../tarjetas/CierreCard";
import ModalIngreso from "../modal/IngresoModal";

function CasosTrack(props) {
  const isIngreso = props.tipoCaso === "panelIngreso";
  const isPremediacion = props.tipoCaso === "panelPremediacion";
  const isMediacion = props.tipoCaso === "panelMediacion";
  const isSeguimiento = props.tipoCaso === "panelSeguimiento";
  const isCierre = props.tipoCaso === "panelCierre";
  const hasCases = props.casos !== undefined;
  const [modalIngreso, setShowModalIngreso] = useState(false);

  function generateCards(casos) {
    let casoArray = [];
    if (props.casos && isIngreso) {
      for (let i = 0; i < casos.length; i++) {
        casoArray.push(
          <IngresoCard key={"ingresoCard_" + casos[i]["id"]} caso={casos[i]}></IngresoCard>
        );
      }
      return casoArray;
    }
    if (props.casos && isPremediacion) {
      for (let i = 0; i < casos.length; i++) {
        casoArray.push(
          <PremediacionCard
            key={"premediacionCard_" + casos[i]["id"]}
            caso={casos[i]}
          ></PremediacionCard>
        );
      }
      return casoArray;
    }
    if (props.casos && isMediacion) {
      for (let i = 0; i < casos.length; i++) {
        casoArray.push(
          <MediacionCard key={"mediacionCard_" + casos[i]["id"]} caso={casos[i]}></MediacionCard>
        );
      }
      return casoArray;
    }
    if (props.casos && isSeguimiento) {
      for (let i = 0; i < casos.length; i++) {
        casoArray.push(
          <SeguimientoCard
            key={"seguimientoCard_" + casos[i]["id"]}
            caso={casos[i]}
          ></SeguimientoCard>
        );
      }
      return casoArray;
    }
    if (props.casos && isCierre) {
      for (let i = 0; i < casos.length; i++) {
        casoArray.push(
          <CierreCard key={"cierreCard_" + casos[i]["id"]} caso={casos[i]}></CierreCard>
        );
      }
      return casoArray;
    }
  }

  return (
    <div className={classes[props.tipoCaso]}>
      <div className={classes.panelContent}>
        <div className={classes.titleCard}>
          <p className={classes.title}>{props.title}</p>
          {hasCases ? (
            <div className={classes.notification}>{props.casos.length}</div>
          ) : (
            ""
          )}
        </div>
        <div className={classes.cardScroller} tipo-caso={props.tipoCaso}>
          {generateCards(props.casos)}
        </div>
        {isIngreso ? (
          <div
            className={classes.agregarCaso}
            onClick={() => {
              setShowModalIngreso(true);
            }}
          >
            Agregar caso
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classes.modalContainer}>
        {modalIngreso ? (
          <ModalIngreso
            setShowModalIngreso={setShowModalIngreso}
            caso={props.caso}
            key={props.caso ? props.caso.ruc : "ingresoEnProceso"}
          ></ModalIngreso>
        ) : null}
      </div>
    </div>
  );
}

export default CasosTrack;
