import classes from "./IngresoDatosConcurrente.module.css";
import { useState, useEffect } from "react";
import {
  nacionalidades,
  puebloOriginario,
  nivelEducacional,
  generos,
  estadoCivil,
  relacionPartes,
  regiones,
  regionesYcomunas,
} from "../data/opciones";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DatosConcurrente(props) {
  const [isOpen, setOpen] = useState(false);
  const [guardado, setGuardado] = useState(true);
  const [rut, setRut] = useState(props.data.rut ? props.data.rut : "");
  const [nombres, setNombres] = useState(
    props.data.nombres ? props.data.nombres : ""
  );
  const [apellidos, setApellidos] = useState(
    props.data.apellidos ? props.data.apellidos : ""
  );
  const [genero, setGenero] = useState(
    props.data.genero ? props.data.genero : ""
  );
  const [fecha_nacimiento, setFecha_nacimiento] = useState(
    props.data.fecha_nacimiento ? props.data.fecha_nacimiento : ""
  );
  const [nacionalidad, setNacionalidad] = useState(
    props.data.nacionalidad ? props.data.nacionalidad : ""
  );

  const [opcionOtraNacionalidad, setOpcionOtraNacionalidad] = useState(false);

  const [otra_nacionalidad, setOtra_nacionalidad] = useState(
    props.data.otra_nacionalidad ? props.data.otra_nacionalidad : ""
  );

  const [pueblo_originario, setPueblo_originario] = useState(
    props.data.pueblo_originario ? props.data.pueblo_originario : ""
  );
  const [nivel_educacional, setNivel_educacional] = useState(
    props.data.nivel_educacional ? props.data.nivel_educacional : ""
  );
  const [ocupacion, setOcupacion] = useState(
    props.data.ocupacion ? props.data.ocupacion : ""
  );
  const [estado_civil, setEstado_civil] = useState(
    props.data.estado_civil ? props.data.estado_civil : ""
  );
  const [direccion_principal, setDireccion_principal] = useState(
    props.data.direccion_principal ? props.data.direccion_principal : ""
  );
  const [region_principal, setRegion_principal] = useState(
    props.data.region_principal ? props.data.region_principal : ""
  );
  const [comuna_principal, setComuna_principal] = useState(
    props.data.comuna_principal ? props.data.comuna_principal : ""
  );
  const [correo, setCorreo] = useState(
    props.data.correo ? props.data.correo : ""
  );
  const [telefonos, setTelefonos] = useState(
    props.data.telefonos ? props.data.telefonos : ""
  );
  const [relacionado_a, setRelacionado_a] = useState(
    props.data.relacionado_a ? props.data.relacionado_a : ""
  );

  let datosConcurrente = {
    nombres: nombres,
    tipo: "concurrente",
    apellidos: apellidos,
    rut: rut,
    genero: genero,
    fecha_nacimiento: fecha_nacimiento,
    nacionalidad: nacionalidad,
    otra_nacionalidad: otra_nacionalidad,
    pueblo_originario: pueblo_originario,
    nivel_educacional: nivel_educacional,
    ocupacion: ocupacion,
    estado_civil: estado_civil,
    relacionado_a: relacionado_a,
    direccion_principal: direccion_principal,
    region_principal: region_principal,
    comuna_principal: comuna_principal,
    correo: correo,
    telefonos: telefonos,
  };

  const [title, setTitle] = useState(
    "Concurrente: " +
      nombres.split(" ")[0] +
      " " +
      apellidos.split(" ")[0] +
      " " +
      rut
  );

  function handleTelefonos(action) {
    if (action === "sumar") {
      telefonos.length === 0
        ? setTelefonos([""])
        : setTelefonos([...telefonos, ""]);
    } else {
      if (telefonos.length > 0) {
        setTelefonos(telefonos.slice(0, -1));
      }
    }
  }

  function handleDate(timestamp) {
    if (timestamp) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "Sin información";
    }
  }

  // FUNCIONES Y STATES PARA VERIFICACIÓN DE DATOS

  const [errorGeneral, setErrorGeneral] = useState(false);
  const [error, setError] = useState(false);
  const [errorApellidos, setErrorApellidos] = useState(false);
  const [errorRut, setErrorRut] = useState(false);
  const [sinDatosFechaNacimiento, setSinDatosFechaNacimiento] = useState(false);
  const [datosObligatoriosVerificados, setDatosObligatoriosVerificados] =
    useState(false);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [errorTelefono, setErrorTelefono] = useState(false);

  const handleChangeNombres = (event) => {
    const valor = event.target.value;

    if (valor == null || valor.trim() === "") {
      setError(true);
    } else if (!/^[a-zA-Z\s'.áéíóúÁÉÍÓÚñÑüÜ]+$/u.test(valor)) {
      setError(true);
      setErrorGeneral(true);
    } else {
      setError(false);
      setErrorGeneral(false);
    }

    setNombres(valor);
    setGuardado(false);
  };

  const handleChangeApellidos = (event) => {
    const valor = event.target.value;

    if (valor == null || valor.trim() === "") {
      setErrorApellidos(true);
      setErrorGeneral(true);
    } else if (!/^[a-zA-Z\s'.áéíóúÁÉÍÓÚñÑüÜ]+$/u.test(valor)) {
      setErrorApellidos(true);
      setErrorGeneral(true);
    } else {
      setErrorApellidos(false);
      setErrorGeneral(false);
    }

    setApellidos(valor);
    setGuardado(false);
  };

  // function validarRut(rut) {
  //   // if (/^([0-9]{7}|[0-9]{8})(-)?[0-9kK]$/.test(rut)) {
  //   if (
  //     /^([0-9]{1}|[0-9]{2})(.)?([0-9]{3})(.)?([0-9]{3})(-)?[0-9kK]$/.test(rut)
  //   ) {
  //     setErrorGeneral(false);
  //     setErrorRut(false);
  //     return false;
  //   } else {
  //     setErrorGeneral(true);
  //     setErrorRut(true);
  //     return true;
  //   }
  // }

  // function rutChecker(rut) {
  //   let rutNoSpecial = rut.replace(/[^0-9kK]/g, "");
  //   let rutSinDv = rutNoSpecial.substring(0, rutNoSpecial.length - 1);
  //   let rutDv = rutNoSpecial.substring(rutNoSpecial.length - 1);
  //   let rutInt = parseInt(rutSinDv);

  //   let sum = 0;
  //   let sigue = true;
  //   let iteraciones = 0;
  //   for (let i = 2; sigue; i++) {
  //     /* Se obtiene el resto, de la división por 10, lo que entrega como resultado
  //     el último dígito del número, se multiplica por el iterador */
  //     sum = sum + (rutInt % 10) * i;
  //     /* Se elimina el último número */
  //     rutInt = Math.floor(rutInt / 10);
  //     /* Se verifica que el iterador sea 7 */
  //     i = i === 7 ? 1 : i;
  //     iteraciones = iteraciones + 1;
  //     sigue = rutInt === 0 ? false : true;
  //     if (iteraciones > 10) {
  //       sigue = false;
  //     }
  //   }

  //   let resto = sum % 11;
  //   let dv = 11 - resto;

  //   if (dv === 10) {
  //     console.log("rut correcto");
  //     if (rutDv.toUpperCase() === "K") {
  //       setErrorGeneral(false);
  //       setErrorRut(false);
  //       setRut(rutFormatter(rut));
  //       return false;
  //       // return true;
  //     }
  //   } else if (dv === 11 && parseInt(rutDv) === 0) {
  //     console.log("rut correcto");
  //     setErrorGeneral(false);
  //     setErrorRut(false);
  //     setRut(rutFormatter(rut));
  //     return false;
  //     // return true;
  //   } else if (dv === parseInt(rutDv)) {
  //     console.log("rut correcto");
  //     setErrorGeneral(false);
  //     setErrorRut(false);
  //     setRut(rutFormatter(rut));
  //     return false;
  //     // return true;
  //   } else {
  //     console.log("rut incorrecto");
  //     setErrorGeneral(true);
  //     setErrorRut(true);
  //     return true;
  //     // return false;
  //   }
  // }

  // function rutFormatter(rut) {
  //   let rutNoSpecial = rut.replace(/[^0-9kK]/g, "");
  //   let rutSinDv = rutNoSpecial.substring(0, rutNoSpecial.length - 1);
  //   let largo = rutSinDv.length;
  //   let rutDv = rutNoSpecial.substring(rutNoSpecial.length - 1);
  //   if (largo > 6) {
  //     let miles = rutSinDv.slice(largo - 3);
  //     let cienmiles = rutSinDv.slice(largo - 6, largo - 3);
  //     let millones = rutSinDv.slice(0, largo - 6);
  //     cienmiles = "." + cienmiles;
  //     miles = "." + miles;
  //     rut = millones + cienmiles + miles + "-" + rutDv;
  //   }
  //   console.log("funciono");
  //   console.log(rut);
  //   setRut(rut);
  //   return rut;
  // }
  // EFECTO PARA DETECTAR CAMPOS OBLIGATORIOS

  const [errorNacionalidadOtros, setErrorNacionalidadOtros] = useState(false);

  useEffect(() => {
    if (
      nombres != "" &&
      apellidos != "" &&
      telefonos.length > 0
    ) {
      setDatosObligatoriosVerificados(true);
    } else {
      setDatosObligatoriosVerificados(false);
    }
  }, [
    nombres,
    apellidos,
    telefonos,
  ]);

  function validarCorreo(correo) {
    // if (/^([0-9]{7}|[0-9]{8})(-)?[0-9kK]$/.test(rut)) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(correo)) {
      setErrorGeneral(false);
      setErrorCorreo(false);
      return false;
    } else {
      setErrorGeneral(true);
      setErrorCorreo(true);
      return true;
    }
  }

  const handleChangeNacionalidadOtros = (event) => {
    const valor = event.target.value;

    if (valor == null || valor.trim() === "") {
      setErrorNacionalidadOtros(true);
      setErrorGeneral(true);
    } else if (!/^[a-zA-Z\s'.áéíóúÁÉÍÓÚñÑüÜ]+$/u.test(valor)) {
      setErrorNacionalidadOtros(true);
      setErrorGeneral(true);
    } else {
      setErrorNacionalidadOtros(false);
      setErrorGeneral(false);
    }

    setNacionalidad(valor);
    // setGuardado(false);
  };

  const notify = () => {
    toast.success("Datos Concurrente actualizados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // function validarTelefono(telefono) {
  //   // if (/^([0-9]{7}|[0-9]{8})(-)?[0-9kK]$/.test(rut)) {
  //   if (/^(\+?56)?\s*\d{8,9}$/.test(Number(telefono))) {
  //     setErrorGeneral(false);
  //     setErrorTelefono(false);
  //     return false;
  //   } else {
  //     setErrorGeneral(true);
  //     setErrorTelefono(true);
  //     return true;
  //   }
  // }

  return (
    <>
      <div className={classes.saved}>
        <div
          className={classes.collapsible}
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <div>{title}</div>
          <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
            {">"}
          </div>
        </div>
      </div>

      {isOpen ? (
        <div>
          {guardado ? (
            <div className={classes.saveTrue}></div>
          ) : (
            <div className={classes.saveFalse}>
              <i>Cambios sin Guardar</i>{" "}
            </div>
          )}

          <div>
            <p>(*) Campo Obligatorio</p>
          </div>
          {/* Nombre y apellido */}
          <div className={classes.dosPartes}>
            <div>
              <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
                <p>Nombres* </p>{" "}
                <span>
                  {error ? (
                    <p className={classes.mensaje_error_datos}>
                      Nombres inválidos
                    </p>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <input
                className={`${classes.actionBox} ${classes.input_cursiva} ${
                  error ? classes.error : ""
                }`}
                type="text"
                name=""
                placeholder="Ejemplo: Juan Pedro"
                id=""
                onBlur={(event) => {
                  setGuardado(false);
                }}
                onChange={handleChangeNombres}
                value={nombres}
              />
            </div>
            <div>
              <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
                <p>Apellidos* </p>{" "}
                <span>
                  {errorApellidos ? (
                    <p className={classes.mensaje_error_datos}>
                      Apellidos inválidos
                    </p>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <input
                className={`${classes.actionBox} ${classes.input_cursiva} ${
                  errorApellidos ? classes.error : ""
                }`}
                type="text"
                name=""
                placeholder="Ejemplo: Pérez Soto"
                id=""
                onBlur={(event) => {
                  setGuardado(false);
                }}
                onChange={handleChangeApellidos}
                value={apellidos}
              />
            </div>
          </div>
          {/* RUT y fecha de necimiento */}
          <div className={classes.dosPartes}>
            <div>
              <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
                <p>RUT* </p>{" "}
                <span>
                  {errorRut ? (
                    <p className={classes.mensaje_error_datos}>RUT inválido</p>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <input
                className={`${classes.actionBox} ${classes.input_cursiva} ${
                  errorRut ? classes.error : ""
                }`}
                type="text"
                name=""
                placeholder="Ejemplo: 16432899-k"
                id=""
                onChange={(event) => {
                  setRut(event.target.value);
                  setGuardado(false);
                }}
                
                defaultValue={rut}
              />
            </div>
            <div>
              <div className={classes.subtitulo}>Fecha Nacimiento</div>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                onBlur={(event) => {
                  let newDate = new Date(event.target.value + "T00:00");
                  let newTimestamp = Timestamp.fromDate(newDate);
                  setFecha_nacimiento(newTimestamp);
                  setGuardado(false);
                }}
                defaultValue={handleDate(fecha_nacimiento)
                  .split("-")
                  .reverse()
                  .join("-")}
              />
            </div>
          </div>
          {/* Nacionalidad y pueblo originario */}
          <div className={classes.dosPartes}>
            <div>
              <div className={classes.subtitulo}>Nacionalidad</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={nacionalidad ? nacionalidad : " "}
                  onChange={(event) => {
                    setNacionalidad(event.target.value);
                    if (event.target.value === "Otra") {
                      setOpcionOtraNacionalidad(true);
                    } else {
                      setOpcionOtraNacionalidad(false);
                    }

                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {nacionalidades.map(function (nacion, i) {
                    return (
                      <option value={nacion} key={"concurrente_nacion_" + i}>
                        {nacion}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div className={classes.subtitulo}>Pueblo Originario</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={pueblo_originario ? pueblo_originario : " "}
                  onChange={(event) => {
                    setPueblo_originario(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {puebloOriginario.map(function (pueblo, i) {
                    return (
                      <option value={pueblo} key={"concurrente_pueblo_" + i}>
                        {pueblo}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* *************** Renderizado para cuando nacionalidad es "OTROS" *************** */}
          {opcionOtraNacionalidad ? (
            <div className={classes.div_ingreso}>
              <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
                <p>Especificar delito* </p>{" "}
              </div>
              <div>
                {errorNacionalidadOtros ? (
                  <div className={classes.subtitulo}>
                    <p className={classes.mensaje_error_datos}>
                      Formato inválido
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <input
                className={`${classes.actionBox} ${
                  errorNacionalidadOtros ? classes.error : ""
                }`}
                type="text"
                name=""
                id=""
                // onBlur={() => setNombres(nombres)}
                onChange={handleChangeNacionalidadOtros}
                defaultValue={otra_nacionalidad}
              />
            </div>
          ) : (
            ""
          )}
          {/* Nivel educacional y género */}
          <div className={classes.dosPartes}>
            <div>
              <div className={classes.subtitulo}>Nivel Educacional</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={nivel_educacional ? nivel_educacional : " "}
                  onChange={(event) => {
                    setNivel_educacional(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {nivelEducacional.map(function (nivel, i) {
                    return (
                      <option value={nivel} key={"concurrente_nivel_" + i}>
                        {nivel}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div className={classes.subtitulo}>Género*</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={genero ? genero : " "}
                  onChange={(event) => {
                    setGenero(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {generos.map(function (genero, i) {
                    return (
                      <option value={genero} key={"concurrente_genero_" + i}>
                        {genero}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* Estado civil y relación con concurrente */}
          <div className={classes.dosPartes}>
            <div>
              <div className={classes.subtitulo}>Estado civil</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={estado_civil ? estado_civil : " "}
                  onChange={(event) => {
                    setEstado_civil(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {estadoCivil.map(function (estado, i) {
                    return (
                      <option value={estado} key={"concurrente_estado_" + i}>
                        {estado}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div className={classes.subtitulo}>Relacionado con</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={relacionado_a ? relacionado_a : ""}
                  onChange={(event) => {
                    setRelacionado_a(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value="">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  <option value="Víctima" key={"relacionado_a_victima"}>
                    Víctima
                  </option>
                  <option value="Concurrente" key={"relacionado_a_concurrente"}>
                    Concurrente
                  </option>
                </select>
              </div>
            </div>
          </div>
          {/* Región y comuna */}
          <div className={classes.dosPartes}>
            <div>
              <div className={classes.subtitulo}>Región residencia</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={region_principal ? region_principal : " "}
                  onChange={(event) => {
                    setRegion_principal(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value=" ">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {regiones.map(function (region, i) {
                    return (
                      <option value={region} key={"concurrente_region_" + i}>
                        {region}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div>
              <div className={classes.subtitulo}>Comuna residencia</div>
              <div>
                <select
                  className={classes.actionBox}
                  name=""
                  id=""
                  defaultValue={comuna_principal ? comuna_principal : ""}
                  onChange={(event) => {
                    setComuna_principal(event.target.value);
                    setGuardado(false);
                  }}
                >
                  <option disabled value="">
                    {" "}
                    -- Seleccione --{" "}
                  </option>
                  {regionesYcomunas[
                    region_principal ? region_principal : "default"
                  ].map(function (comuna, i) {
                    return (
                      <option value={comuna} key={"concurrente_comuna_" + i}>
                        {comuna}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* Dirección y correo */}
          <div className={classes.dosPartes}>
            <div>
              <div className={classes.subtitulo}>Dirección</div>
              <input
                className={`${classes.actionBox} ${classes.input_cursiva}`}
                type="text"
                name=""
                placeholder="Ejemplo: José Manuel Infante 2511"
                id=""
                onChange={(event) => {
                  setDireccion_principal(event.target.value);
                  setGuardado(false);
                }}
                defaultValue={direccion_principal}
              />
            </div>
            <div>
              <div className={`${classes.subtitulo} ${classes.subtitulo_div}`}>
                <p>Correo electrónico</p>
                <span>
                  {errorCorreo ? (
                    <p className={classes.mensaje_error_datos}>
                      Correo inválido
                    </p>
                  ) : (
                    ""
                  )}
                </span>{" "}
              </div>
              <input
                className={`${classes.actionBox} ${classes.input_cursiva} ${
                  errorCorreo ? classes.error : ""
                }`}
                type="text"
                name=""
                placeholder="Ejemplo: correo@email.com"
                id=""
                onBlur={(event) => {
                  setGuardado(false);
                }}
                onChange={(event) => {
                  validarCorreo(event.target.value);
                  setCorreo(event.target.value);
                  setGuardado(false);
                }}
                defaultValue={correo}
              />
            </div>
          </div>
          {/* Teléfonos */}
          <div>
            <div className={classes.telefonoFlex}>
              <div className={classes.subtitulo}>Teléfonos*</div>
              <div
                className={classes.menos}
                onClick={() => {
                  setGuardado(false);
                  handleTelefonos("menos");
                }}
              >
                -
              </div>
              <div
                className={classes.mas}
                onClick={() => {
                  setGuardado(false);
                  handleTelefonos("sumar");
                }}
              >
                +
              </div>
            </div>

            {telefonos.map((number, index) => {
              return (
                <div key={"concurrente_telefonoConcurrente_" + index}>
                  <input
                    className={`${classes.phoneBox} ${classes.input_cursiva} ${
                      errorTelefono ? classes.error : ""
                    }`}
                    type="text"
                    placeholder="Ejemplo: 987766123"
                    defaultValue={telefonos[index]}
                    onBlur={(event) => {
                      let helperArray = [...telefonos];
                      helperArray[index] = event.target.value;
                      setGuardado(false);
                      // validarTelefono(event.target.value);
                      setTelefonos(helperArray);
                    }}
                  />
                </div>
              );
            })}
            {errorTelefono ? (
              <div>
                {" "}
                <p className={classes.mensaje_error_datos}>
                  Alguno de los teléfonos ingresados tiene un formato inválido
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          {!datosObligatoriosVerificados ? (
            <p className={classes.mensaje_error_datos}>
              Debe rellenar/seleccionar todos los campos obligatorios*
            </p>
          ) : (
            ""
          )}
          {/* Botón de guardado */}
          <div
            className={`${
              errorGeneral || !datosObligatoriosVerificados
                ? classes.boton_desactivado
                : classes.boton
            }`}
            onClick={() => {
              let helperArray = [...props.fullArray];
              helperArray[props.index] = datosConcurrente;
              setGuardado(true);
              props.setGuardado(false);
              setTitle(
                "Concurrente: " +
                  nombres.split(" ")[0] +
                  " " +
                  apellidos.split(" ")[0] +
                  " " +
                  rut
              );
              props.setConcurrentes(helperArray);
              notify();
            }}
          >
            Actualizar concurrente
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DatosConcurrente;
