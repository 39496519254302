import { useState, useEffect } from "react";
import classes from "./MediacionSesion.module.css";
import { Timestamp } from "firebase/firestore";
import {
  tipoSesion,
  asesoresLegales,
  resultadosSesion,
  acuerdos,
} from "../data/opciones";
import { postStorage } from "../../firebase/firebaseStorage";
import { toast } from "react-toastify";

function MediacionSesion(props) {
  // *****************************************
  // Funciones, Hooks y States para Subir Archivo a Storage

  //const [uploadeableFile, setUploadeableFile] = useState("");
  //const [file, setFile] = useState("");
  let uploadeableFile;

  async function readFile(file) {
    if (file !== "") {
      return new Promise((res, rej) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", (e) => res(e.target.result));
        reader.addEventListener("error", rej);
        reader.readAsArrayBuffer(file);
      });
    }
  }

  async function main(file) {
    if (file !== "") {
      let fileArrayBuffer = new Uint8Array(await readFile(file));
      uploadeableFile = fileArrayBuffer;
      //setUploadeableFile(fileArrayBuffer);
      return true;
    } else {
      console.log("No hay archivo para leer");
    }
  }

  /*  useEffect(() => {
    main(file).then(() => {
      setUploadeableFile(uploadeableFile);
    });
  }, [file]); */

  // *****************************************

  const [isOpen, setOpen] = useState(false);
  const [guardado, setGuardado] = useState(true);
  const [fecha_sesion_mediacion, setFechaSesionMediacion] = useState(
    props.data.fecha_sesion_mediacion ? props.data.fecha_sesion_mediacion : ""
  );
  const [asistentes_mediacion, setAsistentesMediacion] = useState(
    props.data.asistentes_mediacion ? props.data.asistentes_mediacion : {}
  );
  const [tipo_sesion_mediacion, setTipoSesionMediacion] = useState(
    props.data.tipo_sesion_mediacion ? props.data.tipo_sesion_mediacion : ""
  );
  const [existe_asesor_legal_bool, setExisteAsesorLegal] = useState(
    props.data.existe_asesor_legal_bool
      ? props.data.existe_asesor_legal_bool
      : false
  );
  const [tipo_asesor_legal, setTipoAsesorLegal] = useState(
    props.data.tipo_asesor_legal ? props.data.tipo_asesor_legal : ""
  );
  const [observaciones_sesion_mediacion, setObservacionesSesionMediacion] =
    useState(
      props.data.observaciones_sesion_mediacion
        ? props.data.observaciones_sesion_mediacion
        : ""
    );
  const [resultados_sesion, setResultadosSesion] = useState(
    props.data.resultados_sesion ? props.data.resultados_sesion : ""
  );
  const [tipo_acuerdo_mediacion, setTipoAcuerdoMediacion] = useState(
    props.data.tipo_acuerdo_mediacion ? props.data.tipo_acuerdo_mediacion : []
  );
  const [documento_seguimiento, setDocumentoSeguimiento] = useState(
    props.data.documento_acuerdo_mediacion
      ? props.data.documento_acuerdo_mediacion
      : ""
  );
  const [fecha_proxima_sesion_mediacion, setFechaProximaSesion] = useState(
    props.data.fecha_proxima_sesion_mediacion
      ? props.data.fecha_proxima_sesion_mediacion
      : ""
  );
  const [fecha_devolucion_fiscalia, setFecha_devolucion_fiscalia] = useState(
    props.data.fecha_devolucion_fiscalia
      ? props.data.fecha_devolucion_fiscalia
      : ""
  );
  const [firman_ambas_partes, setFirmanAmbasPartes] = useState(
    props.data.firman_ambas_partes ? props.data.firman_ambas_partes : false
  );
  const [requiere_seguimiento, setRequiereSeguimiento] = useState(
    props.data.requiere_seguimiento ? props.data.requiere_seguimiento : false
  );
  const [url, setUrl] = useState(
    props.data.documento_seguimiento ? props.data.documento_seguimiento : ""
  );
  const [fecha_acuerdo, setFechaAcuerdo] = useState("");
  let file;
  let datosSesion = {
    fecha_sesion_mediacion: fecha_sesion_mediacion,
    asistentes_mediacion: asistentes_mediacion,
    tipo_sesion_mediacion: tipo_sesion_mediacion,
    existe_asesor_legal_bool: existe_asesor_legal_bool,
    tipo_asesor_legal: tipo_asesor_legal,
    observaciones_sesion_mediacion: observaciones_sesion_mediacion,
    resultados_sesion: resultados_sesion,
    tipo_acuerdo_mediacion: tipo_acuerdo_mediacion,
    documento_seguimiento: documento_seguimiento,
    fecha_proxima_sesion_mediacion: fecha_proxima_sesion_mediacion,
    fecha_devolucion_fiscalia: fecha_devolucion_fiscalia,
    firman_ambas_partes: firman_ambas_partes,
    requiere_seguimiento: requiere_seguimiento,
    fecha_acuerdo: fecha_acuerdo,
  };
  function handleDate(timestamp) {
    if (typeof timestamp === typeof Timestamp.now()) {
      let dateData = new Date(timestamp.seconds * 1000);
      let day =
        dateData.getDate() > 9 ? dateData.getDate() : "0" + dateData.getDate();
      let month =
        dateData.getMonth() + 1 > 9
          ? dateData.getMonth() + 1
          : "0" + (dateData.getMonth() + 1);
      let year = dateData.getFullYear();
      let parsedString = day + "-" + month + "-" + year;
      return parsedString;
    } else {
      return "";
    }
  }

  const handleTipoAcuerdoChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setTipoAcuerdoMediacion((prevOptions) => [...prevOptions, value]);
    } else {
      setTipoAcuerdoMediacion((prevOptions) =>
        prevOptions.filter((option) => option !== value)
      );
    }
  };

  function resultadoSesion(resultado) {
    switch (resultado) {
      case "Agenda nueva sesión":
        return (
          <div>
            <p className={classes.subtitulo}> Fecha Nueva sesión</p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_proxima_sesion_mediacion)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFechaProximaSesion(newTimestamp);
                setGuardado(false);
              }}
            />
          </div>
        );
      case "Cierre sin acuerdo":
        return (
          <div>
            <p className={classes.subtitulo}>Fecha devolución Fiscalía</p>
            <input
              className={classes.actionBox}
              type="date"
              name=""
              id=""
              defaultValue={handleDate(fecha_devolucion_fiscalia)
                .split("-")
                .reverse()
                .join("-")}
              onChange={(event) => {
                let newDate = new Date(event.target.value + "T00:00");
                let newTimestamp = Timestamp.fromDate(newDate);
                setFecha_devolucion_fiscalia(newTimestamp);
                setGuardado(false);
              }}
            />
          </div>
        );
      case "Cierre con acuerdo y seguimiento":
        return (
          <div>
            <p className={classes.subtitulo}>Tipo de acuerdo</p>
            <div>
              {acuerdos.map((acuerdo, i) => (
                <div key={"acuerdo_" + i} className={classes.checkWithOption}>
                  <input
                    type="checkbox"
                    value={acuerdo}
                    checked={tipo_acuerdo_mediacion.includes(acuerdo)}
                    onChange={handleTipoAcuerdoChange}
                  />
                  <span>{acuerdo}</span>
                </div>
              ))}
            </div>
            <div className={classes.dosPartes}>
              <div>
                <p className={classes.subtitulo}>
                  Acuerdo firmado por ambas partes
                </p>
                <div className={classes.checkWithOption}>
                  <input
                    type="checkbox"
                    checked={firman_ambas_partes}
                    onChange={() => {
                      setFirmanAmbasPartes(true);
                    }}
                  />
                  <span>Sí</span>
                </div>
                <div className={classes.checkWithOption}>
                  <input
                    type="checkbox"
                    checked={!firman_ambas_partes}
                    onChange={() => {
                      setFirmanAmbasPartes(false);
                    }}
                  />
                  <span>No</span>
                </div>
              </div>
              <div>
                <p className={classes.subtitulo}>
                  Acuerdo requiere seguimiento
                </p>
                <div className={classes.checkWithOption}>
                  <input
                    type="checkbox"
                    checked={requiere_seguimiento}
                    onChange={() => {
                      setRequiereSeguimiento(true);
                    }}
                  />
                  <span>Sí</span>
                </div>
                <div className={classes.checkWithOption}>
                  <input
                    type="checkbox"
                    checked={!requiere_seguimiento}
                    onChange={() => {
                      setRequiereSeguimiento(false);
                    }}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
            <div>
              <p className={classes.subtitulo}>Adjuntar Documento Acuerdo</p>
              <label
                htmlFor="file-input-acuerdo"
                className={classes.fileInputLabel}
              >
                Subir archivo del acuerdo
              </label>
              <input
                type="file"
                id="file-input-acuerdo"
                className={classes.fileInput}
                onChange={(e) => {
                  e.target.nextElementSibling.innerText =
                    e.target.files[0].name;
                  file = e.target.files[0];
                  //setFile(e.target.files[0]);
                  console.log(file);
                  main(file);
                }}
              />
              <span className={classes.fileName}></span>
            </div>
            <div>
              <p className={classes.subtitulo}>Fecha del acuerdo</p>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                onChange={(event) => {
                  let newDate = new Date(event.target.value + "T00:00");
                  let newTimestamp = Timestamp.fromDate(newDate);
                  setFechaAcuerdo(newTimestamp);
                  setGuardado(false);
                }}
                defaultValue={handleDate(fecha_acuerdo)
                  .split("-")
                  .reverse()
                  .join("-")}
              />
            </div>
          </div>
        );
      case "Cierre con acuerdo cumplido":
        return (
          <div>
            <p className={classes.subtitulo}>Tipo de acuerdo</p>
            <div>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={tipo_acuerdo_mediacion}
                onChange={(event) =>
                  setTipoAcuerdoMediacion(event.target.value)
                }
              >
                <option disabled value="">
                  {""}
                  -- Seleccione --{""}
                </option>
                {acuerdos.map(function (acuerdo, i) {
                  return (
                    <option value={acuerdo} key={"acuerdo_" + i}>
                      {acuerdo}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <p className={classes.subtitulo}>Adjuntar Documento Acuerdo</p>
              <label
                htmlFor="file-input-acuerdo"
                className={classes.fileInputLabel}
              >
                Subir archivo del acuerdo
              </label>
              <input
                type="file"
                id="file-input-acuerdo"
                className={classes.fileInput}
                onChange={(e) => {
                  e.target.nextElementSibling.innerText =
                    e.target.files[0].name;
                  file = e.target.files[0];
                  //setFile(e.target.files[0]);
                  console.log(file);
                  main(file);
                }}
              />
              <span className={classes.fileName}></span>
            </div>
          </div>
        );

      default:
        break;
    }
  }

  // EFECTO PARA DETECTAR CAMPOS OBLIGATORIOS
  const [datosObligatoriosVerificados, setDatosObligatoriosVerificados] =
    useState(false);

  const [objetivo_checked, setObjetivo_checked] = useState(false);

  useEffect(() => {
    //console.log(datosObligatoriosVerificados);
    if (
      asistentes_mediacion != "" &&
      fecha_sesion_mediacion != "" &&
      tipoSesion != "" &&
      resultados_sesion != "" &&
      (existe_asesor_legal_bool != "" || !existe_asesor_legal_bool != "")
    ) {
      setDatosObligatoriosVerificados(true);
    } else {
      setDatosObligatoriosVerificados(false);
    }
  }, [
    asistentes_mediacion,
    fecha_sesion_mediacion,
    tipoSesion,
    resultados_sesion,
    existe_asesor_legal_bool,
  ]);

  const notify = () => {
    toast.success("Datos Sesión Mediación guardados con éxito !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <div className={classes.saved}>
        <div
          className={classes.collapsible}
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <div>Sesión de mediación N°{props.index + 1}</div>
          <div className={classes.dateArrow}>
            <div>{handleDate(fecha_sesion_mediacion)}</div>
            <div className={isOpen ? classes.arrowOpen : classes.arrowClose}>
              {">"}
            </div>
          </div>
        </div>
      </div>
      {isOpen ? (
        <div className={classes.sesion}>
          {guardado ? (
            <div className={classes.saveTrue}></div>
          ) : (
            <div className={classes.saveFalse}>Sin Guardar</div>
          )}
          <p>
            <i>Datos Obligatorios (*)</i>
          </p>
          {/* Fecha interacción y asistentes */}
          <div className={classes.dosPartes}>
            <div>
              <p className={classes.subtitulo}>Asistentes a la sesión*</p>
              <div>
                {props.participantes.map((participante, index) => {
                  return (
                    <div
                      className={classes.checkWithOption}
                      key={"participante_" + index}
                    >
                      <input
                        type="checkbox"
                        checked={
                          asistentes_mediacion[participante]
                            ? asistentes_mediacion[participante]
                            : false
                        }
                        onChange={() => {
                          let helperArray = { ...asistentes_mediacion };
                          helperArray[participante] =
                            !helperArray[participante];
                          setGuardado(false);
                          setAsistentesMediacion(helperArray);
                        }}
                      />
                      <span>{participante}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <p className={classes.subtitulo}>Fecha de sesión*</p>
              <input
                className={classes.actionBox}
                type="date"
                name=""
                id=""
                onChange={(event) => {
                  let newDate = new Date(event.target.value + "T00:00");
                  let newTimestamp = Timestamp.fromDate(newDate);
                  setFechaSesionMediacion(newTimestamp);
                  setGuardado(false);
                }}
                defaultValue={handleDate(fecha_sesion_mediacion)
                  .split("-")
                  .reverse()
                  .join("-")}
              />
            </div>
          </div>
          {/* Observaciones */}
          <div>
            <p className={classes.subtitulo}>Observaciones</p>
            <textarea
              className={classes.textArea}
              name=""
              id=""
              cols="20"
              rows="10"
              defaultValue={observaciones_sesion_mediacion}
              onBlur={(event) => {
                setObservacionesSesionMediacion(event.target.value);
                setGuardado(false);
              }}
            ></textarea>
          </div>
          {/* Tipo de sesión */}
          <div>
            <p className={classes.subtitulo}>Tipo de Sesión*</p>
            <div>
              {tipoSesion.map((tipo, index) => {
                const handleChangeTipoSesion = () => {
                  setTipoSesionMediacion(tipo);
                  setGuardado(false);
                };
                return (
                  <span key={"tipoSesion_" + index}>
                    <input
                      type="checkbox"
                      checked={tipo_sesion_mediacion === tipo}
                      onChange={handleChangeTipoSesion}
                    />
                    <span>{tipo}</span>
                  </span>
                );
              })}
            </div>
          </div>
          {/* Asesoría legal y tipo asesor legal */}
          <div className={classes.dosPartes}>
            <div>
              <p className={classes.subtitulo}>Asesoría legal*</p>
              <span>
                <input
                  type="checkbox"
                  checked={existe_asesor_legal_bool}
                  onChange={() => {
                    setExisteAsesorLegal(true);
                    setGuardado(false);
                  }}
                />
                <span>Sí</span>
              </span>
              <span>
                <input
                  type="checkbox"
                  checked={!existe_asesor_legal_bool}
                  onChange={() => {
                    setExisteAsesorLegal(false);
                    setGuardado(false);
                  }}
                />
                <span>No</span>
              </span>
            </div>
            {existe_asesor_legal_bool ? (
              <div>
                <p className={classes.subtitulo}>Tipo asesor legal</p>
                <div>
                  <select
                    className={classes.actionBox}
                    name=""
                    id=""
                    defaultValue={tipo_asesor_legal ? tipo_asesor_legal : ""}
                    onChange={(event) => {
                      setTipoAsesorLegal(event.target.value);
                      setGuardado(false);
                    }}
                  >
                    <option disabled value="">
                      {""}
                      -- Seleccione --{""}
                    </option>
                    {asesoresLegales.map(function (asesor, i) {
                      return (
                        <option value={asesor} key={"asesor_" + i}>
                          {asesor}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {/* Resultados sesión */}

          <div>
            <p className={classes.subtitulo}>Resultado sesión*</p>
            <div>
              <select
                className={classes.actionBox}
                name=""
                id=""
                defaultValue={resultados_sesion}
                onChange={(event) => {
                  setResultadosSesion(event.target.value);
                  setGuardado(false);
                }}
              >
                <option disabled value="">
                  {""}
                  -- Seleccione --{""}
                </option>
                {resultadosSesion.map(function (resultado, i) {
                  return (
                    <option value={resultado} key={"resultado_" + i}>
                      {resultado}
                    </option>
                  );
                })}
              </select>
            </div>
            {}
          </div>

          {/* Acuerdo firmado por ambas partes, requiere seguimiento y archivo adjunto */}
          <div>{resultadoSesion(resultados_sesion)}</div>
          <hr />
          {!datosObligatoriosVerificados ? (
            <p className={classes.mensaje_error_datos}>
              Debe rellenar/seleccionar todos los campos obligatorios*
            </p>
          ) : (
            ""
          )}
          <div className={classes.guardado_btn_div}>
            <button
              className={`${
                !datosObligatoriosVerificados
                  ? classes.guardado_btn_desactivado
                  : classes.guardado_btn
              }`}
              onClick={async () => {
                let fileInput;
                let url;
                if (uploadeableFile !== "" && uploadeableFile !== undefined) {
                  await postStorage(
                    "mediacion/" + file.name,
                    uploadeableFile
                  ).then((archivoRutaDescarga) => {
                    url = archivoRutaDescarga;
                    props.setDocumentoSeguimiento(url);
                  });
                }

                let helperArray = [...props.fullArray];
                helperArray[props.index] = datosSesion;
                props.setSesiones(helperArray);
                props.setEstadoActualMediacion(resultados_sesion);

                props.setFechaAcuerdo(fecha_acuerdo);
                props.setTipoAcuerdo(tipo_acuerdo_mediacion);
                setGuardado(true);
                notify();
                setOpen(false);
              }}
            >
              GUARDAR
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default MediacionSesion;
