import IndicadorCard from "../tarjetas/IndicadorCard";
import classes from "./Indicadores.module.css";

function Indicadores(props) {
  const allCasos = props.allCasos;

  function roundTwo(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  //Filtra sólo casos con encuestas respondidas
  const infoExtraida = () => {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Inicializadores
    const encuestasIngreso = [];
    const encuestasPremediacion = [];
    const encuestasMediacion = [];
    const encuestasSeguimiento = [];
    const encuestasCierre = [];
    let nParticipantes = 0;
    let nCasosLleganMediacion = 0;
    let nCasosLleganPremediacion = 0;
    let nSatisfechosJusticia = 0;
    let ajusteJusticia = 0;
    let nSatisfechosExperiencia = 0;
    let ajusteExperiencia = 0;
    let nSatisfechosCreacionAcuerdo = 0;
    let ajusteCreacionAcuerdo = 0;
    let nSatisfechosMediadora = 0;
    let ajusteMediadora = 0;
    let nSatisfechosInfraestructura = 0;
    let ajusteInfraestructura = 0;
    let nExitoEnMediacion = 0;
    let nExitoEnSeguimiento = 0;
    let nExitoEnCierre = 0;
    let nCerradosEnIngreso = 0;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Funciones de filtro
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos con encuesta
    const casosConEncuesta = allCasos.filter((caso) => {
      return Object.keys(caso.encuestas).length !== 0;
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos cerrados en ingreso
    const cierreEnIngreso = allCasos.filter((caso) => {
      try {
        return (
          Object.keys(caso.premediacionData).length === 0 &&
          caso.estado === "finalizado"
        );
      } catch {
        console.log(caso);
        return false;
      }
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos cerrados en premediación
    const cierreEnPremediacion = allCasos.filter((caso) => {
      return (
        Object.keys(caso.premediacionData).length !== 0 &&
        Object.keys(caso.mediacionData).length === 0 &&
        caso.estado === "finalizado"
      );
    });
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos que hayan pasado a mediación
    const casosLlegadaMediacion = allCasos.filter((caso) => {
      return Object.keys(caso.mediacionData).length !== 0;
    });
    nCasosLleganMediacion = casosLlegadaMediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra casos que pasaron a premediación
    const casosLlegadaPremediacion = allCasos.filter((caso) => {
      return Object.keys(caso.premediacionData).length !== 0;
    });
    nCasosLleganPremediacion = casosLlegadaPremediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos que tengan salida en mediación con acuerdo cumplido
    const casosSalidaExitosaMediacion = casosLlegadaMediacion.filter((caso) => {
      return caso.mediacionData
        ? caso.mediacionData.estado_actual_mediacion ===
            "Cierre con acuerdo cumplido"
        : false;
    });
    nExitoEnMediacion = casosSalidaExitosaMediacion.length;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Filtra sólo casos que salen de seguimiento cumplido
    const casosSalidaExitosaSeguimiento = casosLlegadaMediacion.filter(
      (caso) => {
        return caso.seguimientoData
          ? [
              "Sí totalmente",
              "Sí parcialmente -Víctima conforme-",
              //"Sí parcialmente -Víctima disconforme-",
            ].includes(caso.seguimientoData.cumple_acuerdo)
          : false;
      }
    );
    nExitoEnSeguimiento = casosSalidaExitosaSeguimiento.length;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Procesos de iteración
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //Pushea encuestas respondida y encuentra número de participantes
    for (const caso of casosConEncuesta) {
      nParticipantes =
        nParticipantes +
        caso.ingresoData.victimas.length +
        caso.ingresoData.ofensores.length;
      for (const encuesta of Object.keys(caso.encuestas)) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Suma casos en que indicadores de satisfacción son buenos
        //Justicia y su ajustador
        if (caso.encuestas[encuesta].satisfaccion.justicia >= 6) {
          nSatisfechosJusticia = nSatisfechosJusticia + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.justicia === 0 ||
          caso.encuestas[encuesta].satisfaccion.justicia === " " ||
          caso.encuestas[encuesta].satisfaccion.justicia === ""
        ) {
          ajusteJusticia = ajusteJusticia + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Experiencia general y ajustador
        if (caso.encuestas[encuesta].satisfaccion.experiencia >= 6) {
          nSatisfechosExperiencia = nSatisfechosExperiencia + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.experiencia === 0 ||
          caso.encuestas[encuesta].satisfaccion.experiencia === " " ||
          caso.encuestas[encuesta].satisfaccion.experiencia === ""
        ) {
          ajusteExperiencia = ajusteExperiencia + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Creación del acuerdo y ajustador
        if (caso.encuestas[encuesta].satisfaccion.participacion >= 6) {
          nSatisfechosCreacionAcuerdo = nSatisfechosCreacionAcuerdo + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.participacion === 0 ||
          caso.encuestas[encuesta].satisfaccion.participacion === " " ||
          caso.encuestas[encuesta].satisfaccion.participacion === ""
        ) {
          ajusteCreacionAcuerdo = ajusteCreacionAcuerdo + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Mediadora y ajustador
        if (caso.encuestas[encuesta].satisfaccion.trabajoMediadora >= 6) {
          nSatisfechosMediadora = nSatisfechosMediadora + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === 0 ||
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === " " ||
          caso.encuestas[encuesta].satisfaccion.trabajoMediadora === ""
        ) {
          ajusteMediadora = ajusteMediadora + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Infraestructura y ajustador
        if (caso.encuestas[encuesta].satisfaccion.calidadInfraestructura >= 6) {
          nSatisfechosInfraestructura = nSatisfechosInfraestructura + 1;
        }
        if (
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura === 0 ||
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura ===
            " " ||
          caso.encuestas[encuesta].satisfaccion.calidadInfraestructura === ""
        ) {
          ajusteInfraestructura = ajusteInfraestructura + 1;
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Pushea las encuestas donde corresponde
        if (caso.etapa === "Ingreso") {
          encuestasIngreso.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Premediación") {
          encuestasPremediacion.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Mediación") {
          encuestasMediacion.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Seguimiento") {
          encuestasSeguimiento.push(caso.encuestas[encuesta]);
        } else if (caso.etapa === "Cierre") {
          encuestasCierre.push(caso.encuestas[encuesta]);
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    }

    return {
      nCasos: allCasos.length,
      encuestasIngreso: encuestasIngreso,
      encuestasPremediacion: encuestasPremediacion,
      encuestasMediacion: encuestasMediacion,
      encuestasSeguimiento: encuestasSeguimiento,
      encuestasCierre: encuestasCierre,
      nParticipantes: nParticipantes,
      nCasosLleganPremediacion: nCasosLleganPremediacion,
      nCasosLleganMediacion: nCasosLleganMediacion,
      nExitoEnMediacion: nExitoEnMediacion,
      nExitoEnSeguimiento: nExitoEnSeguimiento,
      nSatisfechosJusticia: nSatisfechosJusticia,
      nSatisfechosExperiencia: nSatisfechosExperiencia,
      nSatisfechosCreacionAcuerdo: nSatisfechosCreacionAcuerdo,
      nSatisfechosMediadora: nSatisfechosMediadora,
      nSatisfechosInfraestructura: nSatisfechosInfraestructura,
      nCerradoEnIngreso: cierreEnIngreso.length,
      nCerradoEnPremediacion: cierreEnPremediacion.length,
      ajusteCreacionAcuerdo: ajusteCreacionAcuerdo,
      ajusteExperiencia: ajusteExperiencia,
      ajusteInfraestructura: ajusteInfraestructura,
      ajusteJusticia: ajusteJusticia,
      ajusteMediadora: ajusteMediadora,
    };
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Sumatorias de datos
  const data = infoExtraida();
  const nCasos = data.nCasos;
  const encuestasIngreso = data.encuestasIngreso;
  const encuestasPremediacion = data.encuestasPremediacion;
  const encuestasMediacion = data.encuestasMediacion;
  const encuestasSeguimiento = data.encuestasSeguimiento;
  const encuestasCierre = data.encuestasCierre;
  const nEncuestasIngreso = encuestasIngreso.length;
  const nEncuestasPremediacion = encuestasPremediacion.length;
  const nEncuestasMediacion = encuestasMediacion.length;
  const nEncuestasSeguimiento = encuestasSeguimiento.length;
  const nEncuestasCierre = encuestasCierre.length;
  const nParticipantes = data.nParticipantes;
  const nCasosLleganMediacion = data.nCasosLleganMediacion;
  const nCasosLleganPremediacion = data.nCasosLleganPremediacion;
  const nExitoEnMediacion = data.nExitoEnMediacion;
  const nExitoEnSeguimiento = data.nExitoEnSeguimiento;
  const nSatisfechosJusticia = data.nSatisfechosJusticia;
  const nSatisfechosExperiencia = data.nSatisfechosExperiencia;
  const nSatisfechosCreacionAcuerdo = data.nSatisfechosCreacionAcuerdo;
  const nSatisfechosMediadora = data.nSatisfechosMediadora;
  const nSatisfechosInfraestructura = data.nSatisfechosInfraestructura;
  const nCerradoEnIngreso = data.nCerradoEnIngreso;
  const nCerradoEnPremediacion = data.nCerradoEnPremediacion;
  const ajusteCreacionAcuerdo = data.ajusteCreacionAcuerdo;
  const ajusteExperiencia = data.ajusteExperiencia;
  const ajusteInfraestructura = data.ajusteInfraestructura;
  const ajusteJusticia = data.ajusteJusticia;
  const ajusteMediadora = data.ajusteMediadora;

  const sumaPremediacionAdelante =
    nEncuestasPremediacion +
    nEncuestasMediacion +
    nEncuestasSeguimiento +
    nEncuestasCierre;
  const sumaMediacionAdelante =
    nEncuestasMediacion + nEncuestasSeguimiento + nEncuestasCierre;
  const sumaSeguimientoAdelante = nEncuestasSeguimiento + nEncuestasCierre;
  const sumaEncuestas =
    nEncuestasIngreso +
    nEncuestasPremediacion +
    nEncuestasMediacion +
    nEncuestasSeguimiento +
    nEncuestasCierre;
  console.log(sumaEncuestas);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Indicadores:
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con el nivel de justicia alcanzado en el proceso
  const iSatisfaccionJusticia =
    sumaEncuestas - ajusteJusticia > 0
      ? roundTwo(
          (100 * nSatisfechosJusticia) / (sumaEncuestas - ajusteJusticia)
        ) + "%"
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% participantes que declaran estar satisfechos con la experiencia general del proceso de mediación
  const iSatisfaccionProceso =
    sumaEncuestas - ajusteExperiencia > 0
      ? roundTwo(
          (100 * nSatisfechosExperiencia) / (sumaEncuestas - ajusteExperiencia)
        ) + "%"
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que rechazan en ingreso
  const iCasosRechazadosIngreso =
    roundTwo((100 * nCerradoEnIngreso) / nCasos) + "%";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosLleganMediacion =
    roundTwo((100 * nCasosLleganMediacion) / nCasos) + "%";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosLleganPremediacion =
    roundTwo((100 * nCasosLleganPremediacion) / nCasos) + "%";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% casos que inician procesos de mediación vs casos derivadors
  const iCasosCierranPremediacion =
    roundTwo((100 * nCerradoEnPremediacion) / nCasos) + "%";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% usuarios satisfechos con su participación en la creación del acuerdo
  const iSatisfaccionAcuerdo =
    sumaEncuestas - ajusteCreacionAcuerdo > 0
      ? roundTwo(
          (100 * nSatisfechosCreacionAcuerdo) /
            (sumaEncuestas - ajusteCreacionAcuerdo)
        ) + "%"
      : "Faltan Datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Tasa de mediaciones por mediador
  const iMediacionesMediador = nCasosLleganMediacion / 2;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Tasa de premediaciones por mediador
  const iPremediacionMedidor = nCasosLleganPremediacion / 2;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la función del mediador
  const iSatisfaccionMediador =
    sumaEncuestas - ajusteMediadora > 0
      ? roundTwo(
          (100 * nSatisfechosMediadora) / (sumaEncuestas - ajusteMediadora)
        ) + "%"
      : "Faltan datos";
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //% de participantes satisfechos con la infraestructura
  const iSatisfaccionInfraestructura =
    sumaPremediacionAdelante - ajusteInfraestructura > 0
      ? roundTwo(
          (100 * nSatisfechosInfraestructura) /
            (sumaPremediacionAdelante - ajusteInfraestructura)
        ) + "%"
      : "Faltan datos";

  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>Indicadores de satisfacción</div>
      <div className={classes.track}>
        <IndicadorCard
          titulo={"Satisfacción con justicia alcanzada"}
          indicador={iSatisfaccionJusticia}
          porcentajeBool={true}
        />
        <IndicadorCard
          titulo={"Satisfacción con proceso en general"}
          indicador={iSatisfaccionProceso}
          porcentajeBool={true}
        />
        <IndicadorCard
          titulo={"Satisfechos con el trabajo del Mediador(a)"}
          indicador={iSatisfaccionMediador}
          porcentajeBool={true}
        />
        <IndicadorCard
          titulo={"Satisfechos con la infraestructura"}
          indicador={iSatisfaccionInfraestructura}
          porcentajeBool={true}
        />
        <IndicadorCard
          titulo={"Satisfechos con la creación del acuerdo"}
          indicador={iSatisfaccionAcuerdo}
          porcentajeBool={true}
        />
      </div>
      <div className={classes.title}>Indicadores de flujo de casos</div>
      <div className={classes.track}>
        <IndicadorCard titulo={"Casos totales"} indicador={nCasos} />
        <IndicadorCard
          titulo={"Casos rechazados en ingreso"}
          indicador={nCerradoEnIngreso}
        />
        <IndicadorCard
          titulo={"Casos que pasan a premediación"}
          indicador={nCasosLleganPremediacion}
        />
        <IndicadorCard
          titulo={"Casos que se cierran en premediación"}
          indicador={nCerradoEnPremediacion}
        />
        <IndicadorCard
          titulo={"Casos que inician etapa de mediación"}
          indicador={nCasosLleganMediacion}
        />
      </div>
      <div className={classes.track}>
        <div className={classes.emptyCard}></div>
        <IndicadorCard
          titulo={"Procentaje de casos rechazados en ingreso"}
          indicador={iCasosRechazadosIngreso}
        />
        <IndicadorCard
          titulo={"Porcentaje de casos que pasan a premediación"}
          indicador={iCasosLleganPremediacion}
        />
        <IndicadorCard
          titulo={"Porcentaje de casos que cierran en premediación"}
          indicador={iCasosCierranPremediacion}
        />
        <IndicadorCard
          titulo={"Porcentaje de casos que inician etapa de mediación"}
          indicador={iCasosLleganMediacion}
        />
      </div>
      <div className={classes.title}>Indicadores de mediadores</div>
      <div className={classes.track}>
        <IndicadorCard
          titulo={"Casos atendidos por mediador(a)"}
          indicador={iPremediacionMedidor}
        />
        <IndicadorCard
          titulo={"Mediaciones atendidas por mediador(a)"}
          indicador={iMediacionesMediador}
        />
      </div>
    </div>
  );
}
export default Indicadores;
